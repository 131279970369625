import { LineTypes } from '../../pages/Lighting/components/LightingPanel/data';

export const getSecondSunConfig = (startTime: string[], endTime: string[]) => {
  return {
    type: LineTypes.SecondSun,
    startHour: Number(startTime[0]),
    startMinute: Number(startTime[1]),
    endHour: Number(endTime[0]),
    endMinute: Number(endTime[1]),
    default: false,
    id: startTime[0] + startTime[1] + endTime[0] + endTime[1],
  };
};

export const getSunConfig = (startTime: string[], endTime: string[], standart = false) => {
  return {
    type: LineTypes.Sun,
    startHour: Number(startTime[0]),
    startMinute: Number(startTime[1]),
    endHour: Number(endTime[0]),
    endMinute: Number(endTime[1]),
    default: standart,
    id: startTime[0] + startTime[1] + endTime[0] + endTime[1],
  };
};

export const getOffSunConfig = (startTime: string[], endTime: string[]) => {
  return {
    type: LineTypes.OffSun,
    startHour: Number(startTime[0]),
    startMinute: Number(startTime[1]),
    endHour: Number(endTime[0]),
    endMinute: Number(endTime[1]),
    default: false,
    id: startTime[0] + startTime[1] + endTime[0] + endTime[1],
  };
};
