import { createReducer } from 'typesafe-actions';
import { saveTimelapseAction, shareFileAction, TimelapseActionUnion } from '../actions/timelapse';

export interface State {
  video: string;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  video: null,
  loading: false,
  error: null,
};

export const reducer = createReducer<State, TimelapseActionUnion>(initialState)
  .handleAction(saveTimelapseAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(saveTimelapseAction.success, (state, action) => ({
    ...state,
    video: action.payload.video,
    loading: false,
  }))
  .handleAction(saveTimelapseAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(shareFileAction.success, (state, action) => ({
    ...state,
    video: action.payload,
    loading: false,
  }))
  .handleAction(shareFileAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
