import { takeLatest, Effect, put, SagaReturnType, call } from 'redux-saga/effects';
import { ApiService, AuthService, LOCAL_STORAGE_KEYS, UserService } from '@plant/data';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { AppRoutes } from '../../core/constants/appRoutes';
import { Navigator } from '../../core/services/navigator';
import {
  clearAuthAction,
  loginAction,
  restorePasswordAction,
  setNewPasswordAction,
  signUpAction,
  socialAuthAction,
  facebookRedirectAction,
  changeUserDataAction,
  uploadAvatarAction,
  removeAvatarAction,
  deleteAccountAction,
  googleRedirectAction,
} from '../actions/auth';

export class AuthSagaWorker {
  static *login({ payload }: ActionType<typeof loginAction.request>) {
    try {
      const data: SagaReturnType<typeof AuthService.login> = yield call(
        AuthService.login,
        payload.email,
        payload.password,
      );
      yield put(loginAction.success({ data }));
      yield ApiService.getToken(data?.token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.token, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, data.refreshToken);
      Navigator.push(AppRoutes.DashBoard);
    } catch (error) {
      if (!error.response?.data?.error) {
        yield put(loginAction.failure(error?.response?.data?.message));
      } else {
        yield put(loginAction.failure(error?.response?.data?.error));
      }
    }
  }

  static *signUp({ payload }: ActionType<typeof signUpAction.request>) {
    try {
      yield call(
        AuthService.signUp,
        payload.email,
        payload.password,
        payload.firstName,
        payload.lastName,
      );
      yield put(signUpAction.success());
      Navigator.push(AppRoutes.SignIn);
      toast.success(`${t('auth.signIn.afterSignUp')}`, { autoClose: 10000 });
    } catch (error) {
      if (!error.response?.data?.error) {
        yield put(signUpAction.failure(error?.response?.data?.message));
      } else {
        yield put(signUpAction.failure(error?.response?.data?.error));
      }
    }
  }

  static *restorePassword({ payload }: ActionType<typeof restorePasswordAction.request>) {
    try {
      yield call(AuthService.recoverPassword, payload.email);
      yield put(restorePasswordAction.success());
    } catch (err) {
      const error = err?.response?.data?.message;
      if (error) {
        yield put(restorePasswordAction.failure(error));
      } else {
        if (!error.response?.data?.error) {
          yield put(restorePasswordAction.failure(error?.response?.data?.message));
        } else {
          yield put(restorePasswordAction.failure(error?.response?.data?.error));
        }
      }
    }
  }

  static *setNewPassword({ payload }: ActionType<typeof setNewPasswordAction.request>) {
    try {
      yield call(AuthService.setNewPassword, payload.newPassword, payload.token);
      yield put(setNewPasswordAction.success());
    } catch (error) {
      if (!error.response?.data?.error) {
        yield put(setNewPasswordAction.failure(error?.response?.data?.message));
      } else {
        yield put(setNewPasswordAction.failure(error?.response?.data?.error));
      }
    }
  }

  static *facebookRedirect() {
    try {
      const url: SagaReturnType<typeof AuthService.facebookLogin> = yield call(
        AuthService.facebookLogin,
      );
      window.open(url, '_self');
    } catch (error) {
      console.log(error);
    }
  }

  static *googleRedirect() {
    try {
      const url: SagaReturnType<typeof AuthService.googleLogin> = yield call(
        AuthService.googleLogin,
      );
      window.open(url, '_self');
    } catch (error) {
      console.log(error);
    }
  }

  static *socialAuth(action: ActionType<typeof socialAuthAction.request>) {
    try {
      yield ApiService.getToken(action.payload.token);
      const data: SagaReturnType<typeof UserService.getUser> = yield call(UserService.getUser);
      data.token = action.payload.token;
      yield put(socialAuthAction.success({ data }));
      yield ApiService.getToken(action.payload.token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.token, data.token);
      localStorage.setItem(LOCAL_STORAGE_KEYS.refreshToken, data.refreshToken);
      yield Navigator.push(AppRoutes.DashBoard);
    } catch (error) {
      yield put(socialAuthAction.failure(error?.response?.data?.error));
    }
  }

  static *changeUserData({ payload }: ActionType<typeof changeUserDataAction.request>) {
    try {
      const { firstName, lastName, token, street, city, country } = payload;
      const userData: SagaReturnType<typeof UserService.changeUserData> = yield call(
        UserService.changeUserData,
        firstName,
        lastName,
        country,
        city,
        street,
      );
      userData.token = userData.token || token;

      yield put(changeUserDataAction.success({ userData: userData }));
      toast.success(`${t('personal.messages.notifications')}`);
    } catch (error) {
      yield put(changeUserDataAction.failure(error?.response?.data?.error));
    }
  }

  static *uploadAvatar(action: ActionType<typeof uploadAvatarAction.request>) {
    try {
      const photo: SagaReturnType<typeof UserService.uploadAvatar> = yield call(
        UserService.uploadAvatar,
        action.payload.body,
      );
      yield put(uploadAvatarAction.success(photo));
      toast.success(`${t('personal.messages.loadAvatar')}`);
    } catch (error) {
      yield put(uploadAvatarAction.failure(error?.response?.data?.error));
      toast.error(error?.response?.data?.error);
    }
  }

  static *removeAvatar({ payload }: ActionType<typeof removeAvatarAction.request>) {
    try {
      yield call(UserService.removeAvatar, payload.uuid);
      yield put(removeAvatarAction.success());
      toast.success(`${t('personal.messages.removeAvatar')}`);
    } catch (error) {
      yield put(removeAvatarAction.failure(error?.response?.data?.error));
      toast.error(error?.response?.data?.error);
    }
  }

  static *deleteAccount(action: ActionType<typeof deleteAccountAction.request>) {
    try {
      yield call(UserService.deleteAccount, action.payload.uuid);
      yield put(deleteAccountAction.success());
      toast.success(`${t('personal.messages.deleteAccount')}`);
    } catch (error) {
      yield put(deleteAccountAction.failure(error?.response?.data?.error));
      toast.error(error?.response?.data?.error);
    }
  }

  static *clearLocalStorage() {
    yield localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
    yield localStorage.removeItem(LOCAL_STORAGE_KEYS.refreshToken);
  }
}

export function* authSaga(): Generator<Effect, void> {
  yield takeLatest(loginAction.request, AuthSagaWorker.login);
  yield takeLatest(signUpAction.request, AuthSagaWorker.signUp);
  yield takeLatest(restorePasswordAction.request, AuthSagaWorker.restorePassword);
  yield takeLatest(setNewPasswordAction.request, AuthSagaWorker.setNewPassword);
  yield takeLatest(facebookRedirectAction, AuthSagaWorker.facebookRedirect);
  yield takeLatest(googleRedirectAction, AuthSagaWorker.googleRedirect);
  yield takeLatest(socialAuthAction.request, AuthSagaWorker.socialAuth);
  yield takeLatest(changeUserDataAction.request, AuthSagaWorker.changeUserData);
  yield takeLatest(uploadAvatarAction.request, AuthSagaWorker.uploadAvatar);
  yield takeLatest(removeAvatarAction.request, AuthSagaWorker.removeAvatar);
  yield takeLatest(deleteAccountAction.request, AuthSagaWorker.deleteAccount);
  yield takeLatest(clearAuthAction, AuthSagaWorker.clearLocalStorage);
}
