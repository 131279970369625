import { useSelector } from 'react-redux';
import Hint from '../../core/components/Hint';
import PageHeaderSection from '../../core/components/PageHeaderSection';
import {
  selectedUnitIdSelector,
} from '../../store/selectors/units';
import LightingPanel from './components/LightingPanel';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

const Lighting: React.FC = () => {
  const { t } = useTranslation();
  const unitId = useSelector(selectedUnitIdSelector);

  return (
    <Styled.Wrapper>
      <PageHeaderSection />
      <Styled.Body>
        <Styled.Container>
          {unitId && (
            <>
              <Styled.HelpSection>
                <Hint
                  text="TTTTT fdhgdfk  dfjlfdgljfldg kldflg"
                  placement="left"
                />
              </Styled.HelpSection>
              <LightingPanel />
            </>
          )}
        </Styled.Container>
      </Styled.Body>
    </Styled.Wrapper>
  );
};

export default Lighting;
