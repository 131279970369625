import { createReducer } from 'typesafe-actions';
import { Pod, Unit } from '@plant/data';
import {
  addPlantAction,
  changeStartDateAction,
  clearUnitAction,
  deletePlantAction,
  deleteUnitAction,
  getUnitsAction,
  rebootUnitAction,
  resetUnitAction,
  setSelectedUnitIdAction,
  stopLightingAction,
  UnitsActionUnion,
  updateSelectedUnitAction,
  changeUnitEnvironmentAction,
  removeNutritionAlertAction
} from '../actions/units';

export interface State {
  units: {
    [key in string]: Unit;
  };
  unitKeys: string[];
  selectedUnitId: string;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  units: {},
  unitKeys: [],
  selectedUnitId: null,
  loading: false,
  error: null,
};

export const reducer = createReducer<State, UnitsActionUnion>(initialState)
  .handleAction(getUnitsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getUnitsAction.success, (state, action) => {
    const defaultUnitUuid =
      (action.payload.defaultUnitUuid &&
        action.payload.units.filter((unit) => unit.uuid === action.payload.defaultUnitUuid)
          .length &&
        action.payload.defaultUnitUuid) ||
      action.payload.units[0].uuid;

    return {
      ...state,
      units: {
        ...state.units,
        ...action.payload.units.reduce(
          (acc, unit) => ({
            ...acc,
            [unit.uuid]: unit,
          }),
          {},
        ),
      },
      unitKeys: [...state.unitKeys, ...action.payload.units.map((unit) => unit.uuid)],
      selectedUnitId: defaultUnitUuid,
      loading: false,
    };
  })
  .handleAction(getUnitsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(setSelectedUnitIdAction, (state, action) => ({
    ...state,
    selectedUnitId: action.payload.uuid,
  }))
  .handleAction(updateSelectedUnitAction, (state, action) => ({
    ...state,
    units: {
      ...state.units,
      [state.selectedUnitId]: action.payload,
    },
  }))
  .handleAction(addPlantAction.success, (state, { payload }) => {
    const { pod, uuid, active, photo, video, plantActionDate, plantAction, crop, plaantUnit } =
      payload.response;

    const newPod: Pod = {
      number: pod.number,
      plant: {
        uuid,
        active,
        photo,
        video,
        plantActionDate,
        plantAction,
        crop,
      },
    };

    return {
      ...state,
      units: {
        ...state.units,
        [plaantUnit.uuid]: {
          ...state.units[state.selectedUnitId],
          pods: [...state.units[state.selectedUnitId].pods, newPod],
        },
      },
    };
  })
  .handleAction(addPlantAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(deletePlantAction.success, (state, action) => {
    return {
      ...state,
      units: {
        ...state.units,
        [state.selectedUnitId]: {
          ...state.units[state.selectedUnitId],
          pods: state.units[state.selectedUnitId].pods.filter(
            (pod) => pod.plant.uuid !== action.payload.plantUuid,
          ),
        },
      },
    };
  })
  .handleAction(deletePlantAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(deleteUnitAction.success, (state, action) => {
    const { [action.payload.unitUuid]: deleted, ...units } = state.units;

    return {
      ...state,
      unitKeys: state.unitKeys.filter((key) => key !== action.payload.unitUuid),
      units,
    };
  })
  .handleAction(deleteUnitAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(stopLightingAction.success, (state, { payload }) => ({
    ...state,
    units: {
      ...state.units,
      [payload.unitUuid]: {
        ...state.units[payload.unitUuid],
        settings: payload.settings,
      },
    },
  }))
  .handleAction(stopLightingAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(changeStartDateAction.success, (state, { payload }) => ({
    ...state,
    units: {
      ...state.units,
      [payload.unitUuid]: {
        ...state.units[payload.unitUuid],
        settings: payload.settings,
      },
    },
  }))
  .handleAction(changeStartDateAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(changeUnitEnvironmentAction, (state, action) => {
    const selectedUnit = state.units[state.selectedUnitId];

    return {
      ...state,
      units: {
        ...state.units,
        [state.selectedUnitId]: {
          ...selectedUnit,
          settings: {
            ...selectedUnit.settings,
            environment: action.payload,
          },
        },
      },
    };
  })
  .handleAction(rebootUnitAction.request, (state) => ({
    ...state,
  }))
  .handleAction(rebootUnitAction.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(rebootUnitAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(resetUnitAction.request, (state) => ({
    ...state,
  }))
  .handleAction(resetUnitAction.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(resetUnitAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(removeNutritionAlertAction.request, (state) => ({
    ...state,
  }))
  .handleAction(removeNutritionAlertAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    units: {
      ...state.units,
      [action.payload.unitUuid]: {
        ...state.units[action.payload.unitUuid],
        alerts: action.payload.data.alerts
      }
    }
  }))
  .handleAction(removeNutritionAlertAction.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(clearUnitAction, () => initialState);
