import { styled } from '@plant/ui';

export const Container = styled('button')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  borderBottom: '1px solid #EBF0EF',
  backgroundColor: 'transparent',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  paddingRight: 5,
  paddingTop: 15,
  paddingBottom: 15,

  '&:hover': {
    backgroundColor: '#F0F5F4',
  },

  '&:last-child': {
    borderBottom: 'none',
  },

  '& svg': {
    fill: theme.palette.light.light,
  },
}));

export const Text = styled('h6')(({ theme }) => ({
  margin: 0,
  marginLeft: 10,
  marginRight: 'auto',

  color: theme.palette.secondary.main,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
}));

export const ArrowContainer = styled('div')({
  transform: 'rotate(-90deg)',
});
