import { createReducer } from 'typesafe-actions';
import { Product } from '@plant/data';
import { StoreActionUnion, requestProductsAction } from '../actions/store';

export interface State {
  keys: string[];
  products: {
    [key in string]: Product;
  };
  loading: boolean;
  error: string;
}

export const initialState: State = {
  keys: [],
  products: {},
  loading: false,
  error: null,
};

export const reducer = createReducer<State, StoreActionUnion>(initialState)
  .handleAction(requestProductsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(requestProductsAction.success, (state, action) => ({
    ...state,
    keys: action.payload.products.map((product) => product.id),
    crops: {
      ...state.products,
      ...action.payload.products.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {}),
    },
    error: null,
    loading: false,
  }))
  .handleAction(requestProductsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
