import React from 'react';
import * as Styled from './styles';

interface ListItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  text: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  textAlign?: 'center' | 'left';
}

const ListItem: React.FC<ListItemProps> = ({
  text,
  textAlign = 'center',
  leftIcon,
  rightIcon,
  ...props
}) => {
  return (
    <Styled.PopupItem {...props}>
      {leftIcon}
      <Styled.PopupItemText
        sx={{
          textAlign,
          paddingLeft: leftIcon && '10px',
          paddingRight: rightIcon && '10px',
        }}
      >
        {text}
      </Styled.PopupItemText>
      {rightIcon}
    </Styled.PopupItem>
  );
};

export default React.memo(ListItem);
