import { t } from 'i18next';

export const speedMarks = [
  { value: 1, label: '1x' },
  { value: 5, label: '5x' },
  { value: 10, label: '10x' },
  { value: 15, label: '15x' },
];

export enum ModalSteps {
  firstStep = 0,
  secondStep = 1,
}

export const containerPadding = 15;
