import { styled } from '@plant/ui';
import { NavLink } from 'react-router-dom';

export const NavBar = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  width: 105,
  padding: '30px 7px',
  height: '100%',
  zIndex: 99,

  display: 'grid',
  gridTemplateRows: '100px auto 100px',

  backgroundColor: theme.palette.light.main,

  borderRight: '1px solid rgba(42, 92, 68, 0.1)',
}));

export const LogoWrapper = styled('div')({
  marginBottom: 120,
  display: 'flex',
  justifyContent: 'center',
});

export const NavContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 4,
  width: '100%',
});

export const Item = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: 90,
  paddingTop: 20,
  paddingBottom: 10,

  transition: 'all .1s linear',

  backgroundColor: '#transparent',
  '& svg': {
    fill: theme.palette.light.light,
  },

  '& span': {
    color: theme.palette.light.light,
  },
  '&[aria-current]': {
    backgroundColor: '#EBF4EF',
    '& span': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },

  borderRadius: 10,
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '#EBF4EF',
  },
}));

export const NavigateItem = styled('a')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: 90,
  paddingTop: 20,
  paddingBottom: 10,

  transition: 'all .1s linear',

  backgroundColor: '#transparent',
  '& svg': {
    fill: theme.palette.light.light,
  },

  '& span': {
    color: theme.palette.light.light,
  },
  '&[aria-current]': {
    backgroundColor: '#EBF4EF',
    '& span': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },

  borderRadius: 10,
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '#EBF4EF',
  },
}));

export const ItemIcon = styled('img')({
  width: 28,
});

export const ItemLabel = styled('span')({
  fontSize: 10,
  fontWeight: 600,
  lineHeight: '14px',
});

export const SettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 30,
  '& svg': {
    fill: '#A7B2AE',
  },
});

export const SettingsIcon = styled('img')({
  width: 26,
  cursor: 'pointer',
});

export const SettingsLink = styled(NavLink)(({ theme }) => ({
  '&[aria-current]': {
    '& svg': {
      fill: theme.palette.secondary.main,
    },
  },
}));
