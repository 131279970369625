import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ShareIcon } from '@plant/ui';
import * as Styled from './styles';
import { unitImagesSelector } from '../../../../store/selectors/units';
import { authTokenSelector } from '../../../../store/selectors/auth';
import { imagesToMarks } from './data';
import useTimelapseModal from '../../../../core/components/modals/Timelapse';
import { MONTHS } from '../../../../core/constants/months';

const TimelinePreview = () => {
  const { t } = useTranslation();
  const images = useSelector(unitImagesSelector);
  const token = useSelector(authTokenSelector);
  const [showTimelapseModal] = useTimelapseModal();

  const [imageIndex, setImageIndex] = useState(60);

  useEffect(() => {
    if (images) setImageIndex(images.length - 1);
  }, [images]);

  const handleShowTimepalseModal = () => {
    if (images?.length) showTimelapseModal();
  };

  const handleChange = (_, newValue: number) => {
    setImageIndex(newValue);
  };

  const sliderLabel = (index: number) => {
    const date = new Date(images[index]?.createdOn);
    return `${date.getDate()} ${MONTHS[date.getMonth()]}`;
  };

  return (
    <Styled.Container>
      <Styled.HeaderSection>
        <Styled.Title>{t('timelapse.title')}</Styled.Title>
        <Styled.ButtonsSection>
          <Styled.ContainedButton onClick={handleShowTimepalseModal}>
            <Styled.ButtonText>{t('timelapse.share')}</Styled.ButtonText>
            <ShareIcon width={14} height={11} />
          </Styled.ContainedButton>
        </Styled.ButtonsSection>
      </Styled.HeaderSection>
      {images?.length > 0 ? (
        <Styled.TimelapseImage
          src={`${images[imageIndex]?.url}?token=${token}`}
        />
      ) : (
        <Styled.ImagePlaceholder>
          <Styled.ImageTextPlaceholder>
            {t('timelapse.noImages')}
          </Styled.ImageTextPlaceholder>
        </Styled.ImagePlaceholder>
      )}
      {images && (
        <Styled.SliderContainer>
          <Styled.CustomSlider
            value={imageIndex}
            onChange={handleChange}
            valueLabelDisplay={images && images.length ? 'on' : 'off'}
            valueLabelFormat={sliderLabel}
            marks={images && imagesToMarks(images)}
            min={0}
            max={images && images.length - 1}
            step={null}
          />
        </Styled.SliderContainer>
      )}
    </Styled.Container>
  );
};

export default React.memo(TimelinePreview);
