import * as yup from 'yup';
import { t as TFunction } from 'i18next';

export const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/;
export const latinRegExp = /^[a-zA-Z]+$/;
export const MIN_USER_NAME_LENGTH = 2;
export const MAX_USER_NAME_LENGTH = 30;
export const PASS_MIN_LENGTH = 6;

export const getPasswordSchema = (
  t: typeof TFunction,
  length: number = PASS_MIN_LENGTH,
  passReg: RegExp = passRegExp
) =>
  yup
    .string()
    .min(length, t('errorMessages.minPass', { length }))
    .required(t('errorMessages.passwordIsRequired'))
    .matches(/^\S*$/, t('errorMessages.whitespace'))
    .matches(passReg, t('errorMessages.passReg', { length }));
