import { styled, css, mountingAnimation } from '@plant/ui';

export const Circle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: 45,
  height: 45,
  cursor: 'pointer',

  '& svg': {
    width: 22,
    fill: theme.palette.light.main,
  },
}));

export const Icon = styled('img')(({ theme }) => ({}));

export const SmallCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -3,
  right: -3,
  backgroundColor: theme.palette.error.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 18,
  height: 18,
  cursor: 'pointer',

  boxShadow: '0px 3.21429px 3.21429px rgba(30, 52, 41, 0.24)',
}));

export const AlertsCount = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '12px',
  color: theme.palette.light.main,
}));

export const Paper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
  zIndex: 3,
});

export const NotificationsPopup = styled('div')(
  ({ theme }) => ({
    position: 'absolute',
    top: '100%',
    right: 0,
    marginTop: 16,
    zIndex: 4,

    width: 330,
    padding: 16,
    backgroundColor: theme.palette.light.main,
    borderRadius: 8,
    cursor: 'default',
    userSelect: 'none',

    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  }),
  css`
    animation: ${mountingAnimation} 0.2s linear;
  `
);

export const NotificationsPopupTitle = styled('h3')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',
  color: theme.palette.secondary.main,
  margin: 0,
}));

export const Notification = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '12px 15px',
  borderWidth: 2,
  borderStyle: 'dashed',
  borderRadius: 15,
  color: '#455458',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',

  display: 'flex',
  alignItems: 'center',
  gap: 12,
}));

export const TempNotification = styled(Notification)({
  borderColor: '#FFD15C',

  '& svg': {
    fill: '#FFD15C',
  },
});

export const WaterNotification = styled(Notification)({
  borderColor: '#EE7674',

  '& svg': {
    fill: '#EE7674',
  },
});

export const NutritionNotification = styled(Notification)(({ theme }) => ({
  borderColor: '#FFD15C',

  '& svg': {
    fill: '#926800',
  },
}));

export const SensorsNotification = styled(Notification)(({ theme }) => ({
  borderColor: '#EE7674',

  '& svg': {
    fill: '#EE7674',
  },
}));

export const LeakNotification = styled(Notification)({
  borderColor: '#0189D6',

  '& svg': {
    fill: '#0189D6',
  },
});

export const ConnectionNotification = styled(Notification)({
  borderColor: '#99C6B0',

  '& svg': {
    fill: '#99C6B0',
  },
});


export const NotificationText = styled('div')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  color: '#455458',
}));

export const DoneButton = styled('button')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  border: '1px solid #FFD15C',
  cursor: 'pointer',
  background: 'none',
  borderRadius: 15,
  padding: '5px 10px',
}))