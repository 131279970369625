import { ActionType, createAsyncAction } from 'typesafe-actions';
import { NotificationsData } from '@plant/data';

export enum NotificationsTypes {
  RequestNotifications = '[NOTIFICATIONS] RequestNotifications',
  RequestNotificationsSuccess = '[NOTIFICATIONS] RequestNotificationsSuccess',
  RequestNotificationsFailed = '[NOTIFICATIONS] RequestNotificationsFailed',

  UpdateNotifications = '[NOTIFICATIONS] UpdateNotifications',
  UpdateNotificationsSuccess = '[NOTIFICATIONS] UpdateNotificationsSuccess',
  UpdateNotificationsFailed = '[NOTIFICATIONS] UpdateNotificationsFailed',
}

export const getNotificationsAction = createAsyncAction(
  NotificationsTypes.RequestNotifications,
  NotificationsTypes.RequestNotificationsSuccess,
  NotificationsTypes.RequestNotificationsFailed
)<void, NotificationsData, string>();

export const updateNotificationsAction = createAsyncAction(
  NotificationsTypes.UpdateNotifications,
  NotificationsTypes.UpdateNotificationsSuccess,
  NotificationsTypes.UpdateNotificationsFailed
)<NotificationsData, NotificationsData, string>();

export type NotificationsUnion = ActionType<
  typeof getNotificationsAction | typeof updateNotificationsAction
>;
