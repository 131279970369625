import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { HarvestIcon, LifespanIcon, ReplantIcon } from '@plant/ui';
import * as Styled from './styles';
import {
  ButtonsContainer,
  SectionContainer,
  SectionText,
  SectionTextWrapper,
  SectionTitle,
} from '../../styles';
import { useSelector } from 'react-redux';
import { authTokenSelector } from '../../../../store/selectors/auth';
import { imageWithToken } from '../../../../core/helpers/image-with-token.helper';

interface HarvestProps {
  lifespan: {
    name: string;
    description: string;
    time: string;
  };
  harvest: {
    name: string;
    description: string;
    time: string;
    image?: string;
  };
  replant: {
    name: string;
    description: string;
    time: string;
  };
}

const Harvest: React.FC<HarvestProps> = ({ lifespan, harvest, replant }) => {
  const { t } = useTranslation();
  const token = useSelector(authTokenSelector);
  const [selectedInex, setSelectedIndex] = useState(0);

  const handleSelect = (index: number) => () => {
    setSelectedIndex(index);
  };

  return (
    <SectionContainer>
      <SectionTitle>{t('cropGuide.harvest')}</SectionTitle>
      <ButtonsContainer>
        <Styled.Button
          onClick={handleSelect(0)}
          isSelected={0 === selectedInex}
        >
          <Styled.IconContainer>
            <HarvestIcon width={15} height={13} />
          </Styled.IconContainer>
          <Styled.TextContainer>
            {t('cropGuide.harvestButton')}
            <Styled.Time>{harvest?.time}</Styled.Time>
          </Styled.TextContainer>
        </Styled.Button>
        <Styled.Button
          onClick={handleSelect(1)}
          isSelected={1 === selectedInex}
        >
          <Styled.IconContainer>
            <ReplantIcon width={25} height={11} />
          </Styled.IconContainer>
          <Styled.TextContainer>
            {t('cropGuide.replantButton')}
            <Styled.Time>{replant?.time}</Styled.Time>
          </Styled.TextContainer>
        </Styled.Button>
        <Styled.Button
          onClick={handleSelect(2)}
          isSelected={2 === selectedInex}
        >
          <Styled.IconContainer>
            <LifespanIcon width={15} height={17} />
          </Styled.IconContainer>
          <Styled.TextContainer>
            {t('cropGuide.lifespanButton')}
            <Styled.Time>{lifespan?.time}</Styled.Time>
          </Styled.TextContainer>
        </Styled.Button>
      </ButtonsContainer>
      <SwipeableViews index={selectedInex}>
        <SectionTextWrapper>
          <SectionText>{harvest?.description}</SectionText>
          {harvest?.image && <img src={imageWithToken(harvest.image, token)} />}
        </SectionTextWrapper>
        <SectionTextWrapper>
          <SectionText>{replant?.description}</SectionText>
        </SectionTextWrapper>
        <SectionTextWrapper>
          <SectionText>{lifespan?.description}</SectionText>
        </SectionTextWrapper>
      </SwipeableViews>
    </SectionContainer>
  );
};

export default Harvest;
