import { styled } from '@plant/ui';

export const Container = styled('div')({
  maxWidth: 550,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Title = styled('h4')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  lineHeight: '22px',
  color: theme.palette.green.dark,
  margin: 0,
  marginBottom: 14,
}));

export const Hr = styled('hr')(({ theme }) => ({
  border: 'none',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.light.dark,
  width: '100%',
  margin: 0,
  opacity: 0.3,
  marginBottom: 50,
}));

export const Subject = styled('h6')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '24px',
  color: theme.palette.green.dark,
  letterSpacing: 0.15,
  margin: 0,
  marginBottom: 20,
}));

export const Artice = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.light.main,
  padding: 20,
  borderRadius: 15,
  marginBottom: 50,

  color: theme.palette.green.dark,

  '& h6': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.15,
    marginBottom: 14,
    marginTop: 0,
  },
  '& p': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20px',
    marginTop: 0,
    marginBottom: 20,

    '&:last-child': {
      marginBottom: 0,
    },
  },
}));
