import React from 'react';
import SocialLink from '../SocialLink';
import { useDispatch } from 'react-redux';
import { facebookRedirectAction } from '../../../store/actions/auth';

const FacebookLink: React.FC = () => {
  const dispatch = useDispatch();
  const signIn = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(facebookRedirectAction());
  };

  return <SocialLink variant="facebook" onClick={signIn} />;
};

export default FacebookLink;
