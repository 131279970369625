import { FC } from 'react';
import { ModalContainer } from '../constants/modalContainer';

export interface ModalOptions {
  close: () => void;
}

export interface ModalInstance {
  id: string;
  component: FC;
  container: ModalContainer;
  options: object;
}

export class ModalsService {
  static MODAL_INSTANCES: {
    [id: string]: ModalInstance;
  } = {};

  static addModal = (
    id: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: FC<any>,
    options: ModalInstance['options'] = {},
    container = ModalContainer.Root
  ) => {
    ModalsService.MODAL_INSTANCES[id] = {
      id,
      component,
      container,
      options,
    };
  };

  static removeModal = (id: string) => {
    delete ModalsService.MODAL_INSTANCES[id];
  };

  static getModal = (id: string) => ModalsService.MODAL_INSTANCES[id];
}
