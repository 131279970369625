import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const actionsStoreSelector = (state: AppState) => state.plantActions;

export const plantActionsLoadingSelector = createSelector(
  actionsStoreSelector,
  (state) => state.loading
);
export const plantActionsDataSelector = createSelector(
  actionsStoreSelector,
  (state) => state.actions
);
