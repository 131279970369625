import { FC, useEffect } from 'react';
import * as Styled from './styles';
import PlantSelect from './components/PlantSelect';
import AddBox from './components/AddBox';
import UnitPanel from './components/UnitPanel';
import AlertBox from './components/AlertBox';
import { useDispatch, useSelector } from 'react-redux';
import { startFetchingUpdatesAction, stopFetchingUpdatesAction } from '../../store/actions/units';
import { selectedUnitIdSelector, unitsLoadingSelector } from '../../store/selectors/units';
import Loader from '../../core/components/Loader';
import Environment from './components/Environment';
import TimelinePreview from './components/TimelapsePreview';

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(unitsLoadingSelector);
  const selectedUnitId = useSelector(selectedUnitIdSelector);

  useEffect(() => {
    if (selectedUnitId) dispatch(startFetchingUpdatesAction({ uuid: selectedUnitId }));

    return () => {
      dispatch(stopFetchingUpdatesAction());
    };
  }, [dispatch, selectedUnitId]);

  return (
    <Styled.Wrapper>
      <Styled.Container>
        {loading ? (
          <Styled.LoaderContainer>
            <Loader />
          </Styled.LoaderContainer>
        ) : (
          <>
            <Styled.HeaderContainer>
              <PlantSelect />
              <Styled.BoxsContainer>
                <AlertBox />
                <AddBox />
              </Styled.BoxsContainer>
            </Styled.HeaderContainer>
            <UnitPanel />
            <Styled.BottomContainer>
              <Environment />
              <TimelinePreview />
            </Styled.BottomContainer>
          </>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default Dashboard;
