import { ActionType, createAsyncAction } from 'typesafe-actions';
import { SocialTypes } from '../../core/constants/socialTypes';

export enum TimelapseTypes {
  saveTimelapse = '[TIMELAPSE] SaveTimelapse',
  saveTimelapseSuccess = '[TIMELAPSE] SaveTimepalseSuccess',
  saveTimelapseFailed = '[TIMELAPSE] SaveTimelapseFailed',

  ShareFile = '[TIMELAPSE] ShareFile',
  ShareFileSuccess = '[TIMELAPSE] ShareFileSuccess',
  ShareFileFailed = '[TIMELAPSE] ShareFileFailed',
}

export const saveTimelapseAction = createAsyncAction(
  TimelapseTypes.saveTimelapse,
  TimelapseTypes.saveTimelapseSuccess,
  TimelapseTypes.saveTimelapseFailed,
)<
  { unitUuid: string; speed: number; startDate: string; endDate: string },
  { video: string },
  string
>();

export const shareFileAction = createAsyncAction(
  TimelapseTypes.ShareFile,
  TimelapseTypes.ShareFileSuccess,
  TimelapseTypes.ShareFileFailed,
)<{ url: string; type: SocialTypes }, string, string>();

export type TimelapseActionUnion = ActionType<typeof saveTimelapseAction | typeof shareFileAction>;
