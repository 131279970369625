import {
  AboutIcon,
  FaqIcon,
  ManagmentIcon,
  NotificationsIcon,
  PersonalIcon,
} from '@plant/ui';
import { AppRoutes } from '../../core/constants/appRoutes';
import { NavBarItem } from '../../core/interfaces/navBarItem';

export const SettingsRoutes: NavBarItem[] = [
  {
    path: AppRoutes.Personal,
    label: 'settings.personal',
    icon: PersonalIcon,
  },
  {
    path: AppRoutes.Notifications,
    label: 'settings.notifications',
    icon: NotificationsIcon,
  },
  {
    path: AppRoutes.About,
    label: 'settings.about',
    icon: AboutIcon,
  },
  {
    path: AppRoutes.UnitManagment,
    label: 'settings.unitManagment',
    icon: ManagmentIcon,
  },
  {
    path: AppRoutes.FAQ,
    label: 'settings.faq',
    icon: FaqIcon,
  },
];
