import { Accordion } from '@mui/material';
import { styled } from '@plant/ui';

export const StyledAccordion = styled(Accordion)({
  borderRadius: '15px !important',
  boxShadow: 'none',

  '&::before': {
    display: 'none',
  },
});

export const AccordionHeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SecondaryMainIcon = styled('div')(({ theme }) => ({
  marginRight: 10,
  '& svg': {
    fill: theme.palette.secondary.main,
  },
}));

export const ArrowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    fill: theme.palette.secondary.main,
  },
}));

export const AccordionTitle = styled('h4')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',

  margin: 0,
}));

export const Arrow = styled('div')({
  border: '2px solid #2A5C44',
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
});

export const ActionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});
