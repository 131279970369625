import {
  call,
  Effect,
  put,
  SagaReturnType,
  takeLatest,
} from 'redux-saga/effects';
import { PlantService } from '@plant/data';
import { getPlantActionsAction } from '../actions/plantActions';

export class ActionsSagaWorker {
  static *getActions() {
    try {
      const actions: SagaReturnType<typeof PlantService.getActions> =
        yield call(PlantService.getActions);
      yield put(getPlantActionsAction.success({ actions }));
    } catch (error) {
      yield put(getPlantActionsAction.failure(error?.response?.data?.error));
    }
  }
}

export function* actionsSaga(): Generator<Effect, void> {
  yield takeLatest(getPlantActionsAction.request, ActionsSagaWorker.getActions);
}
