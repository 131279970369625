import { createReducer } from 'typesafe-actions';
import { NotificationsData } from '@plant/data';
import {
  getNotificationsAction,
  NotificationsUnion,
  updateNotificationsAction,
} from '../actions/notifications';

export interface State {
  data: NotificationsData;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  data: null,
  loading: false,
  error: null,
};

export const reducer = createReducer<State, NotificationsUnion>(initialState)
  .handleAction(getNotificationsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getNotificationsAction.success, (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false,
    error: null,
  }))
  .handleAction(getNotificationsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(updateNotificationsAction.success, (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false,
    error: null,
  }))
  .handleAction(updateNotificationsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
