import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styles';
import Loader from '../../core/components/Loader';
import { contentAboutSelector, contentLoadingSelector } from '../../store/selectors/faq';
import { getAboutAction } from '../../store/actions/content';

const About = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(contentLoadingSelector);
  const data = useSelector(contentAboutSelector);

  useEffect(() => {
    if (!data) dispatch(getAboutAction.request({ page: 1, count: 25 }));
  }, []);

  return (
    <Styled.Container>
      <Styled.Title>{t('about.title')}</Styled.Title>
      <Styled.Hr />
      {loading ? (
        <Loader />
      ) : (
        <>
          {data?.map(({ subject, content }) => {
            return (
              <>
                <Styled.Subject>{subject}</Styled.Subject>
                <Styled.Artice>
                  {content.map((item) => (
                    <>
                      <h6>{item.title}</h6>
                      <p>{item.text}</p>
                    </>
                  ))}
                </Styled.Artice>
              </>
            );
          })}
        </>
      )}
    </Styled.Container>
  );
};

export default React.memo(About);
