import * as yup from 'yup';
import { t as TFunction } from 'i18next';
import {
  getPasswordSchema,
} from './yupSchemas';

export const registerSchema = (t: typeof TFunction) =>
  yup.object().shape({
    username: yup
      .string(),
    userSurname: yup
      .string(),
    email: yup
      .string()
      .email(t('errorMessages.correctEmail'))
      .required(t('errorMessages.emailIsRequired')),
    password: getPasswordSchema(t),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('errorMessages.passwordMustMatch'))
      .required(t('errorMessages.confirmPasswordIsRequired')),
    acceptTerms: yup.bool().oneOf([true], t('errorMessages.acceptTerms')),
  });
