import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { config, slides } from './data';
import * as Styled from './styles';
import { ISlide } from './type';

const TextCarousel: FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper>
      <Carousel {...config}>
        {slides.map((slide: ISlide) => (
          <Styled.SlideHolder key={slide.id}>
            <Styled.ImageWrapper>
              <Styled.Image src={slide.src} alt="img" />
              <Styled.ImageWrapperShadow />
            </Styled.ImageWrapper>
            <Styled.SlideTitle>{t(slide.title)}</Styled.SlideTitle>
            <Styled.SlideText>{t(slide.text)}</Styled.SlideText>
          </Styled.SlideHolder>
        ))}
      </Carousel>
    </Styled.Wrapper>
  );
};

export default React.memo(TextCarousel);
