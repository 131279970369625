import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const timelapseStoreSelector = (state: AppState) => state.timelapse;

export const timelapseLoadingSelector = createSelector(
  timelapseStoreSelector,
  (state) => state.loading
);
export const timelapseVideoSelector = createSelector(
  timelapseStoreSelector,
  (state) => state.video
);
