import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import avatarPlaceholder from '../../../assets/images/navbar/avatar.png';
import { clearAuthAction } from '../../../store/actions/auth';
import { clearUnitAction } from '../../../store/actions/units';
import { authDataSelector, authTokenSelector, avatarSelector } from '../../../store/selectors/auth';
import { imageWithToken } from '../../helpers/image-with-token.helper';
import * as Styled from './styles';
import { UserAvatarProps } from './types';

const UserAvatar: React.FC<UserAvatarProps> = ({ variant = 'small', withPopup = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const photo = useSelector(avatarSelector);
  const userData = useSelector(authDataSelector);
  const token = useSelector(authTokenSelector);

  const clickHandler = () => {
    if (withPopup) setIsOpen((value) => !value);
  };

  const handleLogout = () => {
    dispatch(clearAuthAction());
    dispatch(clearUnitAction());
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Avatar
          isSelected={isOpen}
          src={photo ? imageWithToken(photo, token) : avatarPlaceholder}
          onClick={clickHandler}
          variant={variant}
          withPopup={withPopup}
        />
        {isOpen && (
          <Styled.Popup>
            {userData?.firstName ||
              (userData?.lastName && (
                <Styled.Name>{`${userData?.firstName || ''} ${
                  userData?.lastName || ''
                }`}</Styled.Name>
              ))}

            <Styled.Email>{userData?.email}</Styled.Email>
            <Styled.Button onClick={handleLogout}>{t('auth.logout')}</Styled.Button>
          </Styled.Popup>
        )}
      </Styled.Wrapper>
      {isOpen && <Styled.Paper onClick={clickHandler} />}
    </>
  );
};

export default UserAvatar;
