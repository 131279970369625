import { useSelector } from 'react-redux';
import { Environment } from '@plant/data';
import { environmentsDataSelector } from '../../store/selectors/environments';
import { selectedUnitEnvironmentIdSelector } from '../../store/selectors/units';
import { useMemo } from 'react';

export function useEnvironment(): Environment {
  const environments = useSelector(environmentsDataSelector);
  const selectedEnvironmentId = useSelector(selectedUnitEnvironmentIdSelector);

  const environment = useMemo(
    () => environments.find((env) => env.uuid === selectedEnvironmentId),
    [selectedEnvironmentId, environments]
  );
  return environment;
}
