import * as yup from 'yup';
import { t as TFunction } from 'i18next';

export const loginSchema = (t: typeof TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('errorMessages.correctEmail'))
      .required(t('errorMessages.emailIsRequired')),
    password: yup.string().required(t('errorMessages.passwordIsRequired')),
  });
