import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppLanguages } from '@plant/data';
import enJson from './locales/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enJson,
    },
  },
  lng: AppLanguages.En,
  fallbackLng: AppLanguages.En,
  interpolation: {
    escapeValue: false,
  },
});

export { i18n };
