import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { googleRedirectAction, socialAuthAction } from '../../../store/actions/auth';
import SocialLink from '../SocialLink';

const GoogleLink: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = (e: ChangeEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(googleRedirectAction());
  };

  return <SocialLink variant="google" onClick={handleClick} />;
};

export default GoogleLink;
