import { Modal } from '@mui/material';
import { styled, ScrollBarStyles } from '@plant/ui';

export const StyledModal = styled(Modal)({
  zIndex: 69,
});

export const Container = styled('div')({
  backgroundColor: '#F0F5F4',
  padding: 40,
  width: 490,
  height: '100%',
  position: 'absolute',
  left: 105,
  top: 0,
  bottom: 0,
  boxShadow: '0px 14px 44px rgba(36, 47, 45, 0.42)',
  outline: 'none',
  overflowY: 'scroll',
  ...ScrollBarStyles,
});

export const HeaderSection = styled('div')({
  width: '100%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 15,
});

export const ModalTitle = styled('h1')(({ theme }) => ({
  justifySelf: 'center',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: theme.palette.secondary.main,
}));

export const CircleContainer = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  boxShadow: '0px 4.66667px 4.66667px rgba(0, 0, 0, 0.07)',
  backgroundColor: theme.palette.light.main,
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all .1s linear',

  '&:hover': {
    transform: 'translateY(-2px)',
  },

  '& svg': {
    stroke: '#1F483E',
  },
}));

export const Hr = styled('hr')({
  border: 'none',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: '#A7B2AE',
  margin: 0,
  opacity: 0.3,
  marginBottom: 50,
});

export const Unit = styled('div')({
  width: 190,
  height: 80,
  borderRadius: 13,
  border: '1px solid #C2D0CD',
  backgroundColor: '#DAE4E0',
  padding: '17px 20px',

  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-around',

  '& :nth-child(3)': {
    margin: '20px 0',
  },

  '& :nth-child(6)': {
    margin: '20px 0',
  },

  margin: '0 auto',
  marginBottom: 40,
});

export const Pod = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  width: 18,
  height: 18,
  borderRadius: '50%',
  border: `2px solid ${
    isSelected ? theme.palette.secondary.main : 'rgba(42, 92, 68, 0.3)'
  }`,
  backgroundColor: isSelected ? theme.palette.light.main : 'transparent',

  fontWeight: 800,
  fontSize: 9,
  lineHeight: 9,
  color: theme.palette.secondary.main,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SubTitle = styled('h6')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '15px',
  color: theme.palette.secondary.main,
  marginBottom: 26,
}));

export const PodsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export const ArrowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'rotate(90deg)',

  '& svg': {
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
  },
}));

export const SearchInput = styled('input')(({ theme }) => ({
  padding: 17,
  paddingLeft: 45,
  borderRadius: 15,
  border: 'none',
  outline: 'none',
  backgroundColor: '#E2EAE8',

  fontWeight: 400,
  fontSize: 16,
  lineHeight: '16px',
  color: theme.palette.secondary.main,

  width: '100%',
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginBottom: 15,

  '& svg': {
    fill: theme.palette.light.light,
    stroke: theme.palette.light.light,
    position: 'absolute',
    left: 15,
    top: 0,
    bottom: 0,
    margin: 'auto 0',
  },
}));

export const SearchResultsContainer = styled('div')(({ theme }) => ({
  borderRadius: 15,
  backgroundColor: theme.palette.light.main,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 15,
}));

export const SearchItemIcon = styled('img')({
  width: 20,
});

export const LoaderContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0',
});
