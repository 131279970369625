import React from 'react';
import { useSelector } from 'react-redux';
import { unitsLoadingSelector } from '../../../store/selectors/units';
import Loader from '../Loader';
import PlantSelect from './components/PlantSelect';
import * as Styled from './styles';

const PageHeaderSection = () => {
  const loading = useSelector(unitsLoadingSelector);

  return (
    <Styled.Header>
      <Styled.Container>
        {loading ? <Loader /> : <PlantSelect />}
      </Styled.Container>
    </Styled.Header>
  );
};

export default React.memo(PageHeaderSection);
