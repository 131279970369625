import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ApiService, EventsTypes } from '@plant/data';
import AppRouter from './core/components/AppRouter';
import { appIsReadySelector } from './store/selectors/app';
import { startAppAction } from './store/actions/app';
import Loader from './core/components/Loader';
import { getUnitsAction } from './store/actions/units';
import { getPlantActionsAction } from './store/actions/plantActions';
import { authTokenSelector } from './store/selectors/auth';
import { getEnvironmentsAction } from './store/actions/environments';
import { searchCropsAction } from './store/actions/searchCrops';
import { clearAuthAction } from './store/actions/auth';
import { useTranslation } from 'react-i18next';

export function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appIsReady = useSelector(appIsReadySelector);
  const authToken = useSelector(authTokenSelector);

  const logout = () => {
    dispatch(clearAuthAction());
    toast(`${t('auth.notifications.sessionExpired')}`, { type: 'info' });
  };

  useEffect(() => {
    dispatch(startAppAction.request());

    ApiService.emitter.addEventListener(EventsTypes.logout, logout);

    return () => {
      ApiService.emitter.removeEventListener(EventsTypes.logout, logout);
    };
  }, [dispatch]);

  useEffect(() => {
    if (authToken) {
      dispatch(getUnitsAction.request());
      dispatch(getPlantActionsAction.request());
      dispatch(getEnvironmentsAction.request());
      dispatch(searchCropsAction.request({ name: '' }));
    }
  }, [dispatch, authToken]);

  return (
    <>
      {appIsReady ? (
        <AppRouter />
      ) : (
        <div className="loaderContainer">
          <Loader />
        </div>
      )}
    </>
  );
}

export default App;
