import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ThemeProvider, defaultTheme } from '@plant/ui';
import { Provider } from 'react-redux';

import App from './app';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { i18n } from './core/i18n';
import createStore from './store';
import ModalsContainer from './core/components/ModalsContainer';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/bubble.css';
import 'react-toastify/dist/ReactToastify.min.css';

export const { store } = createStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={defaultTheme}>
          <ModalsContainer />
          <ToastContainer />
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>
  </StrictMode>,
);
