import { css, mountingAnimation, styled, TitledField } from '@plant/ui';

export const Wrapper = styled('div')({
  position: 'relative',
});

export const CustomTitledField = styled(TitledField, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isActive',
})<{ isOpen: boolean; isActive: boolean }>(({ isOpen, isActive }) => ({
  borderRadius: isOpen ? '15px 15px 0px 0px' : 15,
  cursor: isActive ? 'pointer' : 'default',
  marginBottom: 0,
  userSelect: 'none',
  boxShadow: 'none',

  '& svg': {
    fill: '#1F483E',
    transform: isOpen ? 'rotate(-180deg)' : 'rotate(-90deg)',
    transition: 'all .2s linear',
  },
}));

export const SelectedItem = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',
  color: theme.palette.secondary.main,
}));

export const Popup = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.light.main,
    padding: 20,
    paddingTop: 0,
    borderRadius: '0 0 15px 15px',
    boxShadow: '0px 29px 64px rgba(26, 54, 49, 0.25)',

    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
  }),
  css`
    animation: ${mountingAnimation} 0.1s linear;
  `
);

export const PopupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  maxHeight: 250,
  overflowY: 'auto',

  '::-webkit-scrollbar': {
    width: 1,
  },
});

export const Hr = styled('hr')({
  margin: 0,
  marginBottom: 16,
  backgroundColor: '#A7B2AE',
  opacity: 0.3,
});
