import { styled } from '@plant/ui';

export const Link = styled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  color: theme.palette.secondary.light,
  fontWeight: 700,
  fontSize: 12,
  cursor: 'pointer',
  padding: 0,
}));
