import { styled } from '@plant/ui';

export const DefaultSocialLink = styled('button')({
  width: 56,
  height: 46,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 50,
  cursor: 'pointer',
  border: 'none',

  '& svg': {
    fill: 'white',
  },
});

export const GoogleSocialLink = styled(DefaultSocialLink)({
  backgroundColor: '#DF4A38',
});

export const FacebookSocialLink = styled(DefaultSocialLink)({
  backgroundColor: '#3B5998',
});

export const InstagramSocialLink = styled(DefaultSocialLink)({
  background:
    'linear-gradient(39.41deg, #FFBA5F 16.92%, #F1498C 51.64%, #AA51B3 86.4%)',
});
