import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Environment } from '@plant/data';

export enum EnvironmentsTypes {
  RequestEnvironments = '[ENVIRONMENTS] RequestEnvironments',
  RequestEnvironmentsSuccess = '[ENVIRONMENTS] RequestEnvironmentsSuccess',
  RequestEnvironmentsFailed = '[ENVIRONMENTS] RequestEnvironmentsFailed',

  ChangeEnvironment = '[ENVIRONMENTS] ChangeEnvironment',
  ChangeEnvironmentSuccess = '[ENVIRONMENTS] ChangeEnvironmentSuccess',
  ChangeEnvironmentFailed = '[ENVIRONMENTS] ChangeEnvironmentFailed',

  AddEnvironment = '[ENVIRONMENTS] AddEnvironment',
  AddEnvironmentSuccess = '[ENVIRONMENTS] AddEnvironmentSuccess',
  AddEnvironmentFailed = '[ENVIRONMENTS] AddEnvironmentFailed',

  DeleteEnvironment = '[ENVIRONMENTS] DeleteEnvironment',
  DeleteEnvironmentSuccess = '[ENVIRONMENTS] DeleteEnvironmentSuccess',
  DeleteEnvironmentFailed = '[ENVIRONMENTS] DeleteEnvironmentFailed',
}

export const getEnvironmentsAction = createAsyncAction(
  EnvironmentsTypes.RequestEnvironments,
  EnvironmentsTypes.RequestEnvironmentsSuccess,
  EnvironmentsTypes.RequestEnvironmentsFailed,
)<void, { environments: Environment[] }, string>();

export const changeEnvironmentAction = createAsyncAction(
  EnvironmentsTypes.ChangeEnvironment,
  EnvironmentsTypes.ChangeEnvironmentSuccess,
  EnvironmentsTypes.ChangeEnvironmentFailed,
)<{ unitUuid: string; environmentUuid: string }, void, string>();

export const addEnvironmentAction = createAsyncAction(
  EnvironmentsTypes.AddEnvironment,
  EnvironmentsTypes.AddEnvironmentSuccess,
  EnvironmentsTypes.AddEnvironmentFailed,
)<
  {
    name: string;
    ledLevel: number;
    ledTime: number;
    nutritionLevel: number;
    vpaLevel: number;
  },
  { environment: Environment },
  string
>();

export const deleteEnvironmentAction = createAsyncAction(
  EnvironmentsTypes.DeleteEnvironment,
  EnvironmentsTypes.DeleteEnvironmentSuccess,
  EnvironmentsTypes.DeleteEnvironmentFailed,
)<{ environmentUuid: string }, { environmentUuid: string }, string>();

export type EnvironmentsActionUnion = ActionType<
  | typeof getEnvironmentsAction
  | typeof changeEnvironmentAction
  | typeof addEnvironmentAction
  | typeof deleteEnvironmentAction
>;
