import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const notificationsStoreSelector = (state: AppState) => state.notifications;

export const notificationsLoadingSelector = createSelector(
  notificationsStoreSelector,
  (state) => state.loading
);

export const notificationsDataSelector = createSelector(
  notificationsStoreSelector,
  (state) => state.data
);

export const notificationsErrorSelector = createSelector(
  notificationsStoreSelector,
  (state) => state.error
);
