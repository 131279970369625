import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  dateBetween,
  getDefaultTime,
  getLightOffTime,
} from '../../pages/Lighting/components/LightingPanel/data';
import { ProgressLine } from '../../pages/Lighting/components/LightingPanel/types';
import { selectedUnitSelector } from '../../store/selectors/units';
import { dateWithTimezone, toTime } from '../helpers/date-time';
import { getOffSunConfig, getSecondSunConfig, getSunConfig } from '../helpers/lines';

export function useLighting(): {
  ref: MutableRefObject<HTMLDivElement>;
  progressLines: ProgressLine[];
  step: number;
  currentTime: Date;
  activeButton: boolean;
  lightsOff: string | null;
} {
  const ref = useRef<HTMLDivElement>(null);
  const unit = useSelector(selectedUnitSelector);
  const [step, setStep] = useState(0);
  const [currentTime, setCurrentTime] = useState<Date>(null);
  const [progressLines, setProgressLines] = useState<ProgressLine[]>([]);
  const [lightsOff, setLightsOff] = useState<string>(null);
  const [activeButton, setActiveButton] = useState(false);

  const timezone = unit.settings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setLightsOff(null);
    setProgressLines([]);
    const containerWidth = ref.current?.offsetWidth;
    setStep((containerWidth - 20) / 24);

    const lighting = unit?.settings?.led.sort((a, b) => a.order - b.order);

    const lightOn = lighting.filter((item) => item.ledOff === undefined);
    const lightOff = lighting.filter((item) => item.ledOff !== undefined);

    if (lightOn.length > 0) {
      const startTime = lightOn[0]?.timestamp.split(':');
      const endTime = lightOn[1]?.timestamp.split(':');

      setProgressLines([getSunConfig(startTime, endTime, true)]);
    }

    if (lightOff.length > 0 && lightOn.length > 0) {
      const startTime = lightOn[0]?.timestamp.split(':');
      const endTime = lightOn[1]?.timestamp.split(':');

      const startLightOffTime = lightOff[0]?.timestamp.split(':');
      const endLightOffTime = lightOff[1]?.timestamp.split(':');

      const startLightsDate = dateWithTimezone(timezone);
      startLightsDate.setHours(Number(startTime[0]));
      startLightsDate.setMinutes(Number(startTime[1]));

      const endLightsDate = dateWithTimezone(timezone);
      endLightsDate.setHours(Number(endTime[0]));
      endLightsDate.setMinutes(Number(endTime[1]));

      const startLightsOffDate = dateWithTimezone(timezone);
      startLightsOffDate.setHours(Number(startLightOffTime[0]));
      startLightsOffDate.setMinutes(Number(startLightOffTime[1]));

      const endLightsOffDate = dateWithTimezone(timezone);
      endLightsOffDate.setHours(Number(endLightOffTime[0]));
      endLightsOffDate.setMinutes(Number(endLightOffTime[1]));

      const lines = [];

      if (startLightsDate < endLightsDate) {
        lines.push(
          getSecondSunConfig(startTime, startLightOffTime),
          getOffSunConfig(startLightOffTime, endLightOffTime),
        );

        if (endLightsDate > endLightsOffDate) {
          lines.push(getSunConfig(endLightOffTime, endTime));
        }
      } else {

        lines.push(getOffSunConfig(startLightOffTime, endLightOffTime));

        if (startLightsDate < startLightsOffDate) {
          lines.push(
            getSecondSunConfig(['0', '0'], endTime),
            getSecondSunConfig(startTime, startLightOffTime),
            getSunConfig(endLightOffTime, ['23', '59']),
          );
        } else {
          if (endLightsOffDate < startLightsDate) {
            lines.push(
              getSecondSunConfig(['0', '0'], startLightOffTime),
              getSunConfig(startTime, ['23', '59']),
            );
          } else {
            lines.push(
              getSecondSunConfig(['0', '0'], endTime),
              getSunConfig(startTime, ['23', '59']),
            );
          }
          
          if (endLightsDate > endLightsOffDate) {
            lines.push(getSunConfig(endLightOffTime, endTime));
          }
        }
        
      }

      setProgressLines((value) => [...value, ...lines]);
    } else if (lightOn.length > 0) {
      const startTime = lightOn[0]?.timestamp.split(':');
      const endTime = lightOn[1]?.timestamp.split(':');

      const lines = [];
      if (startTime > endTime && Number(endTime[0]) === 0 && Number(endTime[1]) === 0) {
        lines.push(getSunConfig(startTime, ['23', '59']));
      } else if (startTime > endTime) {
        lines.push(getSunConfig(['0', '0'], endTime), getSunConfig(startTime, ['23', '59']));
      } else {
        lines.push(getSunConfig(startTime, endTime));
      }

      setProgressLines((value) => [...value, ...lines]);
    }
  }, [unit]);

  useEffect(() => {
    const lighting = unit?.settings?.led
      .sort((a, b) => a.order - b.order)
      .map((led) => {
        if (led?.timestamp === '00:00') {
          return { ...led, timestamp: '23:59' };
        }

        return led;
      });
    const lightOff = lighting.filter((item) => item.ledOff !== undefined);

    if (lightOff.length > 0) {
      const endLightOffTime = lightOff[1].timestamp.split(':');

      const endLightOffDate = new Date();
      endLightOffDate.setHours(Number(endLightOffTime[0]));
      endLightOffDate.setMinutes(Number(endLightOffTime[1]));

      const lightsOffOffset = endLightOffDate.getTime() - currentTime?.getTime();

      if (lightsOffOffset > 0 && lightsOffOffset < 2 * 3600 * 1000) {
        const offsetHours = Math.floor(lightsOffOffset / (3600 * 1000));
        const offsetMinutes = Math.floor((lightsOffOffset / (60 * 1000)) % 60);

        setLightsOff(`${toTime(offsetHours)}:${toTime(offsetMinutes)}h`);
      } else {
        setLightsOff(null);
      }
    }

    setActiveButton(
      dateBetween(getDefaultTime(progressLines), currentTime) &&
        !dateBetween(getLightOffTime(progressLines), currentTime),
    );
  }, [currentTime]);

  useEffect(() => {
    setCurrentTime(dateWithTimezone(timezone));
    const interval = setInterval(() => {
      setCurrentTime(dateWithTimezone(timezone));
    }, 1000);

    return () => clearInterval(interval);
  }, [unit]);

  return {
    ref,
    progressLines,
    step,
    currentTime,
    activeButton,
    lightsOff,
  };
}
