import { AccordionDetails, AccordionSummary } from '@mui/material';
import { PlantIcon, ArrowIcon } from '@plant/ui';
import { useSelector } from 'react-redux';
import { plantActionsDataSelector } from '../../../../../../store/selectors/plantActions';
import { authTokenSelector } from '../../../../../../store/selectors/auth';
import { imageWithToken } from '../../../../../helpers/image-with-token.helper';
import ListItem from '../../../../ListItem';
import * as Styled from './style';

interface AccordionProps {
  title: string;
  expanded: boolean;
  handleChange: (_, isExpanded: boolean) => void;
  onClick: (actionUuid: string) => () => void;
}

const Accordion: React.FC<AccordionProps> = ({ title, expanded, handleChange, onClick }) => {
  const actions = useSelector(plantActionsDataSelector);
  const token = useSelector(authTokenSelector);

  return (
    <Styled.StyledAccordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={
          <Styled.ArrowWrapper>
            <ArrowIcon width={12} height={4} />
          </Styled.ArrowWrapper>
        }
      >
        <Styled.AccordionHeaderContainer>
          <Styled.SecondaryMainIcon>
            <PlantIcon width={14} height={13} />
          </Styled.SecondaryMainIcon>
          <Styled.AccordionTitle>{title}</Styled.AccordionTitle>
        </Styled.AccordionHeaderContainer>
      </AccordionSummary>
      <AccordionDetails>
        <Styled.ActionsContainer>
          {actions &&
            actions.map((action) => {
              return (
                <ListItem
                  key={action.uuid}
                  leftIcon={<img src={imageWithToken(action.image, token)} />}
                  text={action.name.replace('Replant', 'Plant')}
                  textAlign="left"
                  onClick={onClick(action.uuid)}
                />
              );
            })}
        </Styled.ActionsContainer>
      </AccordionDetails>
    </Styled.StyledAccordion>
  );
};

export default Accordion;
