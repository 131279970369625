import React, { useEffect, useRef } from 'react';
import MoonImg from '../../../../../../assets/images/lighting/moon.png';
import SunImg from '../../../../../../assets/images/lighting/sun.png';
import { CANVAS_PADDING } from '../../data';

const Canvas = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const drawLines = (ctx: CanvasRenderingContext2D) => {
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const step = (width - CANVAS_PADDING * 2) / 12;

    ctx.strokeStyle = '#ffffff4b';
    ctx.lineWidth = 1;

    Array.from({ length: 13 }).map((_, index) => {
      ctx.moveTo(index * step + CANVAS_PADDING, 20);
      ctx.lineTo(index * step + CANVAS_PADDING, height - 20);
    });
    ctx.stroke();
  };

  const drawShort = (ctx: CanvasRenderingContext2D) => {
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const step = (width - 20) / 12;

    ctx.strokeStyle = 'rgba(167, 178, 174, 0.3)';
    ctx.lineWidth = 1;

    Array.from({ length: 12 }).map((_, index) => {
      ctx.moveTo(step / 2 + index * step + CANVAS_PADDING, height - 36);
      ctx.lineTo(step / 2 + index * step + CANVAS_PADDING, height - 20);
    });
    ctx.stroke();
  };

  const drawDots = (ctx: CanvasRenderingContext2D) => {
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const step = (width - 20) / 120;

    ctx.strokeStyle = 'rgba(167, 178, 174, 0.3)';
    ctx.lineWidth = 1;

    Array.from({ length: 120 }).map((_, index) => {
      ctx.moveTo(index * step + CANVAS_PADDING, height - 22);
      ctx.lineTo(index * step + CANVAS_PADDING, height - 20);
    });
    ctx.stroke();
  };

  const drawNumbers = (ctx: CanvasRenderingContext2D) => {
    const height = ctx.canvas.height;
    const width = ctx.canvas.width;
    const step = (width - CANVAS_PADDING * 2) / 12;

    ctx.font = '13px Manrope';

    Array.from({ length: 13 }).map((_, index) => {
      ctx.fillStyle = index % 3 === 0 ? '#2A5C44' : 'rgba(42, 92, 68, 0.4)';
      ctx.fillText(String(index * 2), index * step + 5, height);
    });
    ctx.fill();
  };

  const drawImages = (ctx: CanvasRenderingContext2D) => {
    const width = ctx.canvas.width;
    const step = (width - CANVAS_PADDING * 2) / 2;

    Array.from({ length: 3 }).map((_, index) => {
      const img = new Image();
      img.src = index === 1 ? SunImg : MoonImg;

      if (img.complete) {
        ctx.drawImage(img, index * step + 3, 0);
      } else {
        img.onload = () => {
          ctx.drawImage(img, index * step + 3, 0);
        };
      }
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    drawLines(context);
    drawShort(context);
    drawDots(context);
    drawNumbers(context);
    drawImages(context);
  }, []);

  return <canvas width={750} height={375} ref={canvasRef} {...props} />;
};

export default React.memo(Canvas);
