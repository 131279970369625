import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styles';
import Loader from '../../core/components/Loader';
import {
  contentFaqSelector,
  contentLoadingSelector,
} from '../../store/selectors/faq';
import { getFAQAction } from '../../store/actions/content';
import Accordion from './components/Accordion';

const Faq = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(contentLoadingSelector);
  const data = useSelector(contentFaqSelector);
  const [selectedItem, setSelectedItem] = useState<string>(null);

  useEffect(() => {
    if (!data) dispatch(getFAQAction.request({ page: 1, count: 25 }));
  }, []);

  const handleSelect = (uuid: string) => (_, isExpanded: boolean) => {
    setSelectedItem(!isExpanded ? null : uuid);
  };

  return (
    <Styled.Container>
      <Styled.Title>{t('faq.title')}</Styled.Title>
      <Styled.Hr />
      {loading ? (
        <Loader />
      ) : (
        <>
          {data?.map(({ subject, content, uuid }) => {
            return (
              <Accordion
                expanded={selectedItem === uuid}
                handleChange={handleSelect(uuid)}
                title={subject}
                body={content}
              />
            );
          })}
        </>
      )}
    </Styled.Container>
  );
};

export default React.memo(Faq);
