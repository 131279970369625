import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Action } from '@plant/data';

export enum PlantActionTypes {
  RequestActions = '[ACTION] RequestActions',
  RequestActionsSuccess = '[ACTION] RequestActionsSuccess',
  RequestActionsFailed = '[ACTION] RequestActionsFailed',
}

export const getPlantActionsAction = createAsyncAction(
  PlantActionTypes.RequestActions,
  PlantActionTypes.RequestActionsSuccess,
  PlantActionTypes.RequestActionsFailed
)<void, { actions: Action[] }, string>();

export type ActionsActionUnion = ActionType<typeof getPlantActionsAction>;
