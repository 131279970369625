import {
  ModalsActionUnion,
  removeModalAction,
  showModalAction,
} from '../actions/modals';
import { createReducer } from 'typesafe-actions';

export interface State {
  modals: string[];
}

export const initialState: State = {
  modals: [],
};

export const reducer = createReducer<State, ModalsActionUnion>(initialState)
  .handleAction(showModalAction, (state, action) => ({
    ...state,
    modals: [...state.modals, action.payload.id],
  }))
  .handleAction(removeModalAction, (state, action) => ({
    ...state,
    modals: state.modals.filter((itemId) => itemId !== action.payload.id),
  }));
