import { styled } from '@plant/ui';
import Video from '../../core/components/Video';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  backgroundColor: theme.palette.admin.main,
  flexDirection: 'column',
  gap: 50,
  alignItems: 'center',
}));

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 830,
  width: '100%',
});

export const Title = styled('h1')(({ theme }) => ({
  fontSize: 42,
  lineHeight: '42px',
  fontWeight: 400,
  color: theme.palette.green.dark,
  margin: 0,
}));

export const ContentContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 60,
});

export const HeaderSection = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 50,
});

export const Description = styled('p')(({ theme }) => ({
  fontSize: 17,
  lineHeight: '25px',
  fontWeight: 400,
  color: theme.palette.green.dark,
  margin: 0,
  marginBottom: 30,
}));

export const CustomVideo = styled(Video)({
  marginBottom: 15,
});

export const SectionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 45,
  paddingBottom: 30,
  marginBottom: 15,
  borderRadius: 15,
  backgroundColor: theme.palette.light.main,
}));

export const SectionTitle = styled('h6')(({ theme }) => ({
  fontSize: 20,
  lineHeight: '20px',
  fontWeight: 400,
  color: theme.palette.secondary.main,
  margin: 0,
  marginBottom: 35,
}));

export const ButtonsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  gap: 15,
  justifyContent: 'center',
  flexWrap: 'wrap',

  marginBottom: 30,
});

export const SectionTextWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

export const SectionText = styled('p')(({ theme }) => ({
  maxWidth: 550,
  width: '100%',
  margin: 0,
  textAlign: 'center',

  opacity: 0.8,
  color: theme.palette.secondary.main,
  fontWeight: 400,
  fontSize: 17,
}));

export const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected = false }) => ({
  padding: '10px 35px',
  borderRadius: 8,
  backgroundColor: isSelected ? theme.palette.green.main : theme.palette.admin.main,
  border: 'none',
  outline: 'none',
  boxShadow: isSelected ? '0px 16px 19px -10px rgba(60, 151, 107, 0.28)' : 'none',

  fontWeight: isSelected ? 600 : 500,
  fontSize: 13,
  lineHeight: '20px',
  color: theme.palette.secondary.main,
}));

export const LoadingContainer = styled('div')({
  width: '100%',
  marginTop: 50,

  display: 'flex',
  justifyContent: 'center',
});

export const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
});

export const PodsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

export const PodsLabel = styled('span')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 11,
  lineHeight: '11px',
  color: theme.palette.secondary.main,
}));

export const PodsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
});

export const Pod = styled('span')(({ theme }) => ({
  padding: '5px 12px',
  borderRadius: 40,
  backgroundColor: theme.palette.light.light,
  color: theme.palette.light.main,
  fontSize: 11,
  lineHeight: '11px',
  fontWeight: 700,
  textTransform: 'uppercase',
}));
