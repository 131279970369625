import { call, Effect, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { NotificationsService, UserService } from '@plant/data';
import { getNotificationsAction, updateNotificationsAction } from '../actions/notifications';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { t } from 'i18next';

export class NotificationSagaWorker {
  static *getNotifications() {
    try {
      const { pushNotifications, emailNotifications }: SagaReturnType<typeof UserService.getUser> =
        yield call(UserService.getUser);

      yield put(getNotificationsAction.success({ pushNotifications, emailNotifications }));
    } catch (error) {
      yield put(getNotificationsAction.failure(error?.response?.data?.error));
    }
  }

  static *updateNotifications({ payload }: ActionType<typeof updateNotificationsAction.request>) {
    try {
      const data: SagaReturnType<typeof NotificationsService.updateNotifications> = yield call(
        NotificationsService.updateNotifications,
        payload,
      );

      yield put(updateNotificationsAction.success(data));
      toast.success(`${t('personal.messages.notifications')}`);
    } catch (error) {
      yield put(updateNotificationsAction.failure(error?.response?.data?.error));
    }
  }
}

export function* notificationsSaga(): Generator<Effect, void> {
  yield takeLatest(getNotificationsAction.request, NotificationSagaWorker.getNotifications);
  yield takeLatest(updateNotificationsAction.request, NotificationSagaWorker.updateNotifications);
}
