import { styled } from '@plant/ui';
import { NavLink } from 'react-router-dom';

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  height: '100%',
  width: '100%',
});

export const LeftSideContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  backgroundColor: theme.palette.light.main,
  padding: '120px 22px',

  display: 'flex',
  justifyContent: 'end',
}));

export const RightSideContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.admin.main,
  padding: '120px 50px',

  display: 'flex',
  justifyContent: 'start',
}));

export const NavigationWrapper = styled('div')({
  width: 220,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

export const Title = styled('h3')(({ theme }) => ({
  margin: 0,
  marginBottom: 70,
  fontSize: 35,
  lineHeight: '35px',
  fontWeight: 400,
  color: theme.palette.green.dark,
}));

export const NavigationContiner = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export const NavigationItem = styled(NavLink)(({ theme }) => ({
  width: '100%',
  height: 45,
  borderRadius: 10,
  padding: '10px 20px',
  background: 'transparent',
  cursor: 'pointer',
  textDecoration: 'none',

  display: 'flex',
  alignItems: 'center',
  gap: 10,

  transition: 'all .1s linear',

  '&:hover': {
    background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',
    boxShadow: '0px 16px 19px -10px rgba(60, 151, 107, 0.28)',
  },

  '& svg': {
    fill: theme.palette.secondary.main,
  },

  '&[aria-current]': {
    background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',
    boxShadow: '0px 16px 19px -10px rgba(60, 151, 107, 0.28)',
  },
}));

export const NavigationItemIcon = styled('div')(({ theme }) => ({
  '& svg': {
    fill: theme.palette.secondary.main,
  },
}));

export const NavigationItemLabel = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '16px',
}));
