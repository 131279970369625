import { styled } from '@plant/ui';

export const PopupItem = styled('button')(({ theme }) => ({
  borderRadius: 12,
  padding: '11px 15px',
  backgroundColor: '#F0F5F4',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: 'all .1s linear',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& svg': {
    fill: theme.palette.secondary.main,
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,

    '& img': {
      filter:
        'invert(100%) sepia(94%) saturate(3%) hue-rotate(27deg) brightness(111%) contrast(100%)',
    },
    '& svg': {
      fill: theme.palette.light.main,
    },
    '& p': {
      color: theme.palette.light.main,
    },
  },
}));

export const PopupItemText = styled('p')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '21px',
  textAlign: 'center',
  color: theme.palette.secondary.main,
  margin: 0,
  width: '100%',
}));
