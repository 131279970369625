import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppNavigator, PrivateRoute } from '@plant/data';
import Dashboard from '../../../pages/Dashboard';
import SignIn from '../../../pages/SignIn';
import { AppRoutes } from '../../constants/appRoutes';
import { withAuthGuard } from '../../guards/withAuthGuard';
import { Navigator } from '../../services/navigator';
import SignUp from '../../../pages/SignUp';
import ResetPassword from '../../../pages/ResetPassword';
import NewPassword from '../../../pages/NewPassword';
import Social from '../../../pages/Social';
import Settings from '../../../pages/Settings';
import SidebarLayout from '../SidebarLayout';
import Lighting from '../../../pages/Lighting';
import Personal from '../../../pages/Personal';
import Notifications from '../../../pages/Notifications';
import UnitManagment from '../../../pages/UnitManagment';
import Faq from '../../../pages/Faq';
import About from '../../../pages/About';
import CropGuide from '../../../pages/CropGuide';

const AppRouter: FC = () => {
  return (
    <AppNavigator navigator={Navigator.history}>
      <Routes>
        <Route path={AppRoutes.SignIn} element={<SignIn />} />
        <Route path={AppRoutes.SignUp} element={<SignUp />} />
        <Route path={AppRoutes.ResetPassword} element={<ResetPassword />} />
        <Route path={AppRoutes.NewPassword} element={<NewPassword />} />
        <Route path={AppRoutes.Social} element={<Social />} />
        <Route element={<SidebarLayout />}>
          <Route path={AppRoutes.DashBoard} element={<PrivateRoute guards={[withAuthGuard]} />}>
            <Route path={AppRoutes.DashBoard} element={<Dashboard />} />
          </Route>
          <Route path={AppRoutes.Lighting} element={<PrivateRoute guards={[withAuthGuard]} />}>
            <Route path={AppRoutes.Lighting} element={<Lighting />} />
          </Route>
          <Route path={AppRoutes.CropGuide} element={<PrivateRoute guards={[withAuthGuard]} />}>
            <Route path={AppRoutes.CropGuide} element={<CropGuide />} />
          </Route>
          <Route path={AppRoutes.Settings} element={<PrivateRoute guards={[withAuthGuard]} />}>
            <Route path={AppRoutes.Settings} element={<Settings />}>
              <Route index element={<Navigate to={AppRoutes.Personal} />} />
              <Route path={AppRoutes.Personal} element={<Personal />} />
              <Route path={AppRoutes.Password} element={<Personal />} />
              <Route path={AppRoutes.Notifications} element={<Notifications />} />
              <Route path={AppRoutes.About} element={<About />} />
              <Route path={AppRoutes.UnitManagment} element={<UnitManagment />} />
              <Route path={AppRoutes.FAQ} element={<Faq />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to={AppRoutes.DashBoard} replace />} />
      </Routes>
    </AppNavigator>
  );
};

export default AppRouter;
