import { createReducer } from 'typesafe-actions';
import { Environment } from '@plant/data';
import {
  addEnvironmentAction,
  changeEnvironmentAction,
  deleteEnvironmentAction,
  EnvironmentsActionUnion,
  getEnvironmentsAction,
} from '../actions/environments';

export interface State {
  environments: Environment[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  environments: [],
  loading: false,
  error: null,
};

export const reducer = createReducer<State, EnvironmentsActionUnion>(initialState)
  .handleAction(getEnvironmentsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getEnvironmentsAction.success, (state, action) => ({
    ...state,
    loading: false,
    environments: action.payload.environments,
  }))
  .handleAction(getEnvironmentsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(changeEnvironmentAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
  .handleAction(addEnvironmentAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addEnvironmentAction.success, (state, action) => ({
    ...state,
    loading: false,
    environments: [...state.environments, action.payload.environment],
  }))
  .handleAction(addEnvironmentAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(deleteEnvironmentAction.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(deleteEnvironmentAction.success, (state, action) => ({
    ...state,
    loading: false,
    environments: state.environments.filter(
      (environment) => environment.uuid !== action.payload.environmentUuid,
    ),
  }))
  .handleAction(deleteEnvironmentAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
