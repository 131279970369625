import * as yup from 'yup';
import { t as TFunction } from 'i18next';
import { getPasswordSchema } from './yupSchemas';

export const forgotPasswordConfirmationSchema = (t: typeof TFunction) =>
  yup.object().shape({
    newPassword: getPasswordSchema(t),
    confirmNewPassword: getPasswordSchema(t)
      .oneOf([yup.ref('newPassword')], t('errorMessages.passDontMatch'))
      .required(),
  });
