import * as yup from 'yup';
import { t as TFunction } from 'i18next';
import {
  MIN_USER_NAME_LENGTH,
  MAX_USER_NAME_LENGTH,
  latinRegExp,
} from './yupSchemas';

export const personalSchema = (t: typeof TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .min(
        MIN_USER_NAME_LENGTH,
        t('errorMessages.minFirstNameLength', { MIN_USER_NAME_LENGTH })
      )
      .max(
        MAX_USER_NAME_LENGTH,
        t('errorMessages.maxFirstNameLength', { MAX_USER_NAME_LENGTH })
      )
      .matches(latinRegExp, t('errorMessages.firstNameLatin'))
      .trim(t('errorMessages.trimName'))
      .required(t('errorMessages.nameIsRequired')),
    lastName: yup
      .string()
      .min(
        MIN_USER_NAME_LENGTH,
        t('errorMessages.minLastNameLength', { MIN_USER_NAME_LENGTH })
      )
      .max(
        MAX_USER_NAME_LENGTH,
        t('errorMessages.maxLastNameLength', { MAX_USER_NAME_LENGTH })
      )
      .matches(latinRegExp, t('errorMessages.lastNameLatin'))
      .trim(t('errorMessages.trimName'))
      .required(t('errorMessages.surnameIsRequired')),
    email: yup
      .string()
      .email(t('errorMessages.correctEmail'))
      .required(t('errorMessages.emailIsRequired')),
    addres: yup.string(),
    city: yup.string(),
    country: yup.string(),
  });
