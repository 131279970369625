import { CropService } from '@plant/data';
import {
  Effect,
  put,
  SagaReturnType,
  call,
  debounce,
} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { searchCropsAction } from '../actions/searchCrops';

export class SearchCropsSagaWorker {
  static *searchCrops({
    payload,
  }: ActionType<typeof searchCropsAction.request>) {
    try {
      const crops: SagaReturnType<typeof CropService.searchCrops> = yield call(
        CropService.searchCrops,
        payload.name
      );
      yield put(searchCropsAction.success({ crops }));
    } catch (error) {
      yield put(searchCropsAction.failure(error?.response?.data?.error));
    }
  }
}

export function* searchCropsSaga(): Generator<Effect, void> {
  yield debounce(
    500,
    searchCropsAction.request,
    SearchCropsSagaWorker.searchCrops
  );
}
