export function dateWithTimezone(timeZone: string) {
  return new Date(
    new Date().toLocaleString('en-US', {
      timeZone,
    })
  );
}

export function toTime(time: number) {
  return `0${time}`.slice(-2);
}
