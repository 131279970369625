import { Modal } from '@mui/material';
import { styled, ScrollBarStyles } from '@plant/ui';
import Video from '../../Video';

export const StyledModal = styled(Modal)({
  zIndex: 69,
});

export const Container = styled('div')({
  backgroundColor: '#F0F5F4',
  padding: 40,
  width: 490,
  height: '100%',
  position: 'absolute',
  left: 105,
  top: 0,
  bottom: 0,
  boxShadow: '0px 14px 44px rgba(36, 47, 45, 0.42)',
  outline: 'none',
  overflowY: 'scroll',
  ...ScrollBarStyles,
});

export const ModalTitle = styled('h3')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '20px',
  color: theme.palette.secondary.main,

  margin: 0,
  marginBottom: 40,
}));

export const VideoWrapper = styled('div')({
  width: '100%',
  position: 'relative',
  marginBottom: 55,
});

export const VideoGuide = styled('video')({
  borderRadius: 8,
  width: '100%',
});

export const PlayButton = styled('div')({
  borderRadius: '50%',
  width: 70,
  height: 70,
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(9px)',
  top: 'calc(50% - 35px)',
  left: 'calc(50% - 35px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Triangle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPause',
})<{ isPause: boolean }>(({ isPause }) => ({
  width: 25,
  height: 25,
  borderStyle: isPause ? 'double' : 'solid',
  borderWidth: isPause ? '0 0 0 22px' : '15px 0px 15px 25px',
  borderColor: 'transparent transparent transparent #1F483E',

  transition: 'all 100ms ease-in-out',
}));

export const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0',
});

export const CustomVideo = styled(Video)({
  marginBottom: 55,
});
