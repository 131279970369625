import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HumidityStatIcon, TempStatIcon, WaterStatIcon } from '@plant/ui';
import {
  activeSelector,
  alertsSelector,
  selectedUnitSelector,
  selectedUnitIdSelector,
} from '../../../../store/selectors/units';
import ClearPod from './components/ClearPod';
import PodComponent from './components/Pod';
import * as Styled from './styles';
import { NotificationData, NUTRITION_ALERT_TYPE } from './data';
import { PODS_COUNT } from '../../../../core/constants/podsCount';
import { removeNutritionAlertAction } from '../../../../store/actions/units';

const UnitPanel: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedUnit = useSelector(selectedUnitSelector);
  const alerts = useSelector(alertsSelector);
  const active = useSelector(activeSelector);
  const selectedUnitUuid = useSelector(selectedUnitIdSelector);

  const alert = alerts && alerts.length > 0 && alerts[0];
  const pods = selectedUnit?.pods;
  const { Icon, Container } = NotificationData[alert?.type - 1 || 0];

  const closeNutritionAlert = () => {
    dispatch(removeNutritionAlertAction.request({ unitUuid: selectedUnitUuid }));
  };

  return (
    <Styled.Container>
      <Styled.Paper>
        {selectedUnit &&
          Array.from({ length: PODS_COUNT }).map((_, index) => {
            const pod = pods.find((pod) => pod.number === index + 1);
            if (pod) {
              return <PodComponent key={pod.plant.uuid} pod={pod} index={index + 1} />;
            } else {
              return <ClearPod key={index} index={index + 1} active={active} />;
            }
          })}
        {!selectedUnit &&
          Array.from({ length: PODS_COUNT }).map((_, index) => (
            <ClearPod key={index} index={index + 1} active={active} />
          ))}
      </Styled.Paper>
      <Styled.InfoContainer>
        {alert && (
          <Container>
            <Icon width={17} height={17} />
            {alert.description}
            {NUTRITION_ALERT_TYPE === alert.type && (
              <Styled.DoneButton onClick={closeNutritionAlert}>
                {t('notifications.done')}
              </Styled.DoneButton>
            )}
          </Container>
        )}
        <Styled.InfoBox>
          <WaterStatIcon width={14} height={17} />
          <Styled.Value>{`${selectedUnit?.status?.waterLevel || 0}%`}</Styled.Value>
          <Styled.Label>{t('unitPanel.water')}</Styled.Label>
        </Styled.InfoBox>
        <Styled.InfoBox>
          <TempStatIcon width={14} height={17} />
          <Styled.Value>{`${selectedUnit?.status?.temperature || 0}°C`}</Styled.Value>
          <Styled.Label>{t('unitPanel.temp')}</Styled.Label>
        </Styled.InfoBox>
        <Styled.InfoBox>
          <HumidityStatIcon width={14} height={17} />
          <Styled.Value>{`${selectedUnit?.status?.humidity || 0}%`}</Styled.Value>
          <Styled.Label>{t('unitPanel.humidity')}</Styled.Label>
        </Styled.InfoBox>
      </Styled.InfoContainer>
    </Styled.Container>
  );
};

export default React.memo(UnitPanel);
