import { EnvironmentService } from '@plant/data';
import { toast } from 'react-toastify';
import { takeLatest, Effect, put, SagaReturnType, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { t } from 'i18next';
import {
  addEnvironmentAction,
  changeEnvironmentAction,
  deleteEnvironmentAction,
  getEnvironmentsAction,
} from '../actions/environments';
import { changeUnitEnvironmentAction } from '../actions/units';

export class EnvironmentsSagaWorker {
  static *getEnvironments() {
    try {
      const environments: SagaReturnType<typeof EnvironmentService.getEnvironments> = yield call(
        EnvironmentService.getEnvironments,
      );
      yield put(getEnvironmentsAction.success({ environments }));
    } catch (error) {
      yield put(getEnvironmentsAction.failure(error?.response?.data?.error));
    }
  }

  static *changeEnvironment(action: ActionType<typeof changeEnvironmentAction.request>) {
    try {
      const environment: SagaReturnType<typeof EnvironmentService.changeEnvironment> = yield call(
        EnvironmentService.changeEnvironment,
        action.payload.unitUuid,
        action.payload.environmentUuid,
      );
      yield put(changeEnvironmentAction.success());
      yield put(changeUnitEnvironmentAction(environment));
    } catch (error) {
      yield put(changeEnvironmentAction.failure(error?.response?.data?.error));
    }
  }

  static *addEnvironment(action: ActionType<typeof addEnvironmentAction.request>) {
    try {
      const { name, ledLevel, ledTime, vpaLevel, nutritionLevel } = action.payload;
      const environment: SagaReturnType<typeof EnvironmentService.addEnvironment> = yield call(
        EnvironmentService.addEnvironment,
        name,
        ledLevel,
        ledTime,
        nutritionLevel,
        vpaLevel,
      );
      yield put(addEnvironmentAction.success({ environment }));
      toast(`${t('customEnvironmentModal.messages.creating')}`, { type: 'success' });
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' });
      yield put(addEnvironmentAction.failure(error?.response?.data?.error));
    }
  }
  static *deleteEnvironment(action: ActionType<typeof deleteEnvironmentAction.request>) {
    try {
      yield call(EnvironmentService.deleteEnvironment, action.payload.environmentUuid);
      yield put(
        deleteEnvironmentAction.success({ environmentUuid: action.payload.environmentUuid }),
      );
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' });
      yield put(deleteEnvironmentAction.failure(error?.response?.data?.error));
    }
  }
}

export function* environmentsSaga(): Generator<Effect, void> {
  yield takeLatest(getEnvironmentsAction.request, EnvironmentsSagaWorker.getEnvironments);
  yield takeLatest(changeEnvironmentAction.request, EnvironmentsSagaWorker.changeEnvironment);
  yield takeLatest(addEnvironmentAction.request, EnvironmentsSagaWorker.addEnvironment);
  yield takeLatest(deleteEnvironmentAction.request, EnvironmentsSagaWorker.deleteEnvironment);
}
