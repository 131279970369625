import { createReducer } from 'typesafe-actions';
import { About, IGetFaqType } from '@plant/data';
import { ContentUnion, getAboutAction, getFAQAction } from '../actions/content';

export interface State {
  faq: IGetFaqType[];
  about: About[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  faq: null,
  about: null,
  loading: false,
  error: null,
};

export const reducer = createReducer<State, ContentUnion>(initialState)
  .handleAction(getFAQAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getFAQAction.success, (state, { payload }) => ({
    ...state,
    faq: payload,
    loading: false,
    error: null,
  }))
  .handleAction(getFAQAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(getAboutAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getAboutAction.success, (state, { payload }) => ({
    ...state,
    about: payload,
    loading: false,
    error: null,
  }))
  .handleAction(getAboutAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
