import React from 'react';
import { FC } from 'react';
import { Navigator } from '../../../../core/services/navigator';
import { Link } from './style';

interface IProps {
  route: string;
  title: string;
}

const InternalLink: FC<IProps> = ({ route, title }) => {
  const onClickHandler = () => {
    Navigator.push(route);
  };
  return <Link onClick={onClickHandler}>{title}</Link>;
};

export default React.memo(InternalLink);
