import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Crop } from '@plant/data';

export enum SearchCropsTypes {
  SearchCrops = '[SEARCH CROPS] SearchCrops',
  SearchCropsSuccess = '[SEARCH CROPS] SearchCropsSuccess',
  SearchCropsFailed = '[SEARCH CROPS] SearchCropsFailed',

  ClearResults = '[SEARCH CROPS] ClearResults',
}

export const searchCropsAction = createAsyncAction(
  SearchCropsTypes.SearchCrops,
  SearchCropsTypes.SearchCropsSuccess,
  SearchCropsTypes.SearchCropsFailed
)<{ name: string }, { crops: Crop[] }, string>();

export const clearCropsAction = createAction(
  SearchCropsTypes.ClearResults
)<void>();

export type SearchCropsActionUnion = ActionType<
  typeof searchCropsAction | typeof clearCropsAction
>;
