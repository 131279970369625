import React, { useState } from 'react';
import * as Styled from './styles';
import { BellIcon } from '@plant/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alertsSelector, selectedUnitIdSelector } from '../../../../store/selectors/units';
import { removeNutritionAlertAction } from '../../../../store/actions/units';
import { NotificationData, NUTRITION_ALERT_TYPE } from './data';

const AlertBox: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>();
  const alerts = useSelector(alertsSelector);
  const selectedUnitUuid = useSelector(selectedUnitIdSelector);

  const active = alerts && alerts.length > 1;

  const toggleIsOpen = () => {
    if (alerts && alerts.length > 1) {
      setIsOpen((value) => !value);
    } else if (isOpen) {
      setIsOpen((value) => !value);
    }
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const closeNutritionAlert = () => {
    dispatch(removeNutritionAlertAction.request({ unitUuid: selectedUnitUuid }))
  }

  if (!active) {
    return null;
  }

  return (
    <>
      <Styled.Circle onClick={toggleIsOpen}>
        <BellIcon width={24} height={24} />
        <Styled.SmallCircle>
          <Styled.AlertsCount>{alerts?.length}</Styled.AlertsCount>
        </Styled.SmallCircle>
        {isOpen && (
          <Styled.NotificationsPopup onClick={handleClick}>
            <Styled.NotificationsPopupTitle>
              {t('dashboard.systemNotifications')}
            </Styled.NotificationsPopupTitle>
            {alerts?.map((alert) => {
              const { Container, Icon } =
                NotificationData[alert.type - 1];
              return (
                <Container key={alert.uuid}>
                  <Icon width={14} height={17} />
                  {alert.description}
                  {NUTRITION_ALERT_TYPE === alert.type && <Styled.DoneButton onClick={closeNutritionAlert}>{t('notifications.done')}</Styled.DoneButton>}
                </Container>
              );
            })}
          </Styled.NotificationsPopup>
        )}
      </Styled.Circle>
      {isOpen && <Styled.Paper onClick={toggleIsOpen} />}
    </>
  );
};

export default React.memo(AlertBox);
