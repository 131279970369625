import { Slide } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { ArrowIcon, Button, SpeedIcon } from '@plant/ui';
import * as Styled from './styles';
import { ModalContainer } from '../../../constants/modalContainer';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { ModalOptions } from '../../../services/modals';
import { selectedUnitIdSelector } from '../../../../store/selectors/units';
import { authTokenSelector } from '../../../../store/selectors/auth';
import { ModalSteps, speedMarks } from './data';
import { saveTimelapseAction, shareFileAction } from '../../../../store/actions/timelapse';
import {
  timelapseLoadingSelector,
  timelapseVideoSelector,
} from '../../../../store/selectors/timelapse';
import Loader from '../../Loader';
import SocialLink from '../../SocialLink';
import { useTimelapse } from '../../../hooks/useTimelapse';
import CircleClose from '../../CircleClose';
import { SocialTypes } from '../../../constants/socialTypes';

const TimelapseModal: React.FC<ModalOptions> = ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedUnitId = useSelector(selectedUnitIdSelector);
  const {
    containerRef,
    images,
    speed,
    mainImageIndex,
    imageLength,
    leftThumbPosition,
    rightThumbPosition,
    stepLenght,
    renderImages,
    handleChangeSpeed,
    handleDownLeftThumb,
    handleDownRightThumb,
  } = useTimelapse();
  const token = useSelector(authTokenSelector);
  const loading = useSelector(timelapseLoadingSelector);
  const video = useSelector(timelapseVideoSelector);
  const [step, setStep] = useState<ModalSteps>(ModalSteps.firstStep);

  const handleCreate = () => {
    setStep(ModalSteps.secondStep);
    dispatch(
      saveTimelapseAction.request({
        unitUuid: selectedUnitId,
        speed,
        startDate: images[leftThumbPosition].createdOn,
        endDate: images[rightThumbPosition - 1].createdOn,
      }),
    );
  };

  const handleSaveFile = () => {
    saveAs(`${video}?token=${token}`, 'timelapse.mp4');
  };

  const handleFacebookShare = () => {
    dispatch(shareFileAction.request({ url: video, type: SocialTypes.Facebook }));
  };

  const handleGmailShare = async () => {
    await dispatch(shareFileAction.request({ url: video, type: SocialTypes.Google }));
  };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="down">
        <Styled.Container>
          <Styled.CloseContainer>
            <CircleClose onClick={close} />
          </Styled.CloseContainer>
          <Styled.InnerContainer>
            <Styled.ModalTitle>{t('timelapseModal.title')}</Styled.ModalTitle>
            {loading && (
              <Styled.LoadingContainer>
                <Loader />
                <Styled.LoadingText>{t('timelapseModal.loadingText')}</Styled.LoadingText>
              </Styled.LoadingContainer>
            )}
            {!loading && step === ModalSteps.firstStep && (
              <>
                <Styled.ImageWrapper>
                  <Styled.MainImage
                    src={images.length > 0 && `${images[mainImageIndex]?.url}?token=${token}`}
                  />
                </Styled.ImageWrapper>
                <Styled.CropContainer ref={containerRef}>
                  <Styled.DateToolip
                    position={((rightThumbPosition + leftThumbPosition) * stepLenght - 100) / 2}
                  >
                    {`${t('timelapseModal.day')} ${leftThumbPosition + 1} - ${t(
                      'timelapseModal.day',
                    )} ${rightThumbPosition}`}
                  </Styled.DateToolip>

                  <Styled.Backdrop
                    leftPosition={leftThumbPosition * stepLenght}
                    rightPosition={rightThumbPosition * stepLenght + 15}
                  />
                  <Styled.CropInnerContainer>
                    {renderImages.map((index) => (
                      <Styled.CropImageContainer key={images[index].fileName} width={imageLength}>
                        <Styled.CropImage src={`${images[index]?.url}?token=${token}`} />
                      </Styled.CropImageContainer>
                    ))}
                  </Styled.CropInnerContainer>
                  <>
                    <Styled.LeftBlur position={leftThumbPosition * stepLenght} />
                    <Styled.RightBlur position={rightThumbPosition * stepLenght} />
                    <Styled.LeftThumb
                      onMouseDown={handleDownLeftThumb}
                      position={leftThumbPosition * stepLenght}
                    >
                      <ArrowIcon width={10} height={5} />
                    </Styled.LeftThumb>
                    <Styled.RightThumb
                      onMouseDown={handleDownRightThumb}
                      position={rightThumbPosition * stepLenght + 15}
                    >
                      <ArrowIcon width={10} height={5} />
                    </Styled.RightThumb>
                  </>
                </Styled.CropContainer>
                <Styled.CustomSlider
                  icon={<SpeedIcon width={15} height={17} />}
                  value={speed}
                  onChange={handleChangeSpeed}
                  marks={speedMarks}
                  hintPlacement="left"
                  title={'Playback speed'}
                  helperText={t('timelapseModal.helperText')}
                />
                <Styled.ButtonContainer>
                  <Button
                    disabled={loading}
                    variant="contained"
                    title={t('timelapseModal.create')}
                    onClick={handleCreate}
                    maxWidth={250}
                  />
                </Styled.ButtonContainer>
              </>
            )}
            {!loading && step === ModalSteps.secondStep && (
              <>
                <Styled.Video autoPlay loop>
                  <source src={`${video}?token=${token}`} />
                </Styled.Video>
                <Styled.SaveContainer>
                  <Styled.SocialContainer>
                    <Styled.ShareText>{t('timelapseModal.shareText')}</Styled.ShareText>
                    <SocialLink onClick={handleGmailShare} variant="google" />
                    <SocialLink onClick={handleFacebookShare} variant="facebook" />
                  </Styled.SocialContainer>
                  <Button
                    disabled={loading}
                    variant="contained"
                    title={t('timelapseModal.save')}
                    onClick={handleSaveFile}
                    maxWidth={150}
                  />
                </Styled.SaveContainer>
              </>
            )}
          </Styled.InnerContainer>
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const useTimelapseModal = createModalHook<ModalOptions>(
  (props) => () => <TimelapseModal {...props} />,
  ModalContainer.Root,
);

export default useTimelapseModal;
