import { ArrowIcon, RemoveIcon } from '@plant/ui';
import { MouseEventHandler, useState } from 'react';
import ListItem from '../../../../../../core/components/ListItem';
import { DEFAULT_ENVIRONMENT_UUID } from '../../../../../../core/constants/defaultEnvironmentUuid';
import * as Styled from './styles';
import { TitledSelectProps } from './types';

const TitledSelect: React.FC<TitledSelectProps> = ({
  title,
  data,
  selectedItemId,
  selectHandler,
  deleteHandler,
  active = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => {
    if (active) setIsOpen((value) => !value);
  };

  const clickHandler = (uuid: string) => () => {
    toggleIsOpen();
    selectHandler(uuid);
  };

  const handleDelete = (uuid: string) => (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    toggleIsOpen();
    deleteHandler(uuid);
  };

  return (
    <Styled.Wrapper>
      <Styled.CustomTitledField
        isOpen={isOpen}
        isActive={active}
        title={title}
        onClick={toggleIsOpen}
        rightIcon={<ArrowIcon width={10} height={10} />}
      >
        <Styled.SelectedItem>
          {selectedItemId
            ? data.find((item) => item.uuid === selectedItemId)?.name
            : data.find((item) => item.uuid === DEFAULT_ENVIRONMENT_UUID)?.name}
          {}
        </Styled.SelectedItem>
      </Styled.CustomTitledField>
      {isOpen && (
        <Styled.Popup>
          <Styled.Hr />
          <Styled.PopupContainer>
            {data
              .filter((item) => item.uuid !== selectedItemId)
              .map((item) => (
                <ListItem
                  key={item.uuid}
                  text={item.name}
                  onClick={clickHandler(item.uuid)}
                  rightIcon={
                    !item.standard ? (
                      <span onClick={handleDelete(item.uuid)}>
                        <RemoveIcon width={19} height={19} />
                      </span>
                    ) : null
                  }
                  textAlign="left"
                />
              ))}
          </Styled.PopupContainer>
        </Styled.Popup>
      )}
    </Styled.Wrapper>
  );
};

export default TitledSelect;
