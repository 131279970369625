import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const environmentsStoreSelector = (state: AppState) => state.environments;

export const environmentsLoadingSelector = createSelector(
  environmentsStoreSelector,
  (state) => state.loading
);
export const environmentsDataSelector = createSelector(
  environmentsStoreSelector,
  (state) => state.environments
);
