import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Button, Input } from '@plant/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Styled from './styles';
import { forgotPasswordSchema } from '../../core/yupShemas/forgotPassword';
import AuthLayout from '../../core/components/AuthLayout';
import Loader from '../../core/components/Loader';
import {
  authErrorSelector,
  authLoadingSelector,
} from '../../store/selectors/auth';
import ErrorAlert from '../../core/components/ErrorAlert';
import {
  clearAuthErrorAction,
  restorePasswordAction,
} from '../../store/actions/auth';

interface ResetPasswordFormValues {
  email: string;
}

const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(authLoadingSelector);
  const error = useSelector(authErrorSelector);
  const [currentField, setCurrentField] = useState<string>('');

  const memoizedForgotPasswordShema = useMemo(
    () => forgotPasswordSchema(t),
    [t]
  );

  const { register, handleSubmit, formState } =
    useForm<ResetPasswordFormValues>({
      resolver: yupResolver(memoizedForgotPasswordShema),
    });

  const updateCurrentField = (fieldName: string) => () => {
    setCurrentField(fieldName);
  };

  const handleReset = ({ email }: ResetPasswordFormValues) => {
    dispatch(clearAuthErrorAction());
    dispatch(restorePasswordAction.request({ email }));
  };

  const errorMessage = formState.errors[currentField]?.message || error;

  return (
    <AuthLayout>
      {formState.isSubmitSuccessful && !loading && !errorMessage ? (
        <Styled.FormLabel>
          {t('auth.resetPassword.submitMessage')}
        </Styled.FormLabel>
      ) : (
        <Styled.Form>
          <Styled.FormLabel>{t('auth.resetPassword.title')}</Styled.FormLabel>
          <Input
            title={t('auth.emailPlaceholder')}
            isInvalid={!!formState.errors.email?.message}
            handleFocus={updateCurrentField('email')}
            {...register('email')}
          />
          {errorMessage && <ErrorAlert variant="error" text={errorMessage} />}
          {!loading ? (
            <Styled.ButtonWrapper>
              <Button
                title={t('auth.resetPassword.submitButton')}
                variant="contained"
                maxWidth={250}
                onClick={handleSubmit(handleReset)}
              />
            </Styled.ButtonWrapper>
          ) : (
            <Styled.LoaderWrapper>
              <Loader />
            </Styled.LoaderWrapper>
          )}
        </Styled.Form>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
