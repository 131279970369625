import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '@plant/ui';
import { loginSchema } from '../../core/yupShemas/login';
import AuthLayout from '../../core/components/AuthLayout';
import { clearAuthErrorAction, clearAuthStateAction, loginAction } from '../../store/actions/auth';
import { authErrorSelector, authLoadingSelector } from '../../store/selectors/auth';
import { SignInFormValues } from './types';
import * as Styled from './styles';
import { useForm } from 'react-hook-form';
import { AppRoutes } from '../../core/constants/appRoutes';
import { Navigator } from '../../core/services/navigator';
import ErrorAlert from '../../core/components/ErrorAlert';
import Loader from '../../core/components/Loader';
import GoogleLink from '../../core/components/GoogleLink';
import FacebookLink from '../../core/components/FacebookLink';

const SignIn: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentField, setCurrentField] = useState<string>('');
  const error = useSelector(authErrorSelector);
  const loading = useSelector(authLoadingSelector);

  const memoizedLoginSchema = useMemo(() => loginSchema(t), [t]);

  const { register, handleSubmit, formState } = useForm<SignInFormValues>({
    resolver: yupResolver(memoizedLoginSchema),
  });

  const updateCurrentField = (fieldName: string) => () => {
    setCurrentField(fieldName);
  };

  const handleLogin = ({ email, password }: SignInFormValues) => {
    dispatch(clearAuthStateAction());
    dispatch(loginAction.request({ email, password }));
  };

  const redirectToSignUpPage = () => {
    dispatch(clearAuthErrorAction());
    Navigator.push(AppRoutes.SignUp);
  };
  const redirectToResetPasswordPage = () => {
    dispatch(clearAuthErrorAction());
    Navigator.push(AppRoutes.ResetPassword);
  };

  const errorMessage = formState.errors[currentField]?.message || error;

  return (
    <AuthLayout>
      <Styled.Container>
        <Styled.Form>
          <Styled.FormLabel>{t('auth.signIn.title')}</Styled.FormLabel>
          <Input
            title={t('auth.emailPlaceholder')}
            isInvalid={!!formState.errors.email?.message}
            handleFocus={updateCurrentField('email')}
            {...register('email')}
          />
          <Input
            type="password"
            title={t('auth.passwordPlaceholder')}
            isInvalid={!!formState.errors.password?.message}
            handleFocus={updateCurrentField('password')}
            {...register('password')}
          />
          <Button
            title={t('auth.signIn.resetPassword')}
            variant="link"
            type="button"
            onClick={redirectToResetPasswordPage}
          />
          {!loading ? (
            <>
              <Styled.ButtonWrapper>
                <Button
                  title={t('auth.signIn.title')}
                  variant="contained"
                  type="submit"
                  maxWidth={250}
                  onClick={handleSubmit(handleLogin)}
                />
              </Styled.ButtonWrapper>
              <Styled.TextCondition>{t('auth.orUse')}</Styled.TextCondition>
              <Styled.ButtonsContainer>
                <FacebookLink />
                <Styled.LineDiv />
                <GoogleLink />
              </Styled.ButtonsContainer>
              <Styled.SecondaryButtonWrapper>
                <Button
                  title={t('auth.signIn.switchButton')}
                  variant="link"
                  onClick={redirectToSignUpPage}
                />
              </Styled.SecondaryButtonWrapper>
              {errorMessage && <ErrorAlert variant="error" text={errorMessage} />}
            </>
          ) : (
            <Styled.LoaderWrapper>
              <Loader />
            </Styled.LoaderWrapper>
          )}
        </Styled.Form>
      </Styled.Container>
    </AuthLayout>
  );
};

export default SignIn;
