import { styled } from '@plant/ui';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  paddingTop: 40,
  paddingBottom: 80,
  paddingLeft: 20,
  paddingRight: 20,
  flexDirection: 'column',
  alignItems: 'center',
  background: 'linear-gradient(#B6D9C8 30%, #F0F5F4 30%)',
  position: 'relative',
}));

export const Container = styled('div')({
  maxWidth: 830,
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  gap: 35,
});

export const HeaderContainer = styled('div')({
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
});

export const BoxsContainer = styled('div')({
  display: 'flex',
  gap: 24,
});

export const LoaderContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const BottomContainer = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 20,
});
