import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Slide } from '@mui/material';
import { Input, LightIcon, NutritionIcon, VpaIcon, Button } from '@plant/ui';
import * as Styled from './styles';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { ModalOptions } from '../../../services/modals';
import { ModalContainer } from '../../../constants/modalContainer';
import Slider from '../../Slider';
import {
  defaultHumidityLevel,
  defaultLedPower,
  defaultLedTime,
  defaultNutritionLevel,
  humidityLevelMarks,
  ledPowerMarks,
  ledTimeMarks,
  MAX_ENVIRONMENT_NAME_LENGTH,
  MIN_ENVIRONMENT_NAME_LENGTH,
  nameRegex,
  nutritionLevelMarks,
} from './data';
import { addEnvironmentAction } from '../../../../store/actions/environments';
import ErrorAlert from '../../ErrorAlert';

const CustomEnvironmentModal: React.FC<ModalOptions> = ({ close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [ledTime, setLedTime] = useState(defaultLedTime);
  const [ledPower, setLedPower] = useState(defaultLedPower);
  const [nutritionLevel, setNutritionLevel] = useState(defaultNutritionLevel);
  const [humidityLevel, setHumidityLevel] = useState(defaultHumidityLevel);
  const [error, setError] = useState('');

  const handleChangeName = (event: React.SyntheticEvent<HTMLInputElement>) =>
    setName(event.currentTarget.value);
  const handleChangeLedTime = (_, newValue: number) => setLedTime(newValue);
  const handleChangeLedPower = (_, newValue: number) => setLedPower(newValue);
  const handleChangeNutritionLevel = (_, newValue: number) =>
    setNutritionLevel(newValue);
  const handleChangeHumidityLevel = (_, newValue: number) =>
    setHumidityLevel(newValue);

  const handleSubmit = () => {
    if (!(name.length >= MIN_ENVIRONMENT_NAME_LENGTH)) {
      setError(
        t('errorMessages.minEnvironmentLength', { MIN_ENVIRONMENT_NAME_LENGTH })
      );
      return;
    }
    if (!(name.length <= MAX_ENVIRONMENT_NAME_LENGTH)) {
      setError(
        t('errorMessages.maxEnvironmentLength', { MAX_ENVIRONMENT_NAME_LENGTH })
      );
      return;
    }
    if (!nameRegex.test(name)) {
      setError(t('errorMessages.invalidValues'));
      return;
    }
    setError('');
    close();
    dispatch(
      addEnvironmentAction.request({
        name,
        ledLevel: ledPower,
        ledTime,
        nutritionLevel,
        vpaLevel: humidityLevel,
      })
    );
  };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="right">
        <Styled.Container>
          <Styled.ModalTitle>
            {t('customEnvironmentModal.title')}
          </Styled.ModalTitle>
          <Styled.CustomVideo src="../../../../assets/videos/customEnvironmentGuide.mp4" />
          <Input
            value={name}
            onChange={handleChangeName}
            title={t('customEnvironmentModal.inputPlaceholder')}
          />
          <Slider
            title={t('customEnvironmentModal.ledTime')}
            icon={<LightIcon width={21} height={22} />}
            helperText={t('customEnvironmentModal.ledTimePrompt')}
            marks={ledTimeMarks}
            value={ledTime}
            onChange={handleChangeLedTime}
          />
          <Slider
            title={t('customEnvironmentModal.ledPower')}
            icon={<LightIcon width={21} height={22} />}
            helperText={t('customEnvironmentModal.ledPowerPrompt')}
            marks={ledPowerMarks}
            value={ledPower}
            onChange={handleChangeLedPower}
          />
          <Slider
            title={t('customEnvironmentModal.nutritionLevel')}
            icon={<NutritionIcon width={15} height={20} />}
            helperText={t('customEnvironmentModal.nutritionLevelPrompt')}
            marks={nutritionLevelMarks}
            value={nutritionLevel}
            onChange={handleChangeNutritionLevel}
          />
          <Slider
            title={t('customEnvironmentModal.humidityLevel')}
            icon={<VpaIcon width={15} height={20} />}
            helperText={t('customEnvironmentModal.humidityLevelPrompt')}
            marks={humidityLevelMarks}
            value={humidityLevel}
            onChange={handleChangeHumidityLevel}
          />
          <Styled.ButtonContainer>
            <Button
              variant="contained"
              maxWidth={200}
              title={t('customEnvironmentModal.save')}
              onClick={handleSubmit}
            />
          </Styled.ButtonContainer>
          {error && <ErrorAlert variant="error" text={error} />}
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const useCustomEnvironmentModal = createModalHook<ModalOptions>(
  (props) => () => <CustomEnvironmentModal {...props} />,
  ModalContainer.Dashboard
);

export default useCustomEnvironmentModal;
