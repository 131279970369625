import { ActionType, createAsyncAction } from 'typesafe-actions';

export enum StartAppTypes {
  StartApp = '[App] StartApp',
  StartAppSuccess = '[App] StartAppSuccess',
  StartAppFailed = '[App] StartAppFailed',
}

export const startAppAction = createAsyncAction(
  StartAppTypes.StartApp,
  StartAppTypes.StartAppSuccess,
  StartAppTypes.StartAppFailed
)<void, void, void>();

export type StartAppActionUnion = ActionType<typeof startAppAction>;
