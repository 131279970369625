import { createReducer } from 'typesafe-actions';
import * as AppActions from '../actions/app';

export interface State {
  appIsReady: boolean;
}

export const initialState: State = {
  appIsReady: false,
};

export const reducer = createReducer<State, AppActions.StartAppActionUnion>(initialState)
  .handleAction(AppActions.startAppAction.request, state => ({
    ...state,
    appIsReady: false,
  }))
  .handleAction(AppActions.startAppAction.success, (state) => ({
    ...state,
    appIsReady: true,
  }))
  .handleAction(AppActions.startAppAction.failure, (state) => ({
    ...state,
    appIsReady: true,
  }));
