import { FC, ReactNode } from 'react';
import { WrapperGuard } from '@plant/data';
import { useSelector } from 'react-redux';
import { authTokenSelector } from '../../store/selectors/auth';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../constants/appRoutes';

export const withAuthGuard: WrapperGuard = (element: ReactNode) => {
  const Guard: FC = () => {
    const authToken = useSelector(authTokenSelector);

    return authToken ? (
      <>{element}</>
    ) : (
      <Navigate to={AppRoutes.SignIn} replace />
    );
  };
  return <Guard />;
};
