import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@plant/ui';
import Loader from '../../core/components/Loader';
import PageHeaderSection from '../../core/components/PageHeaderSection';
import { searchCropsAction } from '../../store/actions/searchCrops';
import {
  searchCropsLoadingSelector,
  searchCropsResultsSelector,
} from '../../store/selectors/searchCrops';
import TitledSelect from './components/TitledSelect';
import * as Styled from './styles';
import { selectedUnitSelector } from '../../store/selectors/units';
import Nutrition from './components/Nutrition';
import Harvest from './components/Harvest';
import usePlantModal from '../../core/components/modals/AddPlant';
import { useSearchParams } from 'react-router-dom';
import { authTokenSelector } from '../../store/selectors/auth';

const CropGuide: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unit = useSelector(selectedUnitSelector);
  const crops = useSelector(searchCropsResultsSelector);
  const loading = useSelector(searchCropsLoadingSelector);
  const token = useSelector(authTokenSelector);
  const [searchParams, setParams] = useSearchParams();
  const cropId = searchParams.get('cropId');
  const [selectedCrop, setSelectedCrop] = useState<string>(!cropId ? crops[0]?.uuid : cropId);
  const [query, setQuery] = useState('');
  const [showModal] = usePlantModal();

  const alredyin = useMemo(
    () => unit?.pods.filter((pod) => pod.plant.crop.uuid === selectedCrop),
    [selectedCrop, unit],
  );

  const crop = useMemo(
    () => crops.find((item) => item.uuid === selectedCrop),
    [selectedCrop, crops],
  );

  useEffect(() => {
    dispatch(searchCropsAction.request({ name: query }));
  }, [query, dispatch]);

  useEffect(() => {
    if (!selectedCrop && crops.length > 0) setSelectedCrop(crops[0].uuid);
  }, [crops]);

  const handleSearch = (query: string) => {
    setQuery(query);
  };

  const handleSelect = (uuid: string) => {
    setSelectedCrop(uuid);
  };

  const handleAdd = () => {
    showModal({ cropId: crop.uuid });
  };

  return (
    <Styled.Wrapper>
      <PageHeaderSection />
      <Styled.Container>
        <TitledSelect
          title={t('cropGuide.selectTitle')}
          data={crops}
          query={query}
          handleSearch={handleSearch}
          selectedItemId={selectedCrop}
          selectHandler={handleSelect}
          active
        />
        {loading && !crop ? (
          <Styled.LoadingContainer>
            <Loader />
          </Styled.LoadingContainer>
        ) : (
          <Styled.ContentContainer>
            <Styled.HeaderSection>
              <Styled.TitleContainer>
                <Styled.Title>{crop?.name}</Styled.Title>
                {alredyin?.length > 0 && (
                  <Styled.PodsWrapper>
                    <Styled.PodsLabel>{t('cropGuide.alredyIn')}</Styled.PodsLabel>
                    <Styled.PodsContainer>
                      {alredyin.map((pod) => (
                        <Styled.Pod key={pod.plant.uuid}>
                          {t('cropGuide.pod', { pod: pod.number })}
                        </Styled.Pod>
                      ))}
                    </Styled.PodsContainer>
                  </Styled.PodsWrapper>
                )}
              </Styled.TitleContainer>
              <Button
                variant="contained"
                title={t('cropGuide.addButton', { name: unit?.name })}
                maxWidth={200}
                onClick={handleAdd}
              />
            </Styled.HeaderSection>
            <Styled.Description>{crop?.fullDescription}</Styled.Description>
            {crop?.video && crop?.video !== 'null' && (
              <Styled.CustomVideo src={`${crop?.video}?token=${token}`} />
            )}
            <Harvest harvest={crop?.harvest} lifespan={crop?.lifespan} replant={crop?.replant} />
            {crop?.nutrition.length > 0 && <Nutrition vitamins={crop?.nutrition} />}
            {crop?.flavor.length > 0 && (
              <Styled.SectionContainer>
                <Styled.SectionTitle>{t('cropGuide.flavor')}</Styled.SectionTitle>
                <Styled.ButtonsContainer>
                  {crop?.flavor.map((item) => {
                    return <Styled.Button key={item?.uuid}>{item?.name}</Styled.Button>;
                  })}
                </Styled.ButtonsContainer>
              </Styled.SectionContainer>
            )}
          </Styled.ContentContainer>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default React.memo(CropGuide);
