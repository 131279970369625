import { styled } from '@plant/ui';

export const AlertWrapper = styled('div')<{ variant: 'success' | 'error' }>(
  ({ theme, variant }) => ({
    background:
      variant === 'error'
        ? theme.palette.error.light
        : theme.palette.primary.main,

    borderRadius: '15px',
    borderLeft: `3px solid ${
      variant === 'error'
        ? theme.palette.error.main
        : theme.palette.secondary.main
    }`,

    padding: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    fontWeight: 600,
    fontSize: 14,
    color:
      variant === 'error'
        ? theme.palette.error.main
        : theme.palette.secondary.main,
    width: '100%',
    height: 56,
    marginTop: 10,
  })
);
