import React from 'react';
import { GoogleLogoIcon, FacebookLogoIcon, InstagramLogoIcon } from '@plant/ui';
import {
  FacebookSocialLink,
  GoogleSocialLink,
  InstagramSocialLink,
} from './styles';

type SocialLinkVariants = 'google' | 'facebook' | 'instagram';

interface SocialLinkProps {
  variant: SocialLinkVariants;
  onClick: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const SocialLinkContainers = {
  google: GoogleSocialLink,
  facebook: FacebookSocialLink,
  instagram: InstagramSocialLink,
};

const SocialLinkImageSizes = {
  google: { width: 16, height: 16 },
  facebook: { width: 8, height: 17 },
  instagram: { width: 18, height: 18 },
};

const SocialLinkImages = {
  google: GoogleLogoIcon,
  facebook: FacebookLogoIcon,
  instagram: InstagramLogoIcon,
};

const SocialLink: React.FC<SocialLinkProps> = ({ variant, onClick }) => {
  const Container = SocialLinkContainers[variant];
  const LinkLogo = SocialLinkImages[variant];
  const { width, height } = SocialLinkImageSizes[variant];

  return (
    <Container onClick={onClick}>
      <LinkLogo width={width} height={height} />
    </Container>
  );
};

export default React.memo(SocialLink);
