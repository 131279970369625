import { styled } from '@plant/ui';

export const Text = styled('p')(({ theme }) => ({
  color: theme.palette['light'].light,
  marginTop: 20,
  maxWidth: 500,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 12,
}));
