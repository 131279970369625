import React from 'react';
import * as Styled from './styles';
import { CuttingIcon, AddIcon } from '@plant/ui';
import { useSelector } from 'react-redux';
import { activeSelector } from '../../../../store/selectors/units';
import usePlantModal from '../../../../core/components/modals/AddPlant';

const AddBox: React.FC = () => {
  const active = useSelector(activeSelector);
  const [showModal] = usePlantModal();
  const addHandler = () => {
    if (active) {
      showModal();
    }
  };

  return (
    <Styled.Circle active={active} onClick={addHandler}>
      <CuttingIcon width={21} height={21} />
      <Styled.SmallCircle>
        <AddIcon width={10} height={9} />
      </Styled.SmallCircle>
    </Styled.Circle>
  );
};

export default React.memo(AddBox);
