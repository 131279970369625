import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Country, City } from 'country-state-city';
import { Input, Select } from '@plant/ui';
import UserAvatar from '../../core/components/UserAvatar';
import * as Styled from './styles';
import { PersonalFormValues } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
  authDataSelector,
  authErrorSelector,
  authLoadingSelector,
  authTokenSelector,
} from '../../store/selectors/auth';
import useChangePasswordModal from '../../core/components/modals/ChangePassword';
import {
  changeUserDataAction,
  deleteAccountAction,
  removeAvatarAction,
} from '../../store/actions/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { personalSchema } from '../../core/yupShemas/personal';
import { formErrors } from '../../core/helpers/form-errors';
import ErrorAlert from '../../core/components/ErrorAlert';
import Loader from '../../core/components/Loader';
import useUploadAvatarModal from '../../core/components/modals/UploadAvatar';
import useConfirmModal from '../../core/components/modals/Confirm';

const Personal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(authErrorSelector);
  const loading = useSelector(authLoadingSelector);
  const token = useSelector(authTokenSelector);
  const {
    firstName,
    lastName,
    email,
    streetAddress,
    country: userCountry,
    city,
    uuid,
  } = useSelector(authDataSelector);
  const memoizedPersonalSchema = useMemo(() => personalSchema(t), [t]);
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    control,
    watch,
    resetField,
  } = useForm<PersonalFormValues>({
    mode: 'onChange',
    resolver: yupResolver(memoizedPersonalSchema),
    defaultValues: {
      firstName,
      lastName,
      email,
      street: streetAddress || '',
      country: userCountry || '',
      city: city || '',
    },
  });
  const [showPasswordModal] = useChangePasswordModal();
  const [showUploadPhotoModal] = useUploadAvatarModal();
  const [showConfirmModal] = useConfirmModal();
  const country = watch('country');
  const errorsArray = formErrors(errors);

  const countries = Country.getAllCountries();
  const cities = useMemo(
    () => City.getCitiesOfCountry(countries.find((item) => item.name === country)?.isoCode),
    [country],
  );

  useEffect(() => {
    if (dirtyFields.country) resetField('city', { defaultValue: '' });
  }, [country]);

  const onAvatarRemove = () => dispatch(removeAvatarAction.request({ uuid }));

  const handleRemoveAvatar = () => {
    showConfirmModal({
      onAccept: onAvatarRemove,
      title: t('personal.removeAvatarModal.title'),
      text: t('personal.removeAvatarModal.text'),
    });
  };

  const onSave =
    ({ firstName, lastName, street, country, city }: PersonalFormValues) =>
    () => {
      dispatch(
        changeUserDataAction.request({
          firstName,
          lastName,
          street,
          country,
          city,
          token,
        }),
      );
    };

  const onSubmit = (values: PersonalFormValues) => {
    showConfirmModal({
      onAccept: onSave(values),
      title: t('personal.saveInfoModal.title'),
      text: t('personal.saveInfoModal.text'),
    });
  };

  const handleAccountDelete = () => {
    showConfirmModal({
      onAccept: () => dispatch(deleteAccountAction.request({ uuid })),
      title: t('personal.deleteAccountModal.title'),
      text: t('personal.deleteAccountModal.text'),
    });
  };

  return (
    <Styled.Container>
      <Styled.Title>{t('personal.title')}</Styled.Title>
      <Styled.Hr />
      <Styled.SubTitle>{t('personal.avatar')}</Styled.SubTitle>
      <Styled.AvatarContainer>
        <UserAvatar withPopup={false} variant="medium" />
        <Styled.ButtonsContainer>
          <Styled.ContainedButton onClick={showUploadPhotoModal}>
            {t('personal.uploadImage')}
          </Styled.ContainedButton>
          <Styled.OutlinedButton onClick={handleRemoveAvatar}>
            {t('personal.remove')}
          </Styled.OutlinedButton>
        </Styled.ButtonsContainer>
      </Styled.AvatarContainer>
      <Styled.SubTitle>{t('personal.details')}</Styled.SubTitle>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          title={t('personal.form.firstName')}
          isInvalid={Boolean(errors['firstName'])}
          {...register('firstName')}
        />
        <Input
          title={t('personal.form.lastName')}
          isInvalid={Boolean(errors['lastName'])}
          {...register('lastName')}
        />
        <Input title={t('personal.form.email')} disabled {...register('email')} />
        <Input title={t('personal.form.street')} {...register('street')} />
        <Controller
          name="country"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              title={t('personal.form.country')}
              data={countries}
              value={value}
              onChange={onChange}
              clearItem={t('personal.clearItem')}
              withSearch
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              title={t('personal.form.city')}
              data={cities}
              value={value}
              disabled={!country || cities.length < 1}
              onChange={onChange}
              clearItem={t('personal.clearItem')}
              withSearch
            />
          )}
        />
        <Input
          title={t('personal.form.password')}
          type="password"
          disabledInput
          RightElement={
            <Styled.ChangePasswordButton
              variant="link"
              type="button"
              onClick={showPasswordModal}
              title={t('personal.changePassword')}
            />
          }
          value="*******"
        />

        <Styled.ButtonContainer>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Styled.SubmitButton
                variant="outlined"
                title={t('personal.deleteAccount')}
                type="button"
                maxWidth={200}
                onClick={handleAccountDelete}
              />
              <Styled.SubmitButton
                maxWidth={200}
                variant="contained"
                type="submit"
                title={t('personal.save')}
              />
            </>
          )}
        </Styled.ButtonContainer>
      </Styled.Form>
      {(Boolean(error) || errorsArray.length > 0) && (
        <ErrorAlert variant="error" text={error ? error : errorsArray[0]} />
      )}
    </Styled.Container>
  );
};

export default React.memo(Personal);
