import { PlantService, Unit, UnitService } from '@plant/data';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  takeLatest,
  Effect,
  put,
  SagaReturnType,
  call,
  take,
  race,
  delay,
} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { SESSION_STORAGE_KEYS } from '../../core/constants/sessionStorageKeys';
import {
  addPlantAction,
  addPlantWithDeleteAction,
  changeStartDateAction,
  deletePlantAction,
  deleteUnitAction,
  getUnitsAction,
  rebootUnitAction,
  resetUnitAction,
  setSelectedUnitIdAction,
  startFetchingUpdatesAction,
  stopFetchingUpdatesAction,
  stopLightingAction,
  updateSelectedUnitAction,
  removeNutritionAlertAction
} from '../actions/units';

export class UnitsSagaWorker {
  static *setSelectedUnitId({ payload }: ActionType<typeof setSelectedUnitIdAction>) {
    yield sessionStorage.setItem(SESSION_STORAGE_KEYS.unitUuid, payload.uuid);
  }

  static *getUnits() {
    try {
      const units: SagaReturnType<typeof UnitService.getUnits> = yield call(
        UnitService.getUnits,
        50,
      );
      const defaultUnitUuid = yield sessionStorage.getItem(SESSION_STORAGE_KEYS.unitUuid);

      yield put(getUnitsAction.success({ units, defaultUnitUuid }));
    } catch (error) {
      yield put(getUnitsAction.failure(error?.response?.data?.error));
    }
  }

  static *addPlant({ payload }: ActionType<typeof addPlantAction.request>) {
    try {
      const response: SagaReturnType<typeof PlantService.addPlant> = yield call(
        PlantService.addPlant,
        payload.unitUuid,
        payload.cropUuid,
        payload.plantActionUuid,
        payload.pod,
      );
      yield put(addPlantAction.success({ response }));
    } catch (error) {
      yield put(addPlantAction.failure(error?.response?.data?.error));
    }
  }

  static *deletePlant({ payload }: ActionType<typeof deletePlantAction.request>) {
    try {
      yield call(PlantService.removePlant, payload.plantUuid);
      yield put(deletePlantAction.success({ plantUuid: payload.plantUuid }));
    } catch (error) {
      yield put(deletePlantAction.failure(error?.response?.data?.error));
    }
  }

  static *deleteUnit({ payload }: ActionType<typeof deleteUnitAction.request>) {
    try {
      const { unitUuid } = payload;
      yield call(UnitService.deleteUnit, unitUuid);
      yield put(deleteUnitAction.success({ unitUuid }));
    } catch (error) {
      yield put(deleteUnitAction.failure(error?.response?.data?.error));
    }
  }

  static *addPlantWithDelete({ payload }: ActionType<typeof addPlantWithDeleteAction.request>) {
    try {
      const { plantUuid, ...addParams } = payload;

      yield call(PlantService.removePlant, payload.plantUuid);
      yield put(deletePlantAction.success({ plantUuid: payload.plantUuid }));

      yield put(addPlantAction.request(addParams));
    } catch (error) {
      yield put(addPlantWithDeleteAction.failure(error?.response?.data?.error));
    }
  }

  static *fetchingUnit({ payload }: ActionType<typeof startFetchingUpdatesAction>) {
    while (true) {
      try {
        const unit: Unit = yield call(UnitService.getUnit, payload.uuid);
        yield put(updateSelectedUnitAction(unit));
        yield delay(90000);
      } catch (error) {
        yield delay(90000);
      }
    }
  }

  static *stopLighting({ payload }: ActionType<typeof stopLightingAction.request>) {
    try {
      const { unitUuid } = payload;
      const settings: SagaReturnType<typeof UnitService.stopLighting> = yield call(
        UnitService.stopLighting,
        unitUuid,
      );
      yield put(stopLightingAction.success({ unitUuid, settings }));
    } catch (error) {
      yield put(stopLightingAction.failure(error?.response?.data?.error));
    }
  }

  static *changeStartDate({ payload }: ActionType<typeof changeStartDateAction.request>) {
    try {
      const { unitUuid, startDate } = payload;
      const settings: SagaReturnType<typeof UnitService.changeStartDate> = yield call(
        UnitService.changeStartDate,
        unitUuid,
        startDate,
      );
      yield put(changeStartDateAction.success({ unitUuid, settings }));
    } catch (error) {
      yield put(changeStartDateAction.failure(error?.response?.data?.error));
    }
  }

  static *rebootUnit({ payload }: ActionType<typeof rebootUnitAction.request>) {
    try {
      const response: SagaReturnType<typeof UnitService.rebootUnit> = yield call(
        UnitService.rebootUnit,
        payload.unitUuid,
      );
      yield put(rebootUnitAction.success(response));
      toast.success(`${t('personal.messages.reboot')}`);
    } catch (error) {
      yield put(rebootUnitAction.failure(error?.response?.data?.error));
    }
  }

  static *resetUnit({ payload }: ActionType<typeof resetUnitAction.request>) {
    try {
      const response: SagaReturnType<typeof UnitService.resetUnit> = yield call(
        UnitService.resetUnit,
        payload.unitUuid,
      );
      yield put(resetUnitAction.success(response));
      toast.success(`${t('personal.messages.reset')}`);
    } catch (error) {
      yield put(resetUnitAction.failure(error?.response?.data?.error));
    }
  }

  static *removeNutritionAlert({ payload }: ActionType<typeof removeNutritionAlertAction.request>) {
    try {
      const response: SagaReturnType<typeof UnitService.removeNutritionAlert> = yield call(
        UnitService.removeNutritionAlert,
        payload.unitUuid,
      );
      yield put(removeNutritionAlertAction.success({ unitUuid: payload.unitUuid, data: response.data.data }));
    } catch (error) {
      yield put(removeNutritionAlertAction.failure(error?.response?.data?.error));
    }
  }
}

export function* unitsSaga(): Generator<Effect, void> {
  yield takeLatest(setSelectedUnitIdAction, UnitsSagaWorker.setSelectedUnitId);
  yield takeLatest(getUnitsAction.request, UnitsSagaWorker.getUnits);
  yield takeLatest(addPlantAction.request, UnitsSagaWorker.addPlant);
  yield takeLatest(deletePlantAction.request, UnitsSagaWorker.deletePlant);
  yield takeLatest(addPlantWithDeleteAction.request, UnitsSagaWorker.addPlantWithDelete);
  yield takeLatest(deleteUnitAction.request, UnitsSagaWorker.deleteUnit);
  yield takeLatest(stopLightingAction.request, UnitsSagaWorker.stopLighting);
  yield takeLatest(rebootUnitAction.request, UnitsSagaWorker.rebootUnit);
  yield takeLatest(resetUnitAction.request, UnitsSagaWorker.resetUnit);
  yield takeLatest(changeStartDateAction.request, UnitsSagaWorker.changeStartDate);
  yield takeLatest(removeNutritionAlertAction.request, UnitsSagaWorker.removeNutritionAlert);
  while (true) {
    const action: any = yield take(startFetchingUpdatesAction);
    yield race([call(UnitsSagaWorker.fetchingUnit, action), take(stopFetchingUpdatesAction)]);
  }
}
