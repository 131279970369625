import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { CropperRef } from 'react-advanced-cropper';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, CloseIcon } from '@plant/ui';
import { Slide } from '@mui/material';
import * as Styled from './styles';
import { ModalContainer } from '../../../constants/modalContainer';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { ModalOptions } from '../../../services/modals';
import { uploadAvatarAction } from '../../../../store/actions/auth';

const UploadAvatarModal: React.FC<ModalOptions> = ({ close }) => {
  const cropperRef = useRef<CropperRef>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [file, setFile] = useState<File>(null);
  const imageUrl = file ? URL.createObjectURL(file) : null;

  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  const handleUpload = () => {
    const canvas = cropperRef.current?.getCanvas();
    if (canvas) {
      const form = new FormData();
      canvas.toBlob((blob) => {
        if (blob) {
          form.append('photo', blob);
          dispatch(uploadAvatarAction.request({ body: form }));
          close();
        }
      }, 'image/jpeg');
    }
  };

  const handleDrag = (event: DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();

    const newFile = event.dataTransfer.files[0];
    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="down">
        <Styled.Container selected={Boolean(file)}>
          <Styled.CloseContainer>
            <Styled.Close onClick={close}>
              <CloseIcon width={14} height={14} />
            </Styled.Close>
          </Styled.CloseContainer>
          <Styled.BodyContainer>
            <Styled.ModalTitle>
              {file
                ? t('uploadAvatarModal.secondTitle')
                : t('uploadAvatarModal.title')}
            </Styled.ModalTitle>
            {file && (
              <>
                <Styled.CustomCropper
                  defaultSize={{ width: 300, height: 300 }}
                  stencilProps={{
                    aspectRatio: 1 / 1,
                  }}
                  ref={cropperRef}
                  src={imageUrl}
                />
                <Styled.ButtonContainer>
                  <Button
                    maxWidth={250}
                    variant="contained"
                    title={t('uploadAvatarModal.buttonText')}
                    onClick={handleUpload}
                  />
                  <Button
                    maxWidth={250}
                    variant="outlined"
                    title={t('uploadAvatarModal.cancel')}
                    onClick={close}
                  />
                </Styled.ButtonContainer>
              </>
            )}
            {!file && (
              <>
                <Styled.ModalText>
                  {t('uploadAvatarModal.text')}
                </Styled.ModalText>

                <Styled.UploadButton
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    onChange={handleImageChange}
                    hidden
                  />
                  <Styled.UploadButtonLabel>
                    {t('uploadAvatarModal.buttonText')}
                  </Styled.UploadButtonLabel>
                </Styled.UploadButton>
              </>
            )}
          </Styled.BodyContainer>
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const useUploadAvatarModal = createModalHook<ModalOptions>(
  (props) => () => <UploadAvatarModal {...props} />,
  ModalContainer.Root
);

export default useUploadAvatarModal;
