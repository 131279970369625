import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const searchCropsStoreSelector = (state: AppState) => state.searchCrops;

export const searchCropsResultsSelector = createSelector(
  searchCropsStoreSelector,
  (state) => state.keys.map((key) => state.crops[key])
);

export const searchCropsLoadingSelector = createSelector(
  searchCropsStoreSelector,
  (state) => state.loading
);
