import { ActionType, createAsyncAction } from 'typesafe-actions';
import { About, IGetFaqType } from '@plant/data';

export enum ContentTypes {
  RequestFAQ = '[CONTENT] RequestFAQ',
  RequestFAQSuccess = '[CONTENT] RequestFAQSuccess',
  RequestFAQFailed = '[CONTENT] RequestFAQFailed',

  RequestAbout = '[CONTENT] RequestAbout',
  RequestAboutSuccess = '[CONTENT] RequestAboutSuccess',
  RequestAboutFailed = '[CONTENT] RequestAboutFailed',
}

export const getFAQAction = createAsyncAction(
  ContentTypes.RequestFAQ,
  ContentTypes.RequestFAQSuccess,
  ContentTypes.RequestFAQFailed
)<{ page: number; count: number }, IGetFaqType[], string>();

export const getAboutAction = createAsyncAction(
  ContentTypes.RequestAbout,
  ContentTypes.RequestAboutSuccess,
  ContentTypes.RequestAboutFailed
)<{ page: number; count: number }, About[], string>();

export type ContentUnion = ActionType<
  typeof getFAQAction | typeof getAboutAction
>;
