import { css, styled, mountingAnimation, opacityAnimation } from '@plant/ui';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: 17,
  paddingBottom: 35,
  backgroundColor: theme.palette.light.main,
  borderRadius: 63,
}));

export const Paper = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '0 30px 20px',
  background:
    'linear-gradient(220.63deg, #FFFFFF -34.15%, rgba(255, 255, 255, 0) 97.43%), #DEEDE6',
  borderRadius: 63,
  position: 'relative',
  height: 320,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-around',

  '& section:nth-of-type(3)': {
    margin: '100px 0',
  },
  '& section:nth-of-type(6)': {
    margin: '100px 0',
  },
}));

export const ColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  width: '100%',
});

export const PlantContainer = styled('section', {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ isOpen }) => ({
  borderRadius: '50%',
  width: 105,
  height: 105,
  backgroundColor: 'transparent',
  border: '1.2px solid rgba(191, 201, 195, 0.4)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'relative',
  zIndex: isOpen ? 2 : 'auto',
}));

export const PlantInner = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: 80,
  height: 80,
  backgroundColor: theme.palette.light.main,
  border: '3.6px solid rgba(191, 201, 195, 0.4)',

  boxShadow: '0px 7.29368px 15.803px rgba(51, 83, 56, 0.28)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const PlantImage = styled('img')({
  userSelect: 'none',
  width: 135,
});

export const InfoTooltipButton = styled('div')(({ theme }) => ({
  width: 32,
  height: 21,
  borderRadius: 7,
  backgroundColor: theme.palette.light.main,
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute',
  bottom: '90%',

  '& svg': {
    width: 12,
    height: 13,
    fill: '#0F896C',
  },
}));

export const InfoTooltip = styled('div')(
  ({ theme }) => ({
    borderRadius: 15,
    padding: 16,
    backgroundColor: theme.palette.light.main,
    boxShadow: '0px 24px 64px rgba(145, 158, 156, 0.6)',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,

    position: 'absolute',
    top: '20%',
    left: '40%',
    zIndex: 2,

    '& svg': {
      width: 16,
      height: 16,
      fill: '#0F896C',
    },
  }),
  css`
    animation: ${opacityAnimation} 0.1s linear;
  `
);

export const InfoTooltipItem = styled('div')(({ theme }) => ({
  width: '100%',

  display: 'flex',
  gap: 12,
}));

export const InfoTooltipContentBox = styled('div')(({ theme }) => ({
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  gap: 5,
}));

export const InfoTooltipLabel = styled('span')(({ theme }) => ({
  color: theme.palette.light.light,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '18px',
  width: 'max-content',
}));

export const InfoTooltipValue = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '18px',
}));

export const AddLabel = styled('span')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 11,
  lineHeight: '13px',
  color: theme.palette.light.dark,
  width: 'min-content',
  textAlign: 'center',
  marginBottom: 4,
}));

export const PopupButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 'calc(103%)',
  height: 12,
  display: 'flex',
  alignItems: 'center',
  gap: 6,
}));

export const PlantName = styled('span')(({ theme }) => ({
  fontWeight: 800,
  fontSize: 10,
  lineHeight: '10px',
  color: '#263930',
  opacity: 0.56,

  textTransform: 'uppercase',
}));

export const PopupButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'unActive' && prop !== 'isOpen',
})<{
  isOpen: boolean;
  unActive?: boolean;
}>(({ theme, isOpen, unActive = false }) => ({
  width: 27,
  height: 12,
  borderRadius: 6,
  backgroundColor: theme.palette.light.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  cursor: unActive ? 'default' : 'pointer',
  '& svg': {
    transition: 'all .3s',
    transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
    userSelect: 'none',
    fill: 'rgba(42, 92, 68, 0.51)',
  },
}));

export const Popup = styled('div')(
  ({ theme }) => ({
    position: 'absolute',
    top: '125%',
    width: 310,
    padding: 10,
    backgroundColor: theme.palette.light.main,
    zIndex: 2,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    boxShadow: '0px 24px 64px rgba(145, 158, 156, 0.6)',
  }),
  css`
    animation: ${mountingAnimation} 0.2s linear;
  `
);

export const PopupItem = styled('button')(({ theme }) => ({
  borderRadius: 12,
  padding: '11px 15px',
  backgroundColor: '#F0F5F4',
  width: '100%',
  border: 'none',
  outline: 'none',
  transition: 'all .1s linear',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& svg': {
    fill: theme.palette.secondary.main,
  },

  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    '& p': {
      color: theme.palette.light.main,
    },
    '& svg': {
      fill: theme.palette.light.main,
    },
  },
}));

export const PopupItemText = styled('p')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '21px',
  textAlign: 'center',
  color: theme.palette.secondary.main,
  margin: 0,
  width: '100%',
}));

export const BackPaper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0.5,
  backgroundColor: 'white',
  zIndex: 1,
  borderRadius: 63,
});

export const InfoContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '40px 37px',
  paddingBottom: 0,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 40,
}));

export const InfoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& svg': {
    marginBottom: 3,
    fill: '#1F483E',
  },
}));

export const Value = styled('span')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 32,
  lineHeight: '32px',
  color: '#1F483E',
  marginBottom: 6,
}));

export const Label = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '14px',
  color: theme.palette.secondary.main,
  opacity: 0.7,
}));

export const Icon = styled('img')({});

export const Message = styled('div')(({ theme }) => ({
  padding: '8px 32px',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 38,
  borderRadius: 73,
  top: -19,
  zIndex: 1,
  gap: 12,
}));

export const TempMessage = styled(Message)(({ theme }) => ({
  backgroundColor: '#FFD15C',
  color: '#926800',

  '& svg': {
    fill: '#926800',
  },
}));

export const WaterMessage = styled(Message)(({ theme }) => ({
  backgroundColor: '#EE7674',
  color: theme.palette.light.main,

  '& svg': {
    fill: theme.palette.light.main,
  },
}));

export const LeakMessage = styled(Message)(({ theme }) => ({
  backgroundColor: '#B9EDF4 ',
  color: '#0189D6',

  '& svg': {
    fill: '#0189D6',
  },
}));

export const ConnectionMessage = styled(Message)(({ theme }) => ({
  backgroundColor: '#B9F4DB',
  color: '#0F896C',

  '& svg': {
    fill: '#0F896C',
  },
}));

export const SensorsMessage = styled(Message)(({ theme }) => ({
  backgroundColor: '#B9F4DB',
  color: '#0F896C',

  '& svg': {
    fill: '#0F896C',
  },
}));

export const DoneButton = styled('button')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  border: '1px solid #926800',
  cursor: 'pointer',
  background: 'none',
  borderRadius: 15,
  padding: '5px 10px',
}))
