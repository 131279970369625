import { styled } from '@plant/ui';

export const Circle = styled('div')<{ active: boolean }>(
  ({ theme, active }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: 45,
    height: 45,
    cursor: active ? 'pointer' : 'default',
    opacity: active ? 1 : 0.5,

    '&>div': {
      cursor: active ? 'pointer' : 'default',
    },

    '&>svg': {
      width: 22,
      fill: theme.palette.light.main,
    },
  })
);

export const Icon = styled('img')(({ theme }) => ({}));

export const SmallCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -3,
  right: -3,
  backgroundColor: theme.palette.light.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 18,
  height: 18,
  cursor: 'pointer',

  boxShadow: '0px 3.21429px 3.21429px rgba(30, 52, 41, 0.24)',

  '&>svg': {
    fill: theme.palette.secondary.main,
  },
}));
