import React from 'react';
import { ArrowIcon } from '@plant/ui';
import * as Styled from './styles';

interface SearchItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  text: string;
  icon: React.ReactNode;
}

const SearchItem: React.FC<SearchItemProps> = ({ text, icon, ...props }) => {
  return (
    <Styled.Container {...props}>
      {icon}
      <Styled.Text>{text}</Styled.Text>
      <Styled.ArrowContainer>
        <ArrowIcon width={10} height={4} />
      </Styled.ArrowContainer>
    </Styled.Container>
  );
};

export default React.memo(SearchItem);
