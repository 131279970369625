import { AppRoutes } from '../../constants/appRoutes';
import { PlantsIcon, LightingIcon, NavbarCropGuideIcon } from '@plant/ui';
import { NavBarItem } from '../../interfaces/navBarItem';

export const navBarItems: NavBarItem[] = [
  {
    label: 'navbar.plantLabel',
    icon: PlantsIcon,
    path: AppRoutes.DashBoard,
  },
  {
    label: 'navbar.lightingLabel',
    icon: LightingIcon,
    path: AppRoutes.Lighting,
  },
  {
    label: 'navbar.cropGuideLabel',
    icon: NavbarCropGuideIcon,
    path: AppRoutes.CropGuide,
  },
];
