import { AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowIcon } from '@plant/ui';
import { useSelector } from 'react-redux';
import * as Styled from './style';

interface AccordionProps {
  title: string;
  body: string;
  expanded: boolean;
  handleChange: (_, isExpanded: boolean) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  expanded,
  body,
  handleChange,
}) => {
  return (
    <Styled.StyledAccordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={
          <Styled.ArrowWrapper>
            <ArrowIcon width={12} height={7} />
          </Styled.ArrowWrapper>
        }
      >
        <Styled.AccordionHeaderContainer>
          <Styled.AccordionTitle>{title}</Styled.AccordionTitle>
        </Styled.AccordionHeaderContainer>
      </AccordionSummary>
      <Styled.StyledAccordionDetails>
        <Styled.AccordionBodyContainer>{body}</Styled.AccordionBodyContainer>
      </Styled.StyledAccordionDetails>
    </Styled.StyledAccordion>
  );
};

export default Accordion;
