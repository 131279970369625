import { Button, styled } from '@plant/ui';

export const Container = styled('div')({
  maxWidth: 550,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Title = styled('h4')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  lineHeight: '22px',
  color: theme.palette.green.dark,
  margin: 0,
  marginBottom: 14,
}));

export const Hr = styled('hr')(({ theme }) => ({
  border: 'none',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.light.dark,
  width: '100%',
  margin: 0,
  opacity: 0.3,
  marginBottom: 50,
}));

export const SubTitle = styled('h4')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '15px',
  color: theme.palette.secondary.main,
  margin: 0,
  marginBottom: 25,
}));

export const AvatarContainer = styled('div')({
  gap: 30,
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40,
});

export const ButtonsContainer = styled('div')({
  gap: 10,
  display: 'flex',
  alignItems: 'center',
});

export const ContainedButton = styled('button')(({ theme }) => ({
  padding: '10px 15px',
  backgroundColor: theme.palette.light.main,
  border: 'none',
  cursor: 'pointer',

  fontSize: 14,
  lineHeight: '14px',
  fontWeight: 600,
  color: theme.palette.gray.dark,
  opacity: 0.9,
  borderRadius: 50,
}));

export const OutlinedButton = styled('button')(({ theme }) => ({
  padding: '10px 15px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.gray.light}`,
  cursor: 'pointer',

  fontSize: 14,
  lineHeight: '14px',
  fontWeight: 600,
  color: theme.palette.gray.dark,
  opacity: 0.9,
  borderRadius: 50,
}));

export const Form = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginBottom: 35,
});

export const SubmitButton = styled(Button)({
  marginTop: 25,
});

export const ChangePasswordButton = styled(Button)({
  width: 'max-content',
});

export const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});
