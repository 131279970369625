import { createReducer } from 'typesafe-actions';
import { UserData } from '@plant/data';
import {
  AuthActionUnion,
  changeUserDataAction,
  clearAuthAction,
  clearAuthErrorAction,
  clearAuthStateAction,
  deleteAccountAction,
  loginAction,
  removeAvatarAction,
  restorePasswordAction,
  setNewPasswordAction,
  signUpAction,
  socialAuthAction,
  uploadAvatarAction,
} from '../actions/auth';
import { AuthState } from '../../core/constants/authState';

export interface State {
  userData: UserData;
  state: AuthState;
  loading: boolean;
  error: string;
}

export const initialState: State = {
  userData: null,
  state: null,
  loading: false,
  error: null,
};

export const reducer = createReducer<State, AuthActionUnion>(initialState)
  .handleAction(loginAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(loginAction.success, (state, action) => ({
    ...state,
    userData: action.payload.data,
    loading: false,
    error: null,
  }))
  .handleAction(loginAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(signUpAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(signUpAction.success, (state) => ({
    ...state,
    loading: false,
    state: AuthState.afterSignUp,
    error: null,
  }))
  .handleAction(signUpAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(clearAuthErrorAction, (state) => ({
    ...state,
    error: null,
  }))
  .handleAction(restorePasswordAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(restorePasswordAction.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(restorePasswordAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(setNewPasswordAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(setNewPasswordAction.success, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(setNewPasswordAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(changeUserDataAction.request, (state) => ({
    ...state,
  }))
  .handleAction(changeUserDataAction.success, (state, action) => ({
    ...state,
    loading: false,
    userData: {
      ...action.payload.userData,
      token: state.userData.token,
    },
    error: null,
  }))
  .handleAction(changeUserDataAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(uploadAvatarAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(uploadAvatarAction.success, (state, action) => ({
    ...state,
    loading: false,
    userData: { ...state.userData, photo: action.payload },
    error: null,
  }))
  .handleAction(uploadAvatarAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(removeAvatarAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(removeAvatarAction.success, (state) => ({
    ...state,
    loading: false,
    userData: { ...state.userData, photo: null },
    error: null,
  }))
  .handleAction(removeAvatarAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(socialAuthAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(socialAuthAction.success, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    userData: action.payload.data,
  }))
  .handleAction(socialAuthAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(clearAuthStateAction, (state) => ({
    ...state,
    state: null,
  }))
  .handleAction(deleteAccountAction.request, (state) => ({
    ...state,
    loading: false,
    error: null,
  }))
  .handleAction(deleteAccountAction.success, () => initialState)
  .handleAction(deleteAccountAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(clearAuthAction, () => initialState);
