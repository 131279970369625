import { styled } from '@plant/ui';

export const VideoWrapper = styled('div')({
  width: '100%',
  position: 'relative',
});

export const VideoGuide = styled('video')({
  borderRadius: 8,
  width: '100%',
});

export const PlayButton = styled('div')({
  borderRadius: '50%',
  width: 70,
  height: 70,
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(9px)',
  top: 'calc(50% - 35px)',
  left: 'calc(50% - 35px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Triangle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPause',
})<{ isPause: boolean }>(({ isPause }) => ({
  width: 25,
  height: 25,
  borderStyle: isPause ? 'double' : 'solid',
  borderWidth: isPause ? '0 0 0 22px' : '15px 0px 15px 25px',
  borderColor: 'transparent transparent transparent #1F483E',

  transition: 'all 100ms ease-in-out',
}));
