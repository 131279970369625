import { styled } from '@plant/ui';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.admin.main,
}));

export const Header = styled('div')({
  width: '100%',
  height: 90,
  backgroundColor: 'rgba(223, 232, 230, 0.8)',
  display: 'flex',
  justifyContent: 'center',
});

export const Container = styled('div')({
  maxWidth: 830,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
