import { styled } from '@plant/ui';

export const Container = styled('div')({
  maxWidth: 550,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Title = styled('h4')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  lineHeight: '22px',
  color: theme.palette.green.dark,
  margin: 0,
  marginBottom: 14,
}));

export const Hr = styled('hr')(({ theme }) => ({
  border: 'none',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.light.dark,
  width: '100%',
  margin: 0,
  opacity: 0.3,
  marginBottom: 50,
}));

export const PanelContainer = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: 15,
  padding: 20,
  backgroundColor: theme.palette.light.main,
  boxShadow: '0px 9px 24px #E1EBE9',
  marginBottom: 8,

  display: 'flex',
  flexDirection: 'column',
}));

export const PanelTitle = styled('h6')(({ theme }) => ({
  margin: 0,
  marginBottom: 12,
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '20px',
  color: theme.palette.green.dark,
}));

export const PanelText = styled('p')(({ theme }) => ({
  margin: 0,
  marginBottom: 16,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '21px',
  color: theme.palette.green.dark,
}));

export const PanelButton = styled('button')(({ theme }) => ({
  marginTop: 24,
  cursor: 'pointer',
  width: 'min-content',
  borderRadius: 25,
  background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',
  padding: '10px 18px',
  color: theme.palette.green.dark,
  fontWeight: 600,
  fontSize: 15,
  lineHeight: 1.3,
  border: 'none',
  transition: 'all .2s linear',

  '&:hover': {
    transform: 'translateY(-3px)',
  },
  '&:active': {
    transform: 'translateY(-1px)',
  },

  '&:disabled': {
    opacity: 0.5,
    transform: 'none',
  },
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  marginTop: 20,
});
