import { call, Effect, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { ProductService } from '@plant/data';
import { requestProductsAction } from '../actions/store';
import { ActionType } from 'typesafe-actions';

export class StoreSagaWorker {
  static *getProducts({ payload }: ActionType<typeof requestProductsAction.request>) {
    try {
      const { page, limit } = payload;
      const products: SagaReturnType<typeof ProductService.getProducts> = yield call(
        ProductService.getProducts,
        limit,
        page,
      );
      yield put(requestProductsAction.success({ products }));
    } catch (error) {
      yield put(requestProductsAction.failure(error?.response?.data?.error));
    }
  }
}

export function* storeSaga(): Generator<Effect, void> {
  yield takeLatest(requestProductsAction.request, StoreSagaWorker.getProducts);
}
