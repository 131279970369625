import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@plant/ui';
import * as Styled from './styles';
import Loader from '../../core/components/Loader';
import {
  selectedUnitIdSelector,
  unitsDataSelector,
  unitsLoadingSelector,
} from '../../store/selectors/units';
import { resetUnitAction } from '../../store/actions/units';
import useConfirmModal from '../../core/components/modals/Confirm';

const UnitManagment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmModal] = useConfirmModal();
  const loading = useSelector(unitsLoadingSelector);
  const selectedUnitId = useSelector(selectedUnitIdSelector);
  const units = useSelector(unitsDataSelector);
  const [selectedResetId, setSelectedResetId] = useState('');

  useEffect(() => {
    setSelectedResetId(selectedUnitId);
  }, [units]);

  const handleChangeSelectedResetId = (uuid: string) => {
    setSelectedResetId(uuid);
  };

  const onReset = () => {
    dispatch(resetUnitAction.request({ unitUuid: selectedResetId }));
  };

  const handleReset = () => {
    showConfirmModal({
      onAccept: onReset,
      title: t('unitManagment.resetTitle'),
      text: t('unitManagment.resetText'),
    });
  };

  return (
    <Styled.Container>
      <Styled.Title>{t('unitManagment.title')}</Styled.Title>
      <Styled.Hr />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Styled.PanelContainer>
            <Styled.PanelTitle>{t('unitManagment.resetTitle')}</Styled.PanelTitle>
            <Styled.PanelText>{t('unitManagment.resetText')}</Styled.PanelText>
            <Select
              title={t('unitManagment.selectTitle')}
              withBorder
              withoutShadow
              disabled={units.length === 0}
              value={units.find((item) => item.uuid === selectedResetId)?.name}
              data={units}
              onChange={handleChangeSelectedResetId}
            />
            <Styled.PanelButton disabled={units.length === 0} onClick={handleReset}>
              {t('unitManagment.resetButton')}
            </Styled.PanelButton>
          </Styled.PanelContainer>
        </>
      )}
    </Styled.Container>
  );
};

export default React.memo(UnitManagment);
