import { css, styled, mountingAnimation } from '@plant/ui';
import { UserAvatarVariants } from './types';
export const Wrapper = styled('div')({
  position: 'relative',
});

export const Avatar = styled('img', {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' && prop !== 'variant' && prop !== 'withPopup',
})<{ isSelected: boolean; variant: UserAvatarVariants; withPopup: boolean }>(
  ({ theme, isSelected, variant, withPopup }) => ({
    width: variant === 'small' ? 41 : 71,
    cursor: withPopup ? 'pointer' : 'default',
    borderRadius: '50%',
    border: `${variant === 'small' ? 2 : 5}px solid ${
      isSelected ? theme.palette.secondary.main : theme.palette.light.main
    } !important`,
    filter: 'drop-shadow(5px 2px 10px #CED8D6)',
  })
);

export const Popup = styled('div')(
  ({ theme }) => ({
    borderRadius: 8,
    padding: 20,
    backgroundColor: theme.palette.light.main,
    width: 'max-content',

    position: 'absolute',
    left: '110%',
    bottom: 0,
    zIndex: 2,
  }),
  css`
    animation: ${mountingAnimation} 0.2s linear;
  `
);

export const Name = styled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 15,
  color: theme.palette.secondary.main,
  marginBottom: 8,
}));

export const Email = styled('p')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 13,
  color: theme.palette.light.light,
  marginBottom: 18,
}));

export const Button = styled('button')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  marginBottom: 18,
  padding: '10px 15px',
  borderRadius: 50,
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
}));

export const Paper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  opacity: 0.5,
  zIndex: 1,
});
