import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styles';
import { deleteUnitAction, setSelectedUnitIdAction } from '../../../../../store/actions/units';
import { selectedUnitSelector, unitsDataSelector } from '../../../../../store/selectors/units';
import useConfirmModal from '../../../modals/Confirm';
import { useTranslation } from 'react-i18next';

const PlantSelect: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const units = useSelector(unitsDataSelector);
  const selectedUnit = useSelector(selectedUnitSelector);
  const [showConfirmModal] = useConfirmModal();

  const deleteCallback = (unitUuid: string) => () => {
    dispatch(deleteUnitAction.request({ unitUuid }));
  };

  const deleteHandler = (uuid: string) => {
    showConfirmModal({
      onAccept: deleteCallback(uuid),
      title: t('removeUnitModal.title'),
      text: t('removeUnitModal.text'),
    });
  };

  const clickHandler = (uuid: string) => {
    dispatch(setSelectedUnitIdAction({ uuid }));
  };

  return (
    <Styled.StyledSelect
      data={units}
      selectedItem={selectedUnit}
      deleteHandler={deleteHandler}
      clickHandler={clickHandler}
    />
  );
};

export default React.memo(PlantSelect);
