import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const contentStoreSelector = (state: AppState) => state.content;

export const contentLoadingSelector = createSelector(
  contentStoreSelector,
  (state) => state.loading
);

export const contentFaqSelector = createSelector(
  contentStoreSelector,
  (state) => state.faq
);

export const contentAboutSelector = createSelector(
  contentStoreSelector,
  (state) => state.about
);
