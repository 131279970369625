export const ledTimeMarks = [
  { value: 10, label: '10h' },
  { value: 12, label: '' },
  { value: 14, label: '14h' },
  { value: 16, label: '' },
  { value: 18, label: '18h' },
];

export const ledPowerMarks = [
  { value: 0, label: 'LOW' },
  { value: 1, label: '' },
  { value: 2, label: 'MID' },
  { value: 3, label: '' },
  { value: 4, label: 'HIGH' },
];

export const nutritionLevelMarks = [
  { value: 0, label: 'LOW' },
  { value: 1, label: 'MID' },
  { value: 2, label: 'HIGH' },
];

export const humidityLevelMarks = [
  { value: 0, label: 'LOW' },
  { value: 1, label: 'MID' },
  { value: 2, label: 'HIGH' },
];

export const defaultLedTime = 18;

export const defaultLedPower = 2;

export const defaultNutritionLevel = 1;

export const defaultHumidityLevel = 1;

export const MIN_ENVIRONMENT_NAME_LENGTH = 2;

export const MAX_ENVIRONMENT_NAME_LENGTH = 30;

export const nameRegex = /^[A-Za-z0-9_.]+$/;
