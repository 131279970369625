import { useTranslation } from 'react-i18next';
import { Button, CloseIcon } from '@plant/ui';
import { Slide } from '@mui/material';
import * as Styled from './styles';
import { ModalContainer } from '../../../constants/modalContainer';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { ModalOptions } from '../../../services/modals';

interface ConfirmModalProps extends ModalOptions {
  title: string;
  text: string;
  onAccept: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ close, title, text, onAccept }) => {
  const { t } = useTranslation();

  const handleAccept = () => {
    close();
    onAccept();
  };

  const handleDeny = () => {
    close();
  };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="down">
        <Styled.Container>
          <Styled.Close onClick={close}>
            <CloseIcon width={14} height={14} />
          </Styled.Close>
          <Styled.BodyContainer>
            <Styled.ModalTitle>{title}</Styled.ModalTitle>
            <Styled.ModalText>{text}</Styled.ModalText>
          </Styled.BodyContainer>
          <Styled.ButtonsContainer>
            <Button
              variant="contained"
              title={t('removeUnitModal.acceptButton')}
              onClick={handleAccept}
            />
            <Button
              variant="outlined"
              title={t('removeUnitModal.denyButton')}
              onClick={handleDeny}
            />
          </Styled.ButtonsContainer>
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const useConfirmModal = createModalHook<ConfirmModalProps>(
  (props) => () => <ConfirmModal {...props} />,
  ModalContainer.Root,
);

export default useConfirmModal;
