import React from 'react';
import { QuestionIcon, styled } from '@plant/ui';
import { Tooltip, tooltipClasses } from '@mui/material';
import * as Styled from './styles';

interface HintProps {
  text: string;
  placement: string;
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0F896C',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0F896C',
    color: theme.palette.light.main,
    borderRadius: 10,
    fontSize: 14,
  },
}));

const Hint: React.FC<HintProps> = ({ text, placement }) => {
  return (
    <CustomTooltip title={text} placement={placement}>
      <Styled.Elipse>
        <QuestionIcon width={15} height={15} />
      </Styled.Elipse>
    </CustomTooltip>
  );
};

export default React.memo(Hint);
