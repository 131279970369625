import React, { useState } from 'react';
import * as Styled from './styles';
import { ArrowIcon, RemoveIcon } from '@plant/ui';
import { SelectProps } from './types';
import { useTranslation } from 'react-i18next';

const Select: React.FC<SelectProps> = ({
  data,
  selectedItem,
  clickHandler,
  deleteHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (uuid: string) => () => {
    toggleOpen();
    clickHandler(uuid);
  };

  const handleDelete = (uuid: string) => (e: React.SyntheticEvent) => {
    e.stopPropagation();
    toggleOpen();
    deleteHandler(uuid);
  };

  const toggleOpen = () => {
    if (active) setIsOpen((value) => !value);
  };

  const active = data.length > 1;

  return (
    <>
      <Styled.Wrapper>
        <Styled.SelectContainer
          active={active}
          isOpen={isOpen}
          onClick={toggleOpen}
          {...props}
        >
          <Styled.SelectedItem>
            {selectedItem ? selectedItem?.name : t('unitPanel.addPlant')}
          </Styled.SelectedItem>
          <ArrowIcon width={9} height={5} />
        </Styled.SelectContainer>
        {isOpen && (
          <Styled.ListContainer>
            {data
              .filter((item) => item.uuid !== selectedItem?.uuid)
              .map((item) => {
                return (
                  <Styled.ListItem
                    onClick={handleClick(item?.uuid)}
                    key={item.uuid}
                  >
                    <Styled.ListLabel>{item.name}</Styled.ListLabel>
                    <Styled.IconWrapper onClick={handleDelete(item?.uuid)}>
                      <RemoveIcon width={19} height={19} />
                    </Styled.IconWrapper>
                  </Styled.ListItem>
                );
              })}
          </Styled.ListContainer>
        )}
      </Styled.Wrapper>
      {isOpen && <Styled.Paper onClick={toggleOpen} />}
    </>
  );
};

export default Select;
