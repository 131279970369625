import { ModalInstance } from '../../core/services/modals';
import { FC } from 'react';
import { ActionType, createAction } from 'typesafe-actions';
import { ModalContainer } from '../../core/constants/modalContainer';

export enum ModalsTypes {
  ShowModal = '[Modals] ShowModal',
  RemoveModal = '[Modals] RemoveModal',
  CreateModal = '[Modals] CreateModal',
}

export const createModalAction = createAction(ModalsTypes.CreateModal)<{
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modal: FC<any>;
  container: ModalContainer;
  options: ModalInstance['options'];
}>();
export const showModalAction = createAction(ModalsTypes.ShowModal)<{
  id: string;
}>();
export const removeModalAction = createAction(ModalsTypes.RemoveModal)<{
  id: string;
}>();

export type ModalsActionUnion = ActionType<
  typeof showModalAction | typeof removeModalAction | typeof createModalAction
>;
