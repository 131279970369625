import { styled } from '@plant/ui';

export const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',
});

export const FormLabel = styled('label')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 26,
  lineHeight: '26px',
  margin: '0 0 25px',
  color: theme.palette.secondary.main,
}));

export const TextCondition = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  opacity: 0.7,
  fontSize: 14,
  margin: '16px 0',
  textAlign: 'center',
}));

export const ButtonWrapper = styled('div')`
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ButtonsContainer = styled('div')`
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

export const LineDiv = styled('div')(({ theme }) => ({
  width: 1,
  height: 30,
  background: theme.palette.light.dark,
}));

export const SecondaryButtonWrapper = styled('div')`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoaderWrapper = styled('div')`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
