import React, { FC } from 'react';
import * as Styled from './styles';

interface AlertProps {
  text: string;
  variant: 'success' | 'error';
}

const Alert: FC<AlertProps> = ({ text, variant }) => {
  return <Styled.AlertWrapper variant={variant}>{text}</Styled.AlertWrapper>;
};

export default React.memo(Alert);
