import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { State } from '../reducers/auth';

const authStoreSelector = (state: AppState) => state.auth;

export const authLoadingSelector = createSelector(
  authStoreSelector,
  (state) => state.loading
);
export const authDataSelector = createSelector(
  authStoreSelector,
  (state: State) => state.userData
);

export const authTokenSelector = createSelector(
  authStoreSelector,
  (state: State) => state?.userData?.token
);

export const authErrorSelector = createSelector(
  authStoreSelector,
  (state: State) => state.error
);

export const avatarSelector = createSelector(
  authStoreSelector,
  (state: State) => state?.userData?.photo
);

export const authStateSelector = createSelector(
  authStoreSelector,
  (state: State) => state?.state
);
