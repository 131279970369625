import { Modal } from '@mui/material';
import { ScrollBarStyles, styled } from '@plant/ui';
import { Cropper } from 'react-advanced-cropper';

export const StyledModal = styled(Modal)({
  zIndex: 100,
});

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  width: selected ? 900 : 550,
  height: 'min-content',
  maxHeight: '90%',
  padding: selected ? '24px 48px' : 24,
  paddingBottom: 70,
  backgroundColor: theme.palette.light.main,
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
  outline: 'none',

  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
}));

export const BodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: 32,

  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    width: '0',
  },
}));

export const CloseContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
}));

export const ModalTitle = styled('h6')(({ theme }) => ({
  margin: 0,
  fontWeight: 600,
  fontSize: 25,
  color: theme.palette.green.dark,
}));

export const ModalText = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 400,
  fontSize: 17,
  color: theme.palette.green.dark,
  maxWidth: 270,
  textAlign: 'center',
  marginBottom: 100,
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
  marginTop: 22,
}));

export const UploadButton = styled('label')(({ theme }) => ({
  width: 250,
  backgroundColor: theme.palette.secondary.main,
  padding: 15,
  border: 'none',
  borderRadius: 75,
  cursor: 'pointer',
}));

export const UploadButtonLabel = styled('p')(({ theme }) => ({
  color: theme.palette.light.main,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',
  textAlign: 'center',
  margin: 0,
}));

export const Close = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    fill: theme.palette.green.dark,
    stroke: theme.palette.green.dark,
  },
}));

export const CustomCropper = styled(Cropper)({
  width: '100%',
  borderRadius: 15,
});
