import { combineReducers } from '@reduxjs/toolkit';
import 'react-redux';
import * as fromAuth from './auth';
import * as fromApp from './app';
import * as fromUnits from './units';
import * as fromEnvironments from './environments';
import * as fromModals from './modals';
import * as fromActions from './plantActions';
import * as fromSearchCrops from './searchCrops';
import * as fromTimelapse from './timelapse';
import * as fromNotifications from './notifications';
import * as fromContent from './content';
import * as fromStore from './store';

export interface AppState {
  auth: fromAuth.State;
  app: fromApp.State;
  units: fromUnits.State;
  environments: fromEnvironments.State;
  modals: fromModals.State;
  plantActions: fromActions.State;
  searchCrops: fromSearchCrops.State;
  timelapse: fromTimelapse.State;
  notifications: fromNotifications.State;
  content: fromContent.State;
  store: fromStore.State;
}

export const rootReducer = combineReducers<AppState>({
  auth: fromAuth.reducer,
  app: fromApp.reducer,
  units: fromUnits.reducer,
  environments: fromEnvironments.reducer,
  modals: fromModals.reducer,
  plantActions: fromActions.reducer,
  searchCrops: fromSearchCrops.reducer,
  timelapse: fromTimelapse.reducer,
  notifications: fromNotifications.reducer,
  content: fromContent.reducer,
  store: fromStore.reducer,
});
