import { styled } from '@plant/ui';

export const Elipse = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  width: 32,
  height: 32,
  marginTop: 7,
  boxShadow: '0px 5.33333px 5.33333px rgba(0, 0, 0, 0.07)',

  '& svg': {
    fill: '#1F483E',
  },
}));
