import { styled, mountingAnimation } from '@plant/ui';

export const Wrapper = styled('div')({
  position: 'relative',
  userSelect: 'none',
});

export const SelectContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'isOpen',
})<{
  isOpen: boolean;
  active: boolean;
}>(({ isOpen, theme, active }) => ({
  minWidth: 100,
  backgroundColor: isOpen ? '#FFF' : '#D6ECE1',
  borderRadius: 49,
  padding: '10px 20px',
  cursor: active ? 'pointer' : 'default',
  position: 'relative',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: isOpen ? 3 : 'auto',
  opacity: active ? 1 : 0.5,

  '& svg': {
    transition: 'all .3s',
    transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
    fill: theme.palette.secondary.main,
  },
}));

export const SelectedItem = styled('span')({
  fontWeight: 700,
  fontSize: 16,
  marginRight: 40,
  color: '#1F483E',
});

export const Icon = styled('img')({
  cursor: 'pointer',
  userSelect: 'none',
});

export const Paper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#1F483E',
  opacity: 0.5,
  zIndex: 2,
});

export const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 12px 10px 20px 10px;
  gap: 10px;
  border-radius: 15px;
  min-width: 250px;
  max-height: 530px;
  overflow-y: auto;
  overflow-x: hidden;

  margin-top: 8px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 3;

  animation: ${mountingAnimation} 0.1s linear;

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

export const ListItem = styled('div')({
  width: 310,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F0F5F4',
  borderRadius: 15,
  padding: 20,

  cursor: 'pointer',
});

export const ListLabel = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 15,
  color: theme.palette.secondary.main,
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  '& svg': {
    fill: theme.palette.error.main,
  },
}));
