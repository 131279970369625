import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const unitsStoreSelector = (state: AppState) => state.units;

export const unitsLoadingSelector = createSelector(
  unitsStoreSelector,
  (state) => state.loading
);
export const unitsDataSelector = createSelector(unitsStoreSelector, (state) =>
  state.unitKeys.map((key) => state.units[key])
);

export const selectedUnitSelector = createSelector(
  unitsStoreSelector,
  (state) => state?.units[state.selectedUnitId]
);

export const selectedUnitIdSelector = createSelector(
  unitsStoreSelector,
  (state) => state?.selectedUnitId
);

export const alertsSelector = createSelector(
  selectedUnitSelector,
  (state) => state?.alerts
);

export const activeSelector = createSelector(unitsStoreSelector, (state) =>
  Boolean(state?.selectedUnitId)
);

export const selectedUnitEnvironmentIdSelector = createSelector(
  unitsStoreSelector,
  (state) => state?.units[state.selectedUnitId]?.settings?.environment.uuid
);

export const unitImagesSelector = createSelector(
  unitsStoreSelector,
  (state) => state?.units[state.selectedUnitId]?.images
);
