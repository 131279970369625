import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Slide } from '@mui/material';
import { CloseIcon, ArrowIcon, TitledField, SearchIcon } from '@plant/ui';
import * as Styled from './styles';
import Accordion from './components/Accordion';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { ModalOptions } from '../../../services/modals';
import { ModalContainer } from '../../../constants/modalContainer';
import { selectedUnitSelector } from '../../../../store/selectors/units';
import { PODS_COUNT } from '../../../constants/podsCount';
import SearchItem from './components/SearchItem';
import {
  searchCropsLoadingSelector,
  searchCropsResultsSelector,
} from '../../../../store/selectors/searchCrops';
import Loader from '../../Loader';
import { searchCropsAction } from '../../../../store/actions/searchCrops';
import { imageWithToken } from '../../../helpers/image-with-token.helper';
import { authTokenSelector } from '../../../../store/selectors/auth';
import {
  addPlantAction,
  addPlantWithDeleteAction,
} from '../../../../store/actions/units';

interface AddPlantModalProps extends ModalOptions {
  data?: { pod: number; actionUuid: string };
  plantUuidForDelete?: string;
  cropId?: string;
}

const AddPlantModal: React.FC<AddPlantModalProps> = ({
  data,
  close,
  plantUuidForDelete = null,
  cropId = null,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<number | boolean>(false);
  const [selectedPod, setSelectedPod] = useState<{
    pod: number;
    actionUuid: string;
  }>(data || null);
  const [search, setSearch] = useState('');

  const selectedUnit = useSelector(selectedUnitSelector);
  const pods = selectedUnit?.pods;
  const searchLoading = useSelector(searchCropsLoadingSelector);
  const searchResults = useSelector(searchCropsResultsSelector);
  const token = useSelector(authTokenSelector);

  useEffect(() => {
    dispatch(searchCropsAction.request({ name: search }));
  }, [search, dispatch]);

  const handleChange = (index: number) => (_, isExpanded: boolean) => {
    setOpen(isExpanded ? index : false);
  };

  const handleSelectPod = (index: number) => (actionUuid: string) => () => {
    setSelectedPod({ pod: index, actionUuid });

    if (cropId) {
      handleAddPod(cropId, { pod: index, actionUuid })();
    }
  };

  const handleSearchChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleReset = () => {
    setSelectedPod(null);
  };

  const handleAddPod =
    (cropUuid: string, pod?: { pod: number; actionUuid: string }) => () => {
      close();
      if (plantUuidForDelete) {
        dispatch(
          addPlantWithDeleteAction.request({
            pod: pod ? pod.pod : selectedPod.pod,
            unitUuid: selectedUnit.uuid,
            plantActionUuid: pod ? pod.actionUuid : selectedPod.actionUuid,
            cropUuid,
            plantUuid: plantUuidForDelete,
          })
        );
      } else {
        dispatch(
          addPlantAction.request({
            pod: pod ? pod.pod : selectedPod.pod,
            unitUuid: selectedUnit.uuid,
            plantActionUuid: pod ? pod.actionUuid : selectedPod.actionUuid,
            cropUuid,
          })
        );
      }
    };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="right">
        <Styled.Container>
          <Styled.HeaderSection>
            {selectedPod ? (
              <>
                <Styled.CircleContainer onClick={handleReset}>
                  <Styled.ArrowWrapper>
                    <ArrowIcon width={12} height={4} />
                  </Styled.ArrowWrapper>
                </Styled.CircleContainer>
                <Styled.ModalTitle>
                  {t('addPlantModal.secondTitle')}
                </Styled.ModalTitle>
              </>
            ) : (
              <>
                <Styled.ModalTitle>
                  {t('addPlantModal.title')}
                </Styled.ModalTitle>
                <Styled.CircleContainer onClick={close}>
                  <CloseIcon width={10} height={10} />
                </Styled.CircleContainer>
              </>
            )}
          </Styled.HeaderSection>

          <Styled.Hr />

          {selectedPod && (
            <>
              <Styled.SubTitle>
                {t('addPlantModal.selectPlant')}
              </Styled.SubTitle>
              <Styled.SearchContainer>
                <Styled.SearchInput
                  value={search}
                  onChange={handleSearchChange}
                />
                <SearchIcon width={16} height={17} />
              </Styled.SearchContainer>
              {pods.length > 0 && (
                <TitledField title="Recently planted">
                  {pods.map((pod) => {
                    const crop = pod.plant.crop;
                    return (
                      <SearchItem
                        key={crop.uuid + pod.number}
                        text={crop.name}
                        onClick={handleAddPod(crop.uuid)}
                        icon={
                          <Styled.SearchItemIcon
                            src={imageWithToken(crop.icon, token)}
                          />
                        }
                      />
                    );
                  })}
                </TitledField>
              )}
              {searchLoading ? (
                <Styled.LoaderContainer>
                  <Loader />
                </Styled.LoaderContainer>
              ) : (
                <Styled.SearchResultsContainer>
                  {searchResults &&
                    searchResults.map((crop) => {
                      return (
                        <SearchItem
                          key={crop.uuid}
                          text={crop.name}
                          onClick={handleAddPod(crop.uuid)}
                          icon={
                            <Styled.SearchItemIcon
                              src={imageWithToken(crop.icon, token)}
                            />
                          }
                        />
                      );
                    })}
                </Styled.SearchResultsContainer>
              )}
            </>
          )}
          {!selectedPod && (
            <>
              <Styled.Unit>
                {Array.from({ length: PODS_COUNT }).map((_, index) => (
                  <Styled.Pod isSelected={open === index + 1}>
                    {index + 1}
                  </Styled.Pod>
                ))}
              </Styled.Unit>
              <Styled.SubTitle>{t('addPlantModal.selectPod')}</Styled.SubTitle>
              <Styled.PodsContainer>
                {Array.from({ length: PODS_COUNT }).map((_, index) => {
                  if (!pods.find((pod) => pod.number === index + 1)) {
                    return (
                      <Accordion
                        key={index}
                        handleChange={handleChange(index + 1)}
                        expanded={open === index + 1}
                        onClick={handleSelectPod(index + 1)}
                        title={t('addPlantModal.plantPod', {
                          index: index + 1,
                        })}
                      />
                    );
                  }
                  return null;
                })}
              </Styled.PodsContainer>
            </>
          )}
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const usePlantModal = createModalHook<AddPlantModalProps>(
  (props) => () => <AddPlantModal {...props} />,
  ModalContainer.Dashboard
);

export default usePlantModal;
