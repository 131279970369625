import { styled, Switch } from '@plant/ui';

export const Container = styled('div')({
  maxWidth: 550,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const Title = styled('h4')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 22,
  lineHeight: '22px',
  color: theme.palette.green.dark,
  margin: 0,
  marginBottom: 14,
}));

export const Hr = styled('hr')(({ theme }) => ({
  border: 'none',
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderTopColor: theme.palette.light.dark,
  width: '100%',
  margin: 0,
  opacity: 0.3,
  marginBottom: 50,
}));

export const StyledSwitch = styled(Switch)({
  width: '100%',
});

export const Form = styled('form')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  marginTop: 20,
});
