import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Product } from '@plant/data';

export enum StoreTypes {
  RequestProducts = '[STORE] RequestProducts',
  RequestProductsSuccess = '[STORE] RequestProductsSuccess',
  RequestProductsFailed = '[STORE] RequestProductsFailed',
}

export const requestProductsAction = createAsyncAction(
  StoreTypes.RequestProducts,
  StoreTypes.RequestProductsSuccess,
  StoreTypes.RequestProductsFailed,
)<{ page: number; limit: number }, { products: Product[] }, string>();

export type StoreActionUnion = ActionType<typeof requestProductsAction>;
