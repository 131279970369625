import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Navigator } from '@plant/data';
import * as Styled from './styles';
import Loader from '../../core/components/Loader';
import { socialAuthAction } from '../../store/actions/auth';
import { AppRoutes } from '../../core/constants/appRoutes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Social = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      dispatch(socialAuthAction.request({ token }));
    } else {
      Navigator.push(AppRoutes.SignIn);
      toast(`${t('auth.notifications.something')}`, { type: 'error' });
      dispatch(socialAuthAction.failure(t('auth.notifications.something')));
    }
  }, [searchParams, dispatch]);

  return (
    <Styled.Container>
      <Loader />
    </Styled.Container>
  );
};

export default Social;
