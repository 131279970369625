import { t as TFunction } from 'i18next';
import * as yup from 'yup';
import { getPasswordSchema } from './yupSchemas';

export const changePasswordSchema = (t: typeof TFunction) =>
  yup.object().shape({
    password: yup.string().required(t('errorMessages.passwordIsRequired')),
    newPassword: getPasswordSchema(t),
    repeatNewPassword: getPasswordSchema(t)
      .oneOf([yup.ref('newPassword')], t('errorMessages.passDontMatch'))
      .required(),
  });
