import { call, Effect, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  createModalAction,
  removeModalAction,
  showModalAction,
} from '../actions/modals';
import { ModalsService } from '../../core/services/modals';

class ModalsSagaWorker {
  static *createModal({ payload }: ActionType<typeof createModalAction>) {
    ModalsService.addModal(
      payload.id,
      payload.modal,
      payload.options,
      payload.container
    );
    yield put(
      showModalAction({
        id: payload.id,
      })
    );
  }

  static *removeModal({ payload }: ActionType<typeof removeModalAction>) {
    yield call(ModalsService.removeModal, payload.id);
  }
}

export function* modalsSaga(): Generator<Effect, void> {
  yield takeEvery(createModalAction, ModalsSagaWorker.createModal);
  yield takeEvery(removeModalAction, ModalsSagaWorker.removeModal);
}
