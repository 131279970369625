import { FC, memo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  createModalAction,
  removeModalAction,
} from '../../store/actions/modals';
import { ModalContainer } from '../constants/modalContainer';
import { ModalInstance, ModalOptions } from '../services/modals';

export const createModalHook = <T extends ModalOptions>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props?: T) => FC<any>,
  container: ModalContainer = ModalContainer.Root,
  options?: ModalInstance['options']
) =>
  function useModal(): [
    (props?: Omit<T, keyof ModalOptions>) => void,
    () => void
  ] {
    const dispatch = useDispatch();
    const idRef = useRef<string>();

    const hideModal = () => {
      if (idRef.current) {
        dispatch(removeModalAction({ id: idRef.current }));
      }
    };

    const showModal = (props: Omit<T, keyof ModalOptions> = {} as T) => {
      idRef.current = uuidv4();
      dispatch(
        createModalAction({
          id: idRef.current,
          container,
          modal: memo(
            component({
              ...props,
              close: hideModal,
            } as T)
          ),
          options,
        })
      );
    };

    return [showModal, hideModal];
  };
