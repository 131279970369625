import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import {
  Button,
  ButtonsContainer,
  SectionContainer,
  SectionText,
  SectionTextWrapper,
  SectionTitle,
} from '../../styles';

interface NutritionProps {
  vitamins: {
    uuid: string;
    name: string;
    description: string;
  }[];
}

const Nutrition: React.FC<NutritionProps> = ({ vitamins }) => {
  const { t } = useTranslation();
  const [selectedInex, setSelectedIndex] = useState(0);

  const handleSelect = (index: number) => () => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(0);
  }, [vitamins]);

  return (
    <SectionContainer>
      <SectionTitle>{t('cropGuide.nutrition')}</SectionTitle>
      <ButtonsContainer>
        {vitamins.map(({ name, uuid }, index) => {
          return (
            <Button
              key={uuid + 'btn'}
              onClick={handleSelect(index)}
              isSelected={index === selectedInex}
            >
              {name}
            </Button>
          );
        })}
      </ButtonsContainer>
      <SwipeableViews index={selectedInex}>
        {vitamins.map(({ description, uuid }) => {
          return (
            <SectionTextWrapper key={uuid + 'btn'}>
              <SectionText>{description}</SectionText>
            </SectionTextWrapper>
          );
        })}
      </SwipeableViews>
    </SectionContainer>
  );
};

export default Nutrition;
