import { Slider, Tooltip } from '@mui/material';
import { styled } from '@plant/ui';
import BackgroundImage from '../../../assets/images/timelapse/arrows.png';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px 20px 20px 20px',
  marginBottom: 10,
  borderRadius: 15,
  backgroundColor: theme.palette.light.main,
  display: 'flex',
  flexDirection: 'column',
}));

export const HeaderSection = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
}));

export const Title = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '16px',
  color: theme.palette.secondary.main,
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
  height: 'min-content',

  '& svg': {
    fill: '#E09870',
  },
}));

export const CustomSlider = styled(Slider)({
  color: '#E7EDEB',
  '& .MuiSlider-markLabel': {
    marginTop: 5,
    color: '#A7B2AE',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '10px',
  },
  '& .MuiSlider-mark': {
    height: 17,
    width: 1,
    backgroundColor: 'rgba(167, 178, 174, 0.35)',
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: '#99C6B0',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: '#99C6B0',
    width: 28,
    height: 28,
    borderRadius: 10,
    boxShadow: '0px 6px 14px rgba(153, 198, 176, 0.31)',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  '& .MuiSlider-valueLabel': {
    backgroundColor: '#5B806E',
    borderRadius: 5,
  },
});

export const SliderContainer = styled('div')({
  width: '100%',
  padding: '0px 10px',
});
