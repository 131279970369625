import { KeyboardEventHandler, MouseEventHandler } from 'react';
import { ISlide } from './type';
import Background from '../../../../../assets/images/auth/background.png';
import Background2 from '../../../../../assets/images/auth/background2.png';
import Background3 from '../../../../../assets/images/auth/background3.png';
import * as Styled from './styles';

export const config = {
  showThumbs: false,
  showStatus: false,
  infiniteLoop: true,
  autoPlay: true,
  transitionTime: 1000,
  interval: 3000,
  showArrows: false,
  renderIndicator: (
    onClickHandler:
      | MouseEventHandler<HTMLLIElement>
      | KeyboardEventHandler<HTMLLIElement>
      | undefined,
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    if (isSelected) {
      return (
        <Styled.Indicator
          isSelected={isSelected}
          aria-label={`Selected: ${label} ${index + 1}`}
          title={`Selected: ${label} ${index + 1}`}
        />
      );
    }
    return (
      <Styled.Indicator
        value={index}
        key={index}
        role="button"
        tabIndex={0}
        title={`${label} ${index + 1}`}
        aria-label={`${label} ${index + 1}`}
      />
    );
  },
};

export const slides: ISlide[] = [
  {
    id: 1,
    title: 'auth.carousel.firstItem.title',
    text: 'auth.carousel.firstItem.text',
    src: Background,
  },
  {
    id: 2,
    title: 'auth.carousel.firstItem.title',
    text: 'auth.carousel.firstItem.text',
    src: Background2,
  },
  {
    id: 3,
    title: 'auth.carousel.firstItem.title',
    text: 'auth.carousel.firstItem.text',
    src: Background3,
  },
];
