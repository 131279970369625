import React, { useEffect, useRef, useState } from 'react';
import { ArrowIcon } from '@plant/ui';
import ListItem from '../../../../core/components/ListItem';
import * as Styled from './styles';
import { TitledSelectProps } from './types';

const TitledSelect: React.FC<TitledSelectProps> = ({
  title,
  data,
  selectedItemId,
  query,
  selectHandler,
  handleSearch,
  active = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
      handleSearch('');
    }
  }, [isOpen]);

  const toggleIsOpen = () => {
    if (active) setIsOpen((value) => !value);
  };

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    handleSearch(event.currentTarget.value);
  };

  const clickHandler = (uuid: string) => () => {
    toggleIsOpen();
    selectHandler(uuid);
  };

  return (
    <Styled.Wrapper>
      <Styled.CustomTitledField
        isOpen={isOpen}
        isActive={active}
        title={title}
        onClick={toggleIsOpen}
        rightIcon={<ArrowIcon width={10} height={10} />}
      >
        <Styled.Input
          value={!isOpen ? data.find((item) => item.uuid === selectedItemId)?.name : query}
          disabled={!isOpen}
          onChange={onChange}
          ref={inputRef}
        />
      </Styled.CustomTitledField>
      {isOpen && (
        <Styled.Popup>
          <Styled.Hr />
          <Styled.PopupContainer>
            {data
              .filter((item) => item.uuid !== selectedItemId)
              // .filter((item) => item.name.includes(query))
              .map((item) => (
                <ListItem
                  key={item.uuid}
                  text={item.name}
                  onClick={clickHandler(item.uuid)}
                  textAlign="left"
                />
              ))}
          </Styled.PopupContainer>
        </Styled.Popup>
      )}
    </Styled.Wrapper>
  );
};

export default TitledSelect;
