import { Accordion, AccordionDetails } from '@mui/material';
import { styled } from '@plant/ui';

export const StyledAccordion = styled(Accordion)({
  borderRadius: '15px !important',
  boxShadow: 'none',
  marginBottom: 10,
  width: '100%',

  '&::before': {
    display: 'none',
  },
});

export const AccordionHeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SecondaryMainIcon = styled('div')(({ theme }) => ({
  marginRight: 10,
  '& svg': {
    fill: theme.palette.secondary.main,
  },
}));

export const ArrowWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: 29,
  height: 29,
  borderRadius: '50%',
  background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',

  '& svg': {
    fill: theme.palette.secondary.main,
  },
}));

export const AccordionTitle = styled('h4')(({ theme }) => ({
  color: theme.palette.green.dark,
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '20px',

  margin: 0,
}));

export const Arrow = styled('div')({
  border: '2px solid #2A5C44',
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
});

export const AccordionBodyContainer = styled('p')(({ theme }) => ({
  margin: 0,
  color: theme.palette.green.dark,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '20px',
}));

export const StyledAccordionDetails = styled(AccordionDetails)({
  paddingTop: 0,
});
