import { styled } from '@plant/ui';

export const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected = false }) => ({
  width: 230,
  padding: '10px 25px',
  borderRadius: 8,
  backgroundColor: isSelected
    ? theme.palette.green.main
    : theme.palette.admin.main,
  border: 'none',
  outline: 'none',
  boxShadow: isSelected
    ? '0px 16px 19px -10px rgba(60, 151, 107, 0.28)'
    : 'none',

  fontWeight: isSelected ? 600 : 500,
  fontSize: 13,
  lineHeight: '20px',
  color: theme.palette.secondary.main,

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    fill: isSelected
      ? theme.palette.green.contrastText
      : theme.palette.secondary.main,
  },
  '& span': {
    color: isSelected && theme.palette.secondary.main,
  },
}));

export const IconContainer = styled('div')({
  marginRight: 'auto',
});

export const TextContainer = styled('div')({
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  alignItems: 'center',
});

export const Time = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '18px',
  color: theme.palette.light.dark,
}));
