import { styled, ScrollBarStyles } from '@plant/ui';

export const AuthLayoutWrapper = styled('div')`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const AuthLayoutWrapperLeft = styled('div')({
  width: '100%',
  backgroundColor: '#F0F5F4',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 'min(60px, 5%)',
  overflowY: 'scroll',
  ...ScrollBarStyles,
});

export const AuthContainerLeft = styled('div')`
  max-width: 405px;
  width: 100%;
`;

export const AuthLayoutWrapperLogo = styled('div')`
  margin-bottom: 50px;
`;

export const AuthLayoutWrapperChildrenContainer = styled('div')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuthLayoutWrapperRight = styled('div')`
  width: 45%;
  min-width: 350px;
  max-width: 600px;
`;

export const AuthLayoutWrapperCarouselWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
