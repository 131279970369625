import { styled } from '@plant/ui';

export const ClearPlantInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'unActive',
})<{ unActive: boolean }>(({ theme, unActive }) => ({
  borderRadius: '50%',
  width: 80,
  height: 80,
  backgroundColor: theme.palette.light.main,
  border: '3.6px solid rgba(191, 201, 195, 0.4)',

  boxShadow: '0px 7.29368px 15.803px rgba(51, 83, 56, 0.28)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  cursor: unActive ? 'default' : 'pointer',

  '& svg': {
    fill: '#B1BBB7',
  },
}));
