import { FC } from 'react';
import { LogoIcon } from '@plant/ui';
import TextCarousel from './components/TextCarousel';
import {
  AuthLayoutWrapper,
  AuthLayoutWrapperLeft,
  AuthLayoutWrapperLogo,
  AuthLayoutWrapperChildrenContainer,
  AuthLayoutWrapperRight,
  AuthLayoutWrapperCarouselWrapper,
  AuthContainerLeft,
} from './styles';

interface IProps {
  children: JSX.Element;
}

const AuthLayout: FC<IProps> = ({ children }): JSX.Element => {
  return (
    <AuthLayoutWrapper>
      <AuthLayoutWrapperLeft>
        <AuthContainerLeft>
          <AuthLayoutWrapperLogo>
            <LogoIcon width={124} height={31} />
          </AuthLayoutWrapperLogo>
          <AuthLayoutWrapperChildrenContainer>
            {children}
          </AuthLayoutWrapperChildrenContainer>
        </AuthContainerLeft>
      </AuthLayoutWrapperLeft>
      <AuthLayoutWrapperRight>
        <AuthLayoutWrapperCarouselWrapper>
          <TextCarousel />
        </AuthLayoutWrapperCarouselWrapper>
      </AuthLayoutWrapperRight>
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
