import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../core/components/Loader';
import TitledSelect from './components/TitledSelect';
import {
  changeEnvironmentAction,
  deleteEnvironmentAction,
} from '../../../../store/actions/environments';
import {
  environmentsDataSelector,
  environmentsLoadingSelector,
} from '../../../../store/selectors/environments';
import {
  activeSelector,
  selectedUnitEnvironmentIdSelector,
  selectedUnitIdSelector,
} from '../../../../store/selectors/units';
import * as Styled from './styles';
import { DEFAULT_ENVIRONMENT_UUID } from '../../../../core/constants/defaultEnvironmentUuid';
import useCustomEnvironmentModal from '../../../../core/components/modals/CustomEnvironment';
import { useEnvironment } from '../../../../core/hooks/useEnvironment';
import useConfirmModal from '../../../../core/components/modals/Confirm';

const Environment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCustomEnvironmentModal] = useCustomEnvironmentModal();
  const [showConfirmModal] = useConfirmModal();
  const environment = useEnvironment();
  const selectedUnitId = useSelector(selectedUnitIdSelector);
  const selectedEnvironmentId = useSelector(selectedUnitEnvironmentIdSelector);
  const environments = useSelector(environmentsDataSelector);
  const loading = useSelector(environmentsLoadingSelector);
  const active = useSelector(activeSelector);

  const handleDelete = (uuid: string) => () => {
    dispatch(
      deleteEnvironmentAction.request({
        environmentUuid: uuid,
      }),
    );
  };

  const selectHandler = (uuid: string) => {
    dispatch(
      changeEnvironmentAction.request({
        unitUuid: selectedUnitId,
        environmentUuid: uuid,
      }),
    );
  };

  const deleteHandler = (uuid: string) => {
    showConfirmModal({
      title: t('environment.removeEnvironmentModal.title'),
      text: t('environment.removeEnvironmentModal.text'),
      onAccept: handleDelete(uuid),
    });
  };

  const handleClick = () => {
    if (active) showCustomEnvironmentModal();
  };

  if (loading) {
    return (
      <Styled.LoaderContainer>
        <Loader />
      </Styled.LoaderContainer>
    );
  }

  return (
    <Styled.EnvironmentContainer>
      <TitledSelect
        title={t('environment.select')}
        selectedItemId={selectedEnvironmentId}
        selectHandler={selectHandler}
        deleteHandler={deleteHandler}
        data={environments}
        active={active}
      />
      <Styled.EnvironmentDescription>
        {environment
          ? environment?.description
          : environments.find((item) => item.uuid === DEFAULT_ENVIRONMENT_UUID)?.description}
      </Styled.EnvironmentDescription>
      <Styled.CustomizeButton
        disabled={!active}
        title={t('environment.customizeEnvironment')}
        variant="contained"
        onClick={handleClick}
      />
    </Styled.EnvironmentContainer>
  );
};

export default React.memo(Environment);
