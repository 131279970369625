import { createReducer } from 'typesafe-actions';
import { Crop } from '@plant/data';
import {
  clearCropsAction,
  searchCropsAction,
  SearchCropsActionUnion,
} from '../actions/searchCrops';

export interface State {
  keys: string[];
  crops: {
    [key in string]: Crop;
  };
  loading: boolean;
  error: string;
}

export const initialState: State = {
  keys: [],
  crops: {},
  loading: false,
  error: null,
};

export const reducer = createReducer<State, SearchCropsActionUnion>(
  initialState
)
  .handleAction(searchCropsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(searchCropsAction.success, (state, action) => ({
    ...state,
    keys: action.payload.crops.map((crop) => crop.uuid),
    crops: {
      ...state.crops,
      ...action.payload.crops.reduce((acc, crop) => {
        acc[crop.uuid] = crop;
        return acc;
      }, {}),
    },
    loading: false,
  }))
  .handleAction(searchCropsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(clearCropsAction, () => initialState);
