import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, CloseIcon, Input } from '@plant/ui';
import { Slide } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Styled from './styles';
import { ModalContainer } from '../../../constants/modalContainer';
import { createModalHook } from '../../../helpers/create-modal-hook.helper';
import { deleteUnitAction } from '../../../../store/actions/units';
import { useForm } from 'react-hook-form';
import { ChangePasswordFormValues } from './types';
import { changePasswordSchema } from '../../../yupShemas/changePassword';
import { useMemo } from 'react';
import ErrorAlert from '../../ErrorAlert';
import { formErrors } from '../../../helpers/form-errors';
import {
  authErrorSelector,
  authLoadingSelector,
  authTokenSelector,
} from '../../../../store/selectors/auth';
import { clearAuthErrorAction, setNewPasswordAction } from '../../../../store/actions/auth';
import { ModalOptions } from '../../../services/modals';

const ChangePasswordModal: React.FC<ModalOptions> = ({ close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const memoizedChangePasswordSchema = useMemo(() => changePasswordSchema(t), [t]);
  const { register, handleSubmit, formState } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(memoizedChangePasswordSchema),
  });
  const token = useSelector(authTokenSelector);
  const error = useSelector(authErrorSelector);
  const loading = useSelector(authLoadingSelector);

  const errors = formState.errors;
  const arrayErrors = formErrors(errors);

  const onSubmit = ({ newPassword }: ChangePasswordFormValues) => {
    dispatch(clearAuthErrorAction());
    dispatch(setNewPasswordAction.request({ newPassword, token }));
  };

  return (
    <Styled.StyledModal open onClose={close}>
      <Slide in direction="down">
        <Styled.Container>
          <Styled.CloseContainer>
            <Styled.Close onClick={close}>
              <CloseIcon width={14} height={14} />
            </Styled.Close>
          </Styled.CloseContainer>
          <Styled.BodyContainer>
            <Styled.ModalTitle>{t('changePasswordModal.title')}</Styled.ModalTitle>
            <Styled.FieldsContainer onSubmit={handleSubmit(onSubmit)}>
              <Input
                type="password"
                title={t('changePasswordModal.firstField')}
                isInvalid={Boolean(errors.password)}
                {...register('password')}
              />
              <Input
                type="password"
                title={t('changePasswordModal.secondField')}
                isInvalid={Boolean(errors.newPassword)}
                {...register('newPassword')}
              />
              <Input
                type="password"
                title={t('changePasswordModal.thirdField')}
                isInvalid={Boolean(errors.repeatNewPassword)}
                {...register('repeatNewPassword')}
              />
              <Styled.ButtonContainer>
                <Button
                  maxWidth={250}
                  variant="contained"
                  title={t('changePasswordModal.button')}
                  type="submit"
                />
              </Styled.ButtonContainer>
              {(Boolean(error) || arrayErrors.length > 0) && (
                <ErrorAlert variant="error" text={error ? error : arrayErrors[0]} />
              )}
              {formState.isSubmitSuccessful && !loading && !error && arrayErrors.length === 0 && (
                <ErrorAlert variant="success" text={t('auth.newPassword.submitMessage')} />
              )}
            </Styled.FieldsContainer>
          </Styled.BodyContainer>
        </Styled.Container>
      </Slide>
    </Styled.StyledModal>
  );
};

const useChangePasswordModal = createModalHook<ModalOptions>(
  (props) => () => <ChangePasswordModal {...props} />,
  ModalContainer.Root,
);

export default useChangePasswordModal;
