import { styled, ScrollBarStyles } from '@plant/ui';
import { Lines } from './data';
import Canvas from './components/Canvas';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.light.main,
  borderRadius: 15,
  padding: '30px 40px 50px',
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
}));

export const InfoSection = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 30,
}));

export const InfoBox = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
});

export const InfoLabelContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 6,
});

export const InfoLabel = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '14px',
  color: theme.palette.brown.main,
}));

export const InfoTime = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '18px',
  color: theme.palette.secondary.main,
  cursor: 'pointer',
}));

export const OffLabel = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '14px',
  color: theme.palette.error.main,
}));

export const OffTime = styled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: 15,
  lineHeight: '20px',
  color: theme.palette.light.main,

  padding: '3px 10px',
  backgroundColor: theme.palette.error.main,
  borderRadius: 80,
}));

export const EmptyInfoBox = styled('div')({
  width: 100,
});

export const YellowIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  '& svg': {
    fill: theme.palette.yellow.main,
  },
}));

export const Hr = styled('hr')(({ theme }) => ({
  width: '100%',
  border: 'none',
  borderTop: `1px solid ${theme.palette.light.light}`,
  opacity: 0.25,
  margin: 0,
  marginBottom: 30,
}));

export const StyledCanvas = styled(Canvas)({
  width: '100%',
  height: 375,
});

export const CanvasWrapper = styled('div')({
  position: 'relative',
});

export const Progress = styled('div')<{
  positionLeft: number;
  positionRight: number;
}>(({ theme, positionLeft, positionRight }) => ({
  height: 50,
  width: positionRight - positionLeft,
  borderRadius: 200,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: positionLeft,
  right: positionRight,
}));

export const SunProgress = styled(Progress)(({ theme }) => ({
  backgroundColor: theme.palette.yellow.main,
  top: Lines.sun,
  boxShadow: '0px 4px 14px rgba(255, 209, 92, 0.6)',
  padding: 4,

  '& svg': {
    fill: '#A07300',
  },
}));

export const Thumb = styled('div')(({ theme }) => ({}));

export const SecondSunProgress = styled(Progress)(({ theme }) => ({
  backgroundColor: theme.palette.yellow.light,
  top: Lines.sun,

  '& svg': {
    fill: theme.palette.brown.light,
    opacity: 0.3,
  },
}));

export const OffSunProgress = styled(Progress)(({ theme }) => ({
  backgroundColor: theme.palette.gray.main,
  top: Lines.offSun,

  '& svg': {
    fill: theme.palette.gray.contrastText,
  },
}));

export const TimeLine = styled('div')<{ position: number }>(
  ({ theme, position }) => ({
    width: 2,
    height: 340,
    backgroundColor: theme.palette.yellow.dark,
    borderRadius: 1,

    position: 'absolute',
    top: 20,
    left: position,
  })
);

export const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 15,
  marginTop: 50,
});

export const ButtonSubText = styled('span')(({ theme }) => ({
  color: theme.palette.light.light,
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '20px',
}));

export const BoxesContainer = styled('div')({
  width: '100%',
  marginTop: 30,

  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 20,
});

export const Box = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: 15,
  backgroundColor: theme.palette.light.main,
  padding: 25,

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const BoxTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
}));

export const BoxScenario = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 16,
  color: theme.palette.secondary.main,
}));

export const BoxScenarioDesc = styled('span')(({ theme }) => ({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '18px',
  color: theme.palette.light.light,
}));

export const TimePopup = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '110%',
  left: 0,
  padding: '10px 0',
  backgroundColor: theme.palette.light.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
  maxHeight: 330,
  overflowY: 'scroll',
  boxShadow: `5px 5px 20px ${theme.palette.light.light}`,
  ...ScrollBarStyles,
}));

export const TimePopupItem = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '10px 50px',
  fontWeight: '600',
  fontSize: 18,
  cursor: 'pointer',
  color: theme.palette.light.light,
  transition: 'all .1s linear',
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.gray.light,
  },
}));

export const Backdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
}));
