import { ApiService, Navigator, LOCAL_STORAGE_KEYS, UserService } from '@plant/data';
import { takeLatest, Effect, put, call, SagaReturnType } from 'redux-saga/effects';
import * as AppActions from '../actions/app';
import { environment } from '../../environments/environment';
import { loginAction } from '../actions/auth';
import { AppRoutes } from '../../core/constants/appRoutes';

export class StartAppSagaWorker {
  static *startApp() {
    try {
      yield ApiService.init(environment.BASE_URL);
      const token = localStorage.getItem(LOCAL_STORAGE_KEYS.token);

      if (token) {
        yield ApiService.getToken(token);
        const data: SagaReturnType<typeof UserService.getUser> = yield call(UserService.getUser);
        data.token = token;
        yield put(loginAction.success({ data }));
      }

      yield put(AppActions.startAppAction.success());
    } catch (error) {
      yield put(AppActions.startAppAction.failure());
    }
  }
}

export function* startAppSaga(): Generator<Effect, void> {
  yield takeLatest(AppActions.startAppAction.request, StartAppSagaWorker.startApp);
}
