import React from 'react';
import { VerticalLogoIcon, SettingsIcon, StoreIcon } from '@plant/ui';
import * as Styled from './styles';
import { navBarItems } from './data';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from '../../constants/appRoutes';
import UserAvatar from '../UserAvatar';
import { STORE_PATH } from '../../constants/storePath';

export const NavigationBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.NavBar>
      <Styled.LogoWrapper>
        <VerticalLogoIcon width={40} height={40} />
      </Styled.LogoWrapper>
      <Styled.NavContainer>
        {navBarItems.map((item) => {
          return (
            <Styled.Item to={item.path} key={item.path}>
              <item.icon width={30} height={30} />
              <Styled.ItemLabel>{t(item.label)}</Styled.ItemLabel>
            </Styled.Item>
          );
        })}
        <Styled.NavigateItem href={STORE_PATH} target="_blank">
          <StoreIcon width={30} height={30} />
          <Styled.ItemLabel>{t('navbar.storeLabel')}</Styled.ItemLabel>
        </Styled.NavigateItem>
      </Styled.NavContainer>
      <Styled.SettingsContainer>
        <Styled.SettingsLink to={AppRoutes.Settings}>
          <SettingsIcon width={26} height={26} />
        </Styled.SettingsLink>
        <UserAvatar />
      </Styled.SettingsContainer>
    </Styled.NavBar>
  );
};
