import { Modal } from '@mui/material';
import { ScrollBarStyles, styled } from '@plant/ui';
import Slider from '../../Slider';
import { containerPadding } from './data';

const imageHeight = 85;

export const StyledModal = styled(Modal)({
  zIndex: 69,
});

export const Container = styled('div')({
  backgroundColor: '#F0F5F4',
  padding: 40,
  paddingTop: 16,
  paddingRight: 20,
  width: 920,
  height: 'min(760px, 90vh)',
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  margin: 'auto',
  borderRadius: 15,
  boxShadow: '0px 14px 44px rgba(36, 47, 45, 0.42)',
  outline: 'none',
});

export const CloseContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  marginBottom: 10,
});

export const InnerContainer = styled('div')({
  paddingRight: 20,
  paddingBottom: 20,
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  ...ScrollBarStyles,
});

export const ModalTitle = styled('h1')(({ theme }) => ({
  justifySelf: 'center',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '20px',
  margin: 0,
  marginLeft: 20,
  marginBottom: 20,
  marginRight: 'auto',
  color: theme.palette.secondary.main,
}));

export const ImageWrapper = styled('div')({
  width: '100%',
  borderRadius: 15,
  maxHeight: 370,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

export const MainImage = styled('img')({
  width: '100%',
  userSelect: 'none',
});

export const CustomSlider = styled(Slider)({
  marginTop: 10,
});

export const CropContainer = styled('div')(({ theme }) => ({
  borderRadius: 15,
  boxShadow: '0px 9px 24px #E1EBE9',
  padding: containerPadding,
  marginTop: 70,
  backgroundColor: theme.palette.light.main,
  position: 'relative',
  height: imageHeight + containerPadding * 2,
}));

export const CropImageContainer = styled('div')<{ width: number }>(
  ({ theme, width }) => ({
    width: width,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  })
);

export const CropImage = styled('img')(({ theme }) => ({
  height: '100%',
  userSelect: 'none',
}));

export const Thumb = styled('div', {
  shouldForwardProp: (prop) => prop !== 'position',
})<{ position: number }>(({ theme, position }) => ({
  width: 15,
  height: imageHeight + 5,
  position: 'absolute',
  backgroundColor: '#0F896C',
  left: position,
  top: containerPadding - 2.5,
  // transition: 'all .2s ease-out',
  zIndex: 3,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '& svg': {
    fill: theme.palette.light.main,
    stroke: theme.palette.light.main,
  },
}));

export const LeftThumb = styled(Thumb)({
  borderRadius: '10px 0 0 10px',
  '& svg': {
    transform: 'rotate(90deg)',
  },
});

export const RightThumb = styled(Thumb)({
  borderRadius: '0 10px 10px 0',
  '& svg': {
    transform: 'rotate(-90deg)',
  },
});

export const Backdrop = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'leftPosition' && prop !== 'rightPosition',
})<{
  leftPosition: number;
  rightPosition: number;
}>(({ leftPosition, rightPosition }) => ({
  width: rightPosition - leftPosition + 10,
  borderRadius: 10,
  height: imageHeight + 5,
  position: 'absolute',
  backgroundColor: '#0F896C',
  left: leftPosition,
  right: rightPosition,
  top: containerPadding - 2.5,

  zIndex: 1,
  // transition: 'all .2s ease-out',
}));

export const LeftBlur = styled('div', {
  shouldForwardProp: (prop) => prop !== 'position',
})<{ position: number }>(({ theme, position }) => ({
  height: 85,
  position: 'absolute',
  borderRadius: 15,
  left: 0,
  right: 809 - position + 15,
  backgroundColor: theme.palette.light.main,
  opacity: 0.5,
  zIndex: 2,
}));

export const RightBlur = styled('div', {
  shouldForwardProp: (prop) => prop !== 'position',
})<{ position: number }>(({ theme, position }) => ({
  height: 85,
  position: 'absolute',
  borderRadius: 15,
  left: position + 15,
  right: 0,
  backgroundColor: theme.palette.light.main,
  opacity: 0.5,
  zIndex: 2,
}));

export const CropInnerContainer = styled('div')(({ theme }) => ({
  borderRadius: 10,
  height: imageHeight,
  overflow: 'hidden',
  display: 'flex',
  position: 'absolute',
  userSelect: 'none',
  zIndex: 2,
}));

export const DateToolip = styled('div', {
  shouldForwardProp: (prop) => prop !== 'position',
})<{ position: number }>(({ theme, position }) => ({
  borderRadius: 10,
  padding: '7px 25px 10px',
  backgroundColor: '#0F896C',
  color: theme.palette.light.main,
  fontWeight: 500,
  fontSize: 12,
  userSelect: 'none',

  position: 'absolute',
  bottom: '110%',
  left: position < 10 ? 10 : position > 700 ? 700 : position,

  '&:after': {
    content: "'f'",
    color: 'rgba(0,0,0,0)',
    width: 12,
    height: 12,
    backgroundColor: '#0F896C',
    top: 'calc(100% - 9px)',
    left: 'calc(50% - 6px)',
    position: 'absolute',
    transform: 'rotate(45deg)',
  },
}));

export const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 40,
  marginBottom: 20,
});

export const LoadingContainer = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: 15,
  backgroundColor: theme.palette.light.main,
  padding: '200px 20px',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
}));

export const LoadingText = styled('span')(({ theme }) => ({
  fontSize: 24,
  fontWeight: 500,
  color: '#455458',
}));

export const Video = styled('video')({
  width: '100%',
  borderRadius: 15,
});

export const ShareText = styled('span')(({ theme }) => ({
  marginRight: 4,
  fontWeight: 600,
  fontSize: 16,
  color: theme.palette.secondary.main,
}));

export const SocialContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

export const SaveContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 40,
});
