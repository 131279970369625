import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';

interface VideoProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
}

const Video: React.FC<VideoProps> = ({ src, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPause, setIsPause] = useState(false);
  const loaded = useRef(false);

  const pauseVideo = () => {
    setIsPause(false);
  };

  useEffect(() => {
    videoRef?.current?.addEventListener('ended', pauseVideo);

    return () => videoRef?.current?.removeEventListener('ended', pauseVideo);
  }, []);

  const toggleVideo = () => {
    if (!loaded.current) return;
    if (videoRef.current.paused) {
      setIsPause(true);
      videoRef.current.play();
    } else {
      setIsPause(false);
      videoRef.current.pause();
    }
  };

  const loadVideoCallback = () => {
    loaded.current = true;
  };

  return (
    <Styled.VideoWrapper {...props}>
      <Styled.VideoGuide onLoadedData={loadVideoCallback} ref={videoRef} controlsList="play">
        <source src={src} />
      </Styled.VideoGuide>
      <Styled.PlayButton onClick={toggleVideo}>
        <Styled.Triangle isPause={isPause} />
      </Styled.PlayButton>
    </Styled.VideoWrapper>
  );
};

export default Video;
