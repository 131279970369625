import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '@plant/ui';
import { forgotPasswordConfirmationSchema } from '../../core/yupShemas/forgotPasswordConfirmation';
import AuthLayout from '../../core/components/AuthLayout';
import {
  authErrorSelector,
  authLoadingSelector,
} from '../../store/selectors/auth';
import { Navigator } from '../../core/services/navigator';
import * as Styled from '../ResetPassword/styles';
import ErrorAlert from '../../core/components/ErrorAlert';
import Loader from '../../core/components/Loader';
import {
  clearAuthErrorAction,
  setNewPasswordAction,
} from '../../store/actions/auth';
import { useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../../core/constants/appRoutes';

interface NewPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const NewPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(authLoadingSelector);
  const error = useSelector(authErrorSelector);
  const [currentField, setCurrentField] = useState<string>('');

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const memoizedForgotPasswordConfirmationSchema = useMemo(
    () => forgotPasswordConfirmationSchema(t),
    [t]
  );

  const { register, handleSubmit, formState } = useForm<NewPasswordFormValues>({
    resolver: yupResolver(memoizedForgotPasswordConfirmationSchema),
  });

  const updateCurrentField = (fieldName: string) => () => {
    setCurrentField(fieldName);
  };

  const handleReset = ({ newPassword }: NewPasswordFormValues) => {
    dispatch(clearAuthErrorAction());
    dispatch(setNewPasswordAction.request({ newPassword, token }));
  };

  const redirectToSignIn = () => {
    Navigator.push(AppRoutes.SignIn);
  };

  const errorMessage = formState.errors[currentField]?.message || error;
  return (
    <AuthLayout>
      {formState.isSubmitSuccessful && !loading && !error ? (
        <Styled.SuccessResponse>
          <Styled.FormLabel>
            {t('auth.newPassword.submitMessage')}
          </Styled.FormLabel>
          <Button
            title={t('auth.newPassword.redirectButton')}
            variant="contained"
            onClick={redirectToSignIn}
          />
        </Styled.SuccessResponse>
      ) : (
        <Styled.Form>
          <Styled.FormLabel>{t('auth.newPassword.title')}</Styled.FormLabel>
          <Input
            title={t('auth.newPasswordPlaceholder')}
            isInvalid={!!formState.errors.newPassword?.message}
            type="password"
            handleFocus={updateCurrentField('newPassword')}
            {...register('newPassword')}
          />
          <Input
            title={t('auth.repeatNewPasswordPlaceholder')}
            isInvalid={!!formState.errors.confirmNewPassword?.message}
            type="password"
            handleFocus={updateCurrentField('confirmNewPassword')}
            {...register('confirmNewPassword')}
          />
          {errorMessage && <ErrorAlert variant="error" text={errorMessage} />}
          {!loading ? (
            <Styled.ButtonWrapper>
              <Button
                title={t('auth.newPassword.submitButton')}
                variant="contained"
                maxWidth={250}
                onClick={handleSubmit(handleReset)}
              />
            </Styled.ButtonWrapper>
          ) : (
            <Styled.LoaderWrapper>
              <Loader />
            </Styled.LoaderWrapper>
          )}
        </Styled.Form>
      )}
    </AuthLayout>
  );
};

export default NewPassword;
