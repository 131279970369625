import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { UserData } from '@plant/data';

export enum AuthTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] LoginSuccess',
  LoginFailed = '[Auth] LoginFailed',

  SignUp = '[Auth] SignUp',
  SignUpSuccess = '[Auth] SignUpSuccess',
  SignUpFailed = '[Auth] SignUpFailed',

  RestorePassword = '[Auth] RestorePassword',
  RestorePasswordSuccess = '[Auth] RestorePasswordSuccess',
  RestorePasswordFailed = '[Auth] RestorePasswordFailed',

  SetNewPassword = '[Auth] SetNewPassword',
  SetNewPasswordSuccess = '[Auth] SetNewPasswordSuccess',
  SetNewPasswordFailed = '[Auth] SetNewPasswordFailed',

  SocialAuth = '[Auth] SocialAuth',
  SocialAuthSuccess = '[Auth] SocialAuthSuccess',
  SocialAuthFailed = '[Auth] SocialAuthFailed',

  ChangeUserData = '[Auth] ChangeUserData',
  ChangeUserDataSuccess = '[Auth] ChangeUserDataSuccess',
  ChangeUserDataFailed = '[Auth] ChangeUserDataFailed',

  UploadAvatar = '[Auth] UploadAvatar',
  UploadAvatarSuccess = '[Auth] UploadAvatarSuccess',
  UploadAvatarFailed = '[Auth] UploadAvatarFailed',

  RemoveAvatar = '[Auth] RemoveAvatar',
  RemoveAvatarSuccess = '[Auth] RemoveAvatarSuccess',
  RemoveAvatarFailed = '[Auth] RemoveAvatarFailed',

  FacebookRedirect = '[Auth] FacebookRedirect',
  GoogleRedirect = '[Auth] GoogleRedirect',

  DeleteAccount = '[Auth] DeleteAccount',
  DeleteAccountSuccess = '[Auth] DeleteAccountSuccess',
  DeleteAccountFailed = '[Auth] DeleteAccountFailed',

  ClearAuthState = '[Auth] ClearAuthState',
  ClearError = '[Auth] ClearAuthError',
  Clear = '[Auth] Clear',
}

export const loginAction = createAsyncAction(
  AuthTypes.Login,
  AuthTypes.LoginSuccess,
  AuthTypes.LoginFailed,
)<{ email: string; password: string }, { data: UserData }, string>();

export const signUpAction = createAsyncAction(
  AuthTypes.SignUp,
  AuthTypes.SignUpSuccess,
  AuthTypes.SignUpFailed,
)<{ email: string; password: string; firstName: string; lastName: string }, void, string>();

export const restorePasswordAction = createAsyncAction(
  AuthTypes.RestorePassword,
  AuthTypes.RestorePasswordSuccess,
  AuthTypes.RestorePasswordFailed,
)<{ email: string }, void, string>();

export const setNewPasswordAction = createAsyncAction(
  AuthTypes.SetNewPassword,
  AuthTypes.SetNewPasswordSuccess,
  AuthTypes.SetNewPasswordFailed,
)<{ newPassword: string; token: string }, void, string>();

export const uploadAvatarAction = createAsyncAction(
  AuthTypes.UploadAvatar,
  AuthTypes.UploadAvatarSuccess,
  AuthTypes.UploadAvatarFailed,
)<{ body: FormData }, string, string>();

export const removeAvatarAction = createAsyncAction(
  AuthTypes.RemoveAvatar,
  AuthTypes.RemoveAvatarSuccess,
  AuthTypes.RemoveAvatarFailed,
)<{ uuid: string }, void, string>();

export const changeUserDataAction = createAsyncAction(
  AuthTypes.ChangeUserData,
  AuthTypes.ChangeUserDataSuccess,
  AuthTypes.ChangeUserDataFailed,
)<
  {
    firstName: string;
    lastName: string;
    city: string;
    country: string;
    street: string;
    token: string;
  },
  { userData: UserData },
  string
>();

export const socialAuthAction = createAsyncAction(
  AuthTypes.SocialAuth,
  AuthTypes.SocialAuthSuccess,
  AuthTypes.SocialAuthFailed,
)<{ token: string }, { data: UserData }, string>();

export const deleteAccountAction = createAsyncAction(
  AuthTypes.DeleteAccount,
  AuthTypes.DeleteAccountSuccess,
  AuthTypes.DeleteAccountFailed,
)<{ uuid: string }, void, string>();

export const facebookRedirectAction = createAction(AuthTypes.FacebookRedirect)<void>();
export const googleRedirectAction = createAction(AuthTypes.GoogleRedirect)<void>();

export const clearAuthErrorAction = createAction(AuthTypes.ClearError)<void>();

export const clearAuthAction = createAction(AuthTypes.Clear)<void>();
export const clearAuthStateAction = createAction(AuthTypes.ClearAuthState)<void>();

export type AuthActionUnion = ActionType<
  | typeof loginAction
  | typeof clearAuthAction
  | typeof signUpAction
  | typeof clearAuthErrorAction
  | typeof restorePasswordAction
  | typeof setNewPasswordAction
  | typeof socialAuthAction
  | typeof googleRedirectAction
  | typeof clearAuthStateAction
  | typeof changeUserDataAction
  | typeof uploadAvatarAction
  | typeof removeAvatarAction
  | typeof deleteAccountAction
>;
