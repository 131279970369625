import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Pod, dateConverter } from '@plant/data';
import { ArrowIcon, CropGuideIcon, CuttingIcon, RemoveIcon } from '@plant/ui';
import * as Styled from '../../styles';
import { authTokenSelector } from '../../../../../../store/selectors/auth';
import ListItem from '../../../../../../core/components/ListItem';
import { plantActionsDataSelector } from '../../../../../../store/selectors/plantActions';
import { imageWithToken } from '../../../../../../core/helpers/image-with-token.helper';
import usePlantModal from '../../../../../../core/components/modals/AddPlant';
import { deletePlantAction } from '../../../../../../store/actions/units';
import useConfirmModal from '../../../../../../core/components/modals/Confirm';
import { AppRoutes } from '../../../../../../core/constants/appRoutes';
import { useNavigate } from 'react-router-dom';

interface PodProps {
  pod: Pod;
  index: number;
}

const PodComponent: React.FC<PodProps> = ({ pod, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfirmModal] = useConfirmModal();
  const [showAddPlantModal] = usePlantModal();
  const token = useSelector(authTokenSelector);
  const actions = useSelector(plantActionsDataSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tooltipIsOpen, setTooltpIsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOpenModal = (actionUuid: string) => () => {
    toggleIsOpen();
    showAddPlantModal({
      data: {
        pod: index,
        actionUuid,
      },
      plantUuidForDelete: pod.plant.uuid,
    });
  };

  const onPlantRemove = () => {
    dispatch(deletePlantAction.request({ plantUuid: pod.plant.uuid }));
  };

  const handleViewCropGuide = () => {
    toggleIsOpen();
    navigate(`${AppRoutes.CropGuide}?cropId=${pod.plant.crop.uuid}`);
  };

  const handlePlantRemove = () => {
    showConfirmModal({
      onAccept: onPlantRemove,
      title: t('removeUnitModal.title'),
      text: t('removeUnitModal.text'),
    });
    toggleIsOpen();
  };

  const toggleTooltipIsOpen = () => {
    setTooltpIsOpen((value) => !value);
  };

  const toggleIsOpen = () => {
    setIsOpen((value) => !value);
  };

  return (
    <>
      <Styled.PlantContainer isOpen={isOpen}>
        <Styled.InfoTooltipButton
          onMouseOver={toggleTooltipIsOpen}
          onMouseOut={toggleTooltipIsOpen}
        >
          <CuttingIcon width={12} height={13} />
        </Styled.InfoTooltipButton>
        <Styled.PlantInner>
          <Styled.PlantImage alt="plant" src={imageWithToken(pod.plant.crop.photo, token)} />
        </Styled.PlantInner>
        <Styled.PopupButtonContainer>
          <Styled.PlantName>{pod.plant.crop.name}</Styled.PlantName>
          <Styled.PopupButton isOpen={isOpen} onClick={toggleIsOpen}>
            <ArrowIcon width={9} height={5} />
          </Styled.PopupButton>
        </Styled.PopupButtonContainer>
        {isOpen && (
          <Styled.Popup>
            <ListItem
              leftIcon={<CropGuideIcon width={12} height={8} />}
              text={t('unitPanel.popup.cropGuide')}
              onClick={handleViewCropGuide}
            />
            {actions &&
              actions.map((action) => {
                return (
                  <ListItem
                    key={action.uuid}
                    leftIcon={<img src={imageWithToken(action.image, token)} />}
                    text={action.name}
                    textAlign="center"
                    onClick={handleOpenModal(action.uuid)}
                  />
                );
              })}
            <ListItem
              leftIcon={<RemoveIcon width={14} height={12} />}
              text={t('unitPanel.popup.remove')}
              onClick={handlePlantRemove}
            />
          </Styled.Popup>
        )}
        {tooltipIsOpen && (
          <Styled.InfoTooltip>
            <Styled.InfoTooltipItem>
              <CuttingIcon width={16} height={17} />
              <Styled.InfoTooltipContentBox>
                <Styled.InfoTooltipLabel>
                  {t('unitPanel.dateOfInstallement')}
                </Styled.InfoTooltipLabel>
                <Styled.InfoTooltipValue>
                  {pod.plant.plantActionDate && dateConverter(pod.plant.plantActionDate)}
                </Styled.InfoTooltipValue>
              </Styled.InfoTooltipContentBox>
            </Styled.InfoTooltipItem>
            <Styled.InfoTooltipItem>
              <CuttingIcon width={16} height={17} />
              <Styled.InfoTooltipContentBox>
                <Styled.InfoTooltipLabel>{t('unitPanel.age')}</Styled.InfoTooltipLabel>
                <Styled.InfoTooltipValue>
                  {pod.plant.plantAction.plantAge >= 0 &&
                    `${pod.plant.plantAction.plantAge} ${t('unitPanel.days')}`}
                </Styled.InfoTooltipValue>
              </Styled.InfoTooltipContentBox>
            </Styled.InfoTooltipItem>
          </Styled.InfoTooltip>
        )}
      </Styled.PlantContainer>
      {isOpen && <Styled.BackPaper onClick={toggleIsOpen} />}
    </>
  );
};

export default PodComponent;
