import { Modal } from '@mui/material';
import { styled } from '@plant/ui';

export const StyledModal = styled(Modal)({
  zIndex: 69,
});

export const Container = styled('div')(({ theme }) => ({
  width: 550,
  height: 'min-content',
  padding: '80px 100px',
  backgroundColor: theme.palette.light.main,
  borderRadius: 15,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 40,
  outline: 'none',

  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
}));

export const BodyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
}));

export const ModalTitle = styled('h6')(({ theme }) => ({
  margin: 0,
  fontWeight: 600,
  fontSize: 25,
  color: '#1F483E',
}));

export const ModalText = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 400,
  fontSize: 17,
  color: '#1F483E',
  textAlign: 'center',
}));

export const ButtonsContainer = styled('div')(({ theme }) => ({
  maxWidth: 250,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
}));

export const Header = styled('div')({
  width: '100%',
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'end',
});

export const Close = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',
  cursor: 'pointer',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  position: 'absolute',
  top: 24,
  right: 24,

  '& svg': {
    fill: theme.palette.green.dark,
    stroke: theme.palette.green.dark,
  },
}));
