import {
  useMemo,
  useRef,
  useState,
  MouseEvent,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Image } from '@plant/data';
import { unitImagesSelector } from '../../store/selectors/units';
import { containerPadding } from '../components/modals/Timelapse/data';

export function useTimelapse(): {
  containerRef: MutableRefObject<HTMLDivElement>;
  images: Image[];
  speed: number;
  mainImageIndex: number;
  imageLength: number;
  leftThumbPosition: number;
  rightThumbPosition: number;
  stepLenght: number;
  renderImages: number[];
  handleChangeSpeed: (_: any, newValue: number) => void;
  handleDownLeftThumb: (event: SyntheticEvent<HTMLDivElement>) => void;
  handleDownRightThumb: (event: SyntheticEvent<HTMLDivElement>) => void;
} {
  const containerRef = useRef<HTMLDivElement>(null);
  const images = useSelector(unitImagesSelector);
  const [speed, setSpeed] = useState(1);
  const [leftThumbPosition, setLeftThumbPosition] = useState(0);
  const [rightThumbPosition, setRightThumbPosition] = useState(images?.length);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [stepLenght, setStepLenght] = useState(0);
  const [imageLength, setImageLenght] = useState(0);

  const renderNumber = Math.ceil(images?.length / 20);
  const renderImages = useMemo(
    () => [
      ...Array.from({ length: images?.length })
        .map((_, index) => index)
        .filter((value) => value % renderNumber === 0),
    ],
    [images, renderNumber]
  );

  useEffect(() => {
    const step = Math.min(
      (containerRef.current?.offsetWidth - containerPadding * 2) /
        images?.length,
      110
    );
    const image = Math.min(
      (containerRef.current?.offsetWidth - containerPadding * 2) /
        renderImages.length,
      110
    );
    console.log(containerRef.current?.offsetWidth);
    setStepLenght(step);
    setImageLenght(image);
  }, [containerRef.current]);

  const handleChangeSpeed = (_, newValue: number) => {
    setSpeed(newValue);
  };

  const handleDownLeftThumb = (event: MouseEvent<HTMLDivElement>) => {
    let startX = event.pageX;

    const handleMoveLeftThumb = (event: any) => {
      if (event.pageX > startX) {
        const offset = event.pageX - startX;

        if (Math.floor(offset / stepLenght)) {
          startX = event.pageX;
          setLeftThumbPosition((value) => {
            if (2 + value >= rightThumbPosition) {
              return value;
            } else {
              setMainImageIndex(value + 1);
              return value + 1;
            }
          });
        }
      } else {
        const offset = startX - event.pageX;

        if (Math.floor(offset / stepLenght)) {
          startX = event.pageX;
          setLeftThumbPosition((value) => {
            if (value <= 0) {
              return value;
            } else {
              setMainImageIndex(value - 1);
              return value - 1;
            }
          });
        }
      }
    };

    const handleUpLeftThumb = () => {
      document.onmousemove = null;
      document.onmouseup = null;
    };

    document.onmousemove = handleMoveLeftThumb;
    document.onmouseup = handleUpLeftThumb;
  };

  const handleDownRightThumb = (event: MouseEvent<HTMLDivElement>) => {
    let startX = event.pageX;

    const handleMoveRightThumb = (event: any) => {
      if (event.pageX > startX) {
        const offset = event.pageX - startX;

        if (Math.floor(offset / stepLenght)) {
          startX = event.pageX;
          setRightThumbPosition((value) => {
            if (1 + value > images?.length) {
              return value;
            } else {
              setMainImageIndex(value);
              return value + 1;
            }
          });
        }
      } else {
        const offset = startX - event.pageX;

        if (Math.floor(offset / stepLenght)) {
          startX = event.pageX;
          setRightThumbPosition((value) => {
            if (value - 2 <= leftThumbPosition) {
              return value;
            } else {
              setMainImageIndex(value - 2);
              return value - 1;
            }
          });
        }
      }
    };

    const handleUpRightThumb = () => {
      document.onmousemove = null;
      document.onmouseup = null;
    };

    document.onmousemove = handleMoveRightThumb;
    document.onmouseup = handleUpRightThumb;
  };

  return {
    containerRef,
    images,
    speed,
    mainImageIndex,
    imageLength,
    leftThumbPosition,
    rightThumbPosition,
    stepLenght,
    renderImages,
    handleChangeSpeed,
    handleDownLeftThumb,
    handleDownRightThumb,
  };
}
