export enum AppRoutes {
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ResetPassword = '/reset-password',
  DashBoard = '/dashboard',
  NewPassword = '/create-new-password',
  Social = '/social',
  Settings = '/settings',
  Lighting = '/lighting',
  Personal = 'personal',
  UnitManagment = 'unit-managment',
  About = 'about',
  FAQ = 'FAQ',
  Notifications = 'notifications',
  Password = 'password',
  CropGuide = '/crop-guide',
  Store = '/store'
}
