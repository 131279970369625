import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@plant/ui';
import * as Styled from './styles';
import { NotificationsFormData } from './types';
import { getNotificationsAction, updateNotificationsAction } from '../../store/actions/notifications';
import {
  notificationsDataSelector,
  notificationsErrorSelector,
  notificationsLoadingSelector,
} from '../../store/selectors/notifications';
import Loader from '../../core/components/Loader';
import ErrorAlert from '../../core/components/ErrorAlert';
import useConfirmModal from '../../core/components/modals/Confirm';

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(notificationsErrorSelector);
  const loading = useSelector(notificationsLoadingSelector);
  const data = useSelector(notificationsDataSelector);
  const { handleSubmit, control, reset } = useForm<NotificationsFormData>({
    mode: 'onChange',
  });
  const [showConfirmModal] = useConfirmModal();

  useEffect(() => {
    reset(data);
  }, [data]);

  useEffect(() => {
    if (!data) dispatch(getNotificationsAction.request());
  }, []);

  const onSave = (data: NotificationsFormData) => () => {
    dispatch(updateNotificationsAction.request(data));
  };

  const onSubmit = (data: NotificationsFormData) => {
    showConfirmModal({
      onAccept: onSave(data),
      text: t('notification.modal.text'),
      title: t('notification.modal.title'),
    });
  };

  return (
    <Styled.Container>
      <Styled.Title>{t('notification.title')}</Styled.Title>
      <Styled.Hr />
      {loading ? (
        <Loader />
      ) : (
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="pushNotifications"
            render={({ field: { value, onChange } }) => {
              return (
                <Styled.StyledSwitch
                  title={t('notification.information')}
                  text={t('notification.pushNotifications')}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="emailNotifications"
            render={({ field: { value, onChange } }) => {
              return (
                <Styled.StyledSwitch
                  title={t('notification.offers')}
                  text={t('notification.email')}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
          {error && <ErrorAlert variant="error" text={error} />}
          <Styled.ButtonContainer>
            <Button
              variant="contained"
              type="submit"
              title={t('notification.buttonText')}
              maxWidth={200}
            />
          </Styled.ButtonContainer>
        </Styled.Form>
      )}
    </Styled.Container>
  );
};

export default React.memo(Notifications);
