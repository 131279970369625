import { createSelector } from 'reselect';
import { ModalContainer } from '../../core/constants/modalContainer';
import { ModalsService } from '../../core/services/modals';
import { AppState } from '../reducers';

const modalsStoreSelector = (state: AppState) => state.modals;

export const modalsDataSelector = (container: ModalContainer) =>
  createSelector(modalsStoreSelector, (state) =>
    state.modals
      .map((id) => ModalsService.getModal(id))
      .filter((instance) => instance.container === container)
  );
