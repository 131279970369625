import {
  call,
  Effect,
  put,
  SagaReturnType,
  takeLatest,
} from 'redux-saga/effects';
import { ContentService } from '@plant/data';
import { getAboutAction, getFAQAction } from '../actions/content';
import { ActionType } from 'typesafe-actions';

export class ContentSagaWorker {
  static *getFAQ({
    payload: { page, count },
  }: ActionType<typeof getFAQAction.request>) {
    try {
      const data: SagaReturnType<typeof ContentService.getFAQ> = yield call(
        ContentService.getFAQ,
        page,
        count
      );
      yield put(getFAQAction.success(data));
    } catch (error) {
      yield put(getFAQAction.failure(error?.response?.data?.error));
    }
  }

  static *getAbout({
    payload: { page, count },
  }: ActionType<typeof getAboutAction.request>) {
    try {
      const data: SagaReturnType<typeof ContentService.getAbout> = yield call(
        ContentService.getAbout,
        page,
        count
      );
      yield put(getAboutAction.success(data));
    } catch (error) {
      yield put(getAboutAction.failure(error?.response?.data?.error));
    }
  }
}

export function* contentSaga(): Generator<Effect, void> {
  yield takeLatest(getFAQAction.request, ContentSagaWorker.getFAQ);
  yield takeLatest(getAboutAction.request, ContentSagaWorker.getAbout);
}
