import { styled } from '@plant/ui';

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SlideHolder = styled('div')`
  width: 100%;
  height: 100vh;
  background-color: white;
`;

export const ImageWrapper = styled('div')`
  height: 65%;
  position: relative;
`;

export const Image = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ImageWrapperShadow = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.95) 5%,
    rgba(255, 255, 255, 0) 40%
  );
  top: 0;
`;

export const SlideTitle = styled('h1')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
  fontSize: 25,
  margin: '20px 0',
}));

export const SlideText = styled('p')(({ theme }) => ({
  margin: '0 auto 50px',
  width: '80%',
  maxWidth: 400,
  textAlign: 'center',
  color: theme.palette.secondary.main,
}));

export const Indicator = styled('li', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  width: 8,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  position: 'relative',
  borderRadius: '50%',
  background: isSelected
    ? theme.palette.secondary.main
    : theme.palette.light.dark,
}));
