import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SettingsRoutes } from './data';
import * as Styled from './styles';

const Settings: FC = () => {
  const { t } = useTranslation();
  
  return (
    <Styled.Container>
      <Styled.LeftSideContainer>
        <Styled.NavigationWrapper>
          <Styled.Title>{t('settings.title')}</Styled.Title>
          <Styled.NavigationContiner>
            {SettingsRoutes.map((item) => {
              return (
                <Styled.NavigationItem key={item.path} to={item.path}>
                  <item.icon width={20} height={20} />
                  <Styled.NavigationItemLabel>
                    {t(item.label)}
                  </Styled.NavigationItemLabel>
                </Styled.NavigationItem>
              );
            })}
          </Styled.NavigationContiner>
        </Styled.NavigationWrapper>
      </Styled.LeftSideContainer>
      <Styled.RightSideContainer>
        <Outlet />
      </Styled.RightSideContainer>
    </Styled.Container>
  );
};

export default Settings;
