import { t as TFunction } from 'i18next';
import * as yup from 'yup';

export const forgotPasswordSchema = (t: typeof TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('errorMessages.correctEmail'))
      .required(t('errorMessages.emailIsRequired')),
  });
