import React, { ComponentType, useState } from 'react';
import { Button, SunIcon, OffSunIcon } from '@plant/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styles';
import { CANVAS_PADDING, getDefaultTime, LineTypes } from './data';
import Hint from '../../../../core/components/Hint';
import { useLighting } from '../../../../core/hooks/useLighting';
import { useEnvironment } from '../../../../core/hooks/useEnvironment';
import { toTime } from '../../../../core/helpers/date-time';
import { changeStartDateAction, stopLightingAction } from '../../../../store/actions/units';
import { selectedUnitSelector } from '../../../../store/selectors/units';

const LightingPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ref, progressLines, step, currentTime, activeButton, lightsOff } = useLighting();
  const environment = useEnvironment();
  const unit = useSelector(selectedUnitSelector);
  const [isOpen, setIsOpen] = useState(false);

  const calculatePosition = (hour: number, minute: number) => {
    return step * hour + (minute * step) / 60 + CANVAS_PADDING;
  };

  const handleToggle = () => {
    setIsOpen((value) => !value);
  };

  const handleStopLighting = () => {
    dispatch(
      stopLightingAction.request({
        unitUuid: unit.uuid,
      }),
    );
  };

  const handleChangeTime = (time: string) => {
    dispatch(
      changeStartDateAction.request({
        unitUuid: unit.uuid,
        startDate: time,
      }),
    );
  };

  const handleChangeStartDate = (time: string) => () => {
    handleToggle();
    handleChangeTime(time);
  };

  const LinesStyles: {
    [key in LineTypes]: {
      Container: ComponentType<any>;
      Icon: React.NamedExoticComponent<any>;
    };
  } = {
    [LineTypes.OffSun]: {
      Container: Styled.OffSunProgress,
      Icon: OffSunIcon,
    },
    [LineTypes.Sun]: {
      Container: Styled.SunProgress,
      Icon: SunIcon,
    },
    [LineTypes.SecondSun]: {
      Container: Styled.SecondSunProgress,
      Icon: SunIcon,
    },
  };

  return (
    <>
      {isOpen && <Styled.Backdrop onClick={handleToggle} />}
      <Styled.Container>
        <Styled.InfoSection>
          <Styled.InfoBox>
            <Styled.InfoLabelContainer>
              <Styled.YellowIcon>
                <SunIcon width={16} height={16} />
              </Styled.YellowIcon>
              <Styled.InfoLabel>{t('lighting.plantLight')}</Styled.InfoLabel>
            </Styled.InfoLabelContainer>
            <Styled.InfoTime onClick={handleToggle}>{`${
              getDefaultTime(progressLines)?.startHour | 0
            }:00 - ${getDefaultTime(progressLines)?.endHour | 0}:00`}</Styled.InfoTime>
            {isOpen && (
              <Styled.TimePopup>
                {Array.from({ length: 24 }).map((_, index) => {
                  return (
                    <Styled.TimePopupItem
                      key={index}
                      onClick={handleChangeStartDate(`${toTime(index)}:00`)}
                    >{`${toTime(index)}:00`}</Styled.TimePopupItem>
                  );
                })}
              </Styled.TimePopup>
            )}
          </Styled.InfoBox>
          {lightsOff && (
            <Styled.InfoBox>
              <Styled.OffLabel>{t('lighting.lightsOff')}</Styled.OffLabel>
              <Styled.OffTime>{lightsOff}</Styled.OffTime>
            </Styled.InfoBox>
          )}
          <Styled.EmptyInfoBox />
        </Styled.InfoSection>
        <Styled.Hr />
        <Styled.CanvasWrapper ref={ref}>
          <Styled.StyledCanvas />
          {progressLines.length > 0 &&
            progressLines
              .filter((item) => !item.default)
              .map((time) => {
                const { Container, Icon } = LinesStyles[time.type];
                return (
                  <Container
                    key={time.id}
                    positionLeft={calculatePosition(time.startHour, time.startMinute)}
                    positionRight={calculatePosition(time.endHour, time.endMinute)}
                  >
                    <Icon width={22} height={22} />
                  </Container>
                );
              })}
          <Styled.TimeLine
            position={calculatePosition(currentTime?.getHours(), currentTime?.getMinutes())}
          />
        </Styled.CanvasWrapper>
      </Styled.Container>
      <Styled.BoxesContainer>
        <Styled.Box>
          <Styled.BoxTextContainer>
            <Styled.BoxScenario>
              {t('lighting.scenarioLight', { name: environment?.name })}
            </Styled.BoxScenario>
            <Styled.BoxScenarioDesc>
              {t('lighting.envDescription', { time: environment?.ledTime })}
            </Styled.BoxScenarioDesc>
          </Styled.BoxTextContainer>
          <Hint text={environment?.description} placement="top" />
        </Styled.Box>
      </Styled.BoxesContainer>
      <Styled.ButtonContainer>
        <Button
          disabled={!activeButton}
          variant="contained"
          title={
            !activeButton && lightsOff
              ? t('lighting.offButtonText', { time: lightsOff })
              : t('lighting.buttonText')
          }
          maxWidth={280}
          onClick={handleStopLighting}
        />
        <Styled.ButtonSubText>{t('lighting.buttonSubText')}</Styled.ButtonSubText>
      </Styled.ButtonContainer>
    </>
  );
};

export default React.memo(LightingPanel);
