import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { modalsDataSelector } from '../../../store/selectors/modals';
import { ModalContainer } from '../../constants/modalContainer';

interface ModalsContainerProps {
  container?: ModalContainer;
}

const ModalsContainer: FC<ModalsContainerProps> = ({
  container = ModalContainer.Root,
}) => {
  const modals = useSelector(modalsDataSelector(container));

  return (
    <>
      {modals.map<ReactNode>(
        ({ id, component: Component }): ReactNode => (
          <Component key={id} />
        )
      )}
    </>
  );
};

export default ModalsContainer;
