import React from 'react';
import * as Styled from './styles';
import Hint from '../Hint';

interface SliderProps {
  title: string;
  icon: React.ReactNode;
  helperText: string;
  marks: { value: number; label: string }[];
  value: number;
  hintPlacement?: string;
  onChange: (_, newValue: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  title,
  icon,
  helperText,
  marks,
  value,
  hintPlacement = 'right',
  onChange,
  ...props
}) => {
  return (
    <Styled.Container {...props}>
      <Styled.HeaderSection>
        <Styled.TitleContainer>
          {icon}
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleContainer>
        <Hint text={helperText} placement={hintPlacement} />
      </Styled.HeaderSection>
      <Styled.SliderContainer>
        <Styled.CustomSlider
          value={value}
          onChange={onChange}
          valueLabelDisplay={'off'}
          marks={marks}
          min={marks[0].value}
          max={marks[marks.length - 1].value}
          step={null}
        />
      </Styled.SliderContainer>
    </Styled.Container>
  );
};

export default React.memo(Slider);
