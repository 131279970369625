import { CloseIcon } from '@plant/ui';
import React, { HTMLAttributes } from 'react';
import * as Styled from './styles';

const CircleClose: React.FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Styled.Circle {...props}>
      <CloseIcon width={12} height={12} />
    </Styled.Circle>
  );
};

export default React.memo(CircleClose);
