import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPlantIcon, ArrowIcon } from '@plant/ui';
import * as Styled from './../../styles';
import { ClearPlantInner } from './styles';
import ListItem from '../../../../../../core/components/ListItem';
import { useSelector } from 'react-redux';
import { plantActionsDataSelector } from '../../../../../../store/selectors/plantActions';
import { imageWithToken } from '../../../../../../core/helpers/image-with-token.helper';
import { authTokenSelector } from '../../../../../../store/selectors/auth';
import usePlantModal from '../../../../../../core/components/modals/AddPlant';

interface ClearPodProps {
  index: number;
  active: boolean;
}

const ClearPod: React.FC<ClearPodProps> = ({ index, active }) => {
  const { t } = useTranslation();
  const [showAddPlantModal] = usePlantModal();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const actions = useSelector(plantActionsDataSelector);
  const token = useSelector(authTokenSelector);

  const handleOpenModal = (actionUuid: string) => () => {
    toggleIsOpen();
    showAddPlantModal({
      data: {
        pod: index,
        actionUuid,
      },
    });
  };

  const toggleIsOpen = () => {
    if (active) setIsOpen((value) => !value);
  };

  return (
    <>
      <Styled.PlantContainer isOpen={isOpen}>
        <ClearPlantInner unActive={!active} onClick={toggleIsOpen}>
          <Styled.AddLabel>{t('unitPanel.addPlant')}</Styled.AddLabel>
          <AddPlantIcon width={12} height={12} />
        </ClearPlantInner>
        <Styled.PopupButtonContainer>
          <Styled.PlantName>
            {t('unitPanel.pod')}
            {index}
          </Styled.PlantName>
          <Styled.PopupButton isOpen={isOpen} unActive={!active} onClick={toggleIsOpen}>
            <ArrowIcon width={9} height={5} />
          </Styled.PopupButton>
        </Styled.PopupButtonContainer>
        {isOpen && (
          <Styled.Popup>
            {actions &&
              actions.map((action) => {
                return (
                  <ListItem
                    key={action.uuid}
                    leftIcon={<img src={imageWithToken(action.image, token)} alt="icon" />}
                    text={action.name.replace('Replant', 'Plant')}
                    textAlign="center"
                    onClick={handleOpenModal(action.uuid)}
                  />
                );
              })}
          </Styled.Popup>
        )}
      </Styled.PlantContainer>
      {isOpen && <Styled.BackPaper onClick={toggleIsOpen} />}
    </>
  );
};

export default React.memo(ClearPod);
