import { call, Effect, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { UnitService } from '@plant/data';
import { saveTimelapseAction, shareFileAction } from '../actions/timelapse';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { SocialTypes } from '../../core/constants/socialTypes';
import { facebookShareLink, googleShareLink } from '../../core/constants/shareUrls';

export class TimelapseSagaWorker {
  static *saveTimelapse(action: ActionType<typeof saveTimelapseAction.request>) {
    try {
      const { unitUuid, speed, startDate, endDate } = action.payload;
      const video: SagaReturnType<typeof UnitService.saveTimelapse> = yield call(
        UnitService.saveTimelapse,
        unitUuid,
        speed,
        startDate,
        endDate,
      );
      yield put(saveTimelapseAction.success({ video }));
    } catch (error) {
      yield put(saveTimelapseAction.failure(error));
    }
  }

  static *shareFile({ payload }: ActionType<typeof shareFileAction.request>) {
    try {
      const { url, type } = payload;
      const resultUrl: SagaReturnType<typeof UnitService.saveTimelapse> = yield call(
        UnitService.shareFile,
        url,
      );
      yield put(shareFileAction.success(resultUrl));
      if (type === SocialTypes.Facebook) {
        window.open(facebookShareLink(resultUrl), '_blank');
      } else if (type === SocialTypes.Google) {
        window.open(
          googleShareLink(resultUrl),
          'sharer',
          'toolbar=0,status=0,width=648,height=395',
        );
      }
    } catch (error) {
      toast.error(t('auth.notifications.something').toString());
      yield put(shareFileAction.failure(error?.response?.data?.error));
    }
  }
}

export function* timelapseSaga(): Generator<Effect, void> {
  yield takeLatest(shareFileAction.request, TimelapseSagaWorker.shareFile);
  yield takeLatest(saveTimelapseAction.request, TimelapseSagaWorker.saveTimelapse);
}
