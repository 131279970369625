import { styled } from '@plant/ui';

export const Circle = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  background: 'linear-gradient(0deg, #B6D9C8, #B6D9C8), #99C6B0',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  cursor: 'pointer',

  '& svg': {
    stroke: theme.palette.secondary.main,
  },
}));
