import { styled } from '@plant/ui';

export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F0F5F4',
  paddingBottom: 50,
});

export const Body = styled('div')({
  paddingTop: 20,
  display: 'flex',
  justifyContent: 'center',
});

export const HelpSection = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: 20,
});

export const Container = styled('div')({
  maxWidth: 830,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});
