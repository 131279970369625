import { ProgressLine } from './types';

export const CANVAS_PADDING = 10;

export enum LineTypes {
  SecondSun = 2,
  Sun = 1,
  OffSun = 0,
}

export const Lines = {
  sun: 100,
  offSun: 160,
};

export function getDefaultTime(times: ProgressLine[]) {
  return times.find((time) => time.default);
}

export function getLightOffTime(times: ProgressLine[]) {
  return times.find((time) => time.type === LineTypes.OffSun);
}

export function dateBetween(line: ProgressLine, date: Date) {
  const startDate = new Date();
  startDate.setHours(line?.startHour);
  startDate.setMinutes(line?.startMinute - 2);

  const endDate = new Date();
  if (line?.endHour === 0 && line?.endMinute === 0) {
    endDate.setHours(23);
    endDate.setMinutes(59);
  } else {
    endDate.setHours(line?.endHour);
    endDate.setMinutes(line?.endMinute);
  }

  if (startDate < date && startDate > endDate) {
    return true;
  }

  if (startDate > endDate && date < startDate && date < endDate) {
    return true;
  }
  return startDate < date && date < endDate;
}
