import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '@plant/ui';
import AuthLayout from '../../core/components/AuthLayout';
import { clearAuthErrorAction, signUpAction } from '../../store/actions/auth';
import TermsAndPrivacy from './components/TermsAndPrivacy';
import * as Styled from './styles';
import {
  authErrorSelector,
  authLoadingSelector,
} from '../../store/selectors/auth';
import { Navigator } from '../../core/services/navigator';
import { registerSchema } from '../../core/yupShemas/register';
import ErrorAlert from '../../core/components/ErrorAlert';
import Loader from '../../core/components/Loader';
import { AppRoutes } from '../../core/constants/appRoutes';
import GoogleLink from '../../core/components/GoogleLink';
import FacebookLink from '../../core/components/FacebookLink';

interface SignUpFormValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  userSurname: string;
}

const SignUp: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const error = useSelector(authErrorSelector);
  const loading = useSelector(authLoadingSelector);
  const [currentField, setCurrentField] = useState<string>('');

  const memoizedRegisterSchema = useMemo(() => registerSchema(t), [t]);

  const { register, handleSubmit, formState } = useForm<SignUpFormValues>({
    resolver: yupResolver(memoizedRegisterSchema),
  });

  const updateCurrentField = (fieldName: string) => () => {
    setCurrentField(fieldName);
  };

  const handleSignUp = ({
    email,
    password,
    username,
    userSurname,
  }: SignUpFormValues) => {
    dispatch(clearAuthErrorAction());
    dispatch(
      signUpAction.request({
        email,
        password,
        firstName: username,
        lastName: userSurname,
      })
    );
  };

  const redirectToSignInPage = () => {
    dispatch(clearAuthErrorAction());
    Navigator.push(AppRoutes.SignIn);
  };

  const errorMessage = formState.errors[currentField]?.message || error;

  return (
    <AuthLayout>
      <Styled.Container>
        <Styled.Form>
          <Styled.FormLabel>{t('auth.signUp.title')}</Styled.FormLabel>
          <Input
            title={t('auth.emailPlaceholder')}
            isInvalid={!!formState.errors.email?.message}
            handleFocus={updateCurrentField('email')}
            {...register('email')}
          />
          <Input
            title={t('auth.firstNamePlaceholder')}
            isInvalid={!!formState.errors.username?.message}
            handleFocus={updateCurrentField('username')}
            {...register('username')}
          />
          <Input
            title={t('auth.lastNamePlaceholder')}
            isInvalid={!!formState.errors.userSurname?.message}
            handleFocus={updateCurrentField('userSurname')}
            {...register('userSurname')}
          />
          <Input
            type="password"
            title={t('auth.passwordPlaceholder')}
            isInvalid={!!formState.errors.password?.message}
            handleFocus={updateCurrentField('password')}
            {...register('password')}
          />
          <Input
            type="password"
            title={t('auth.confirmPasswordPlaceholder')}
            isInvalid={!!formState.errors.confirmPassword?.message}
            handleFocus={updateCurrentField('confirmPassword')}
            {...register('confirmPassword')}
          />
          {!loading ? (
            <>
              <Styled.ButtonWrapper>
                <Button
                  title="Create account"
                  variant="contained"
                  maxWidth={250}
                  onClick={handleSubmit(handleSignUp)}
                />
              </Styled.ButtonWrapper>
              <Styled.TextCondition>{t('auth.orUse')}</Styled.TextCondition>
              <Styled.ButtonsContainer>
                <FacebookLink />
                <Styled.LineDiv />
                <GoogleLink />
              </Styled.ButtonsContainer>
              <Styled.SecondaryButtonWrapper>
                <Button
                  title={t('auth.signUp.switchButton')}
                  variant="link"
                  onClick={redirectToSignInPage}
                />
              </Styled.SecondaryButtonWrapper>
              {errorMessage && (
                <ErrorAlert variant="error" text={errorMessage} />
              )}
            </>
          ) : (
            <Styled.LoaderWrapper>
              <Loader />
            </Styled.LoaderWrapper>
          )}
        </Styled.Form>
        <TermsAndPrivacy />
      </Styled.Container>
    </AuthLayout>
  );
};

export default SignUp;
