import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { AddPlantResponse, Unit, Settings, SuccessResponse, Environment, RemoveNutritionAlertResponse } from '@plant/data';

export enum UnitsTypes {
  RequestUnits = '[UNITS] RequestUnits',
  RequestUnitsSuccess = '[UNITS] RequestUnitsSuccess',
  RequestUnitsFailed = '[UNITS] RequestUnitsFailed',

  StartFetchingUpdates = '[UNITS] StartFetchingUpdates',
  StopFetchingUpdates = '[UNITS] StopFetchingUpdates',
  UpdateSelectedUnit = '[UNITS] UpdateSelectedUnit',

  AddPlant = '[UNITS] AddPlant',
  AddPlantSuccess = '[UNITS] AddPlantSuccess',
  AddPlantFailed = '[UNITS] AddPlantFailed',

  DeletePlant = '[UNITS] DeletePlant',
  DeletePlantSuccess = '[UNITS] DeletePlantSuccess',
  DeletePlantFailed = '[UNITS] DeletePlantFailed',

  AddPlantWithDelete = '[UNITS] AddPlantWithDelete',
  AddPlantWithDeleteSuccess = '[UNITS] AddPlantWithDeleteSuccess',
  AddPlantWithDeleteFailed = '[UNITS] AddPlantWithDeleteFailed',

  DeleteUnit = '[UNITS] DeleteUnit',
  DeleteUnitSuccess = '[UNITS] DeleteUnitSuccess',
  DeleteUnitFailed = '[UNITS] DeleteUnitFailed',

  StopLighting = '[UNITS] StopLighting',
  StopLightingSuccess = '[UNITS] StopLightingSuccess',
  StopLightingFailed = '[UNITS] StopLightingFailed',

  ChangeStartDate = '[UNITS] ChangeStartDate',
  ChangeStartDateSuccess = '[UNITS] ChangeStartDateSuccess',
  ChangeStartDateFailed = '[UNITS] ChangeStartDateFailed',

  RebootUnit = '[UNITS] RebootUnit',
  RebootUnitSuccess = '[UNITS] RebootUnitSuccess',
  RebootUnitFailed = '[UNITS] RebootUnitFailed',

  ResetUnit = '[UNITS] ResetUnit',
  ResetUnitSuccess = '[UNITS] ResetUnitSuccess',
  ResetUnitFailed = '[UNITS] ResetUnitFailed',

  RemoveNutritionAlert = '[UNITS] RemoveNutritionAlert',
  RemoveNutritionAlertSuccess = '[UNITS] RemoveNutritionAlertSuccess',
  RemoveNutritionAlertFailed = '[UNITS] RemoveNutritionAlertFailed',

  SetSelectedUnit = '[UNITS] SetSelectedUnit',

  ChangeUnitEnvironment = '[UNITS] ChangeUnitEnvironment',

  ClearUnit = '[UNITS] ClearUnit',
}

export const getUnitsAction = createAsyncAction(
  UnitsTypes.RequestUnits,
  UnitsTypes.RequestUnitsSuccess,
  UnitsTypes.RequestUnitsFailed,
)<void, { units: Unit[]; defaultUnitUuid?: string }, string>();

export const addPlantAction = createAsyncAction(
  UnitsTypes.AddPlant,
  UnitsTypes.AddPlantSuccess,
  UnitsTypes.AddPlantFailed,
)<
  { cropUuid: string; unitUuid: string; pod: number; plantActionUuid: string },
  { response: AddPlantResponse },
  string
>();

export const deletePlantAction = createAsyncAction(
  UnitsTypes.DeletePlant,
  UnitsTypes.DeletePlantSuccess,
  UnitsTypes.DeletePlantFailed,
)<{ plantUuid: string }, { plantUuid: string }, string>();

export const rebootUnitAction = createAsyncAction(
  UnitsTypes.RebootUnit,
  UnitsTypes.RebootUnitSuccess,
  UnitsTypes.RebootUnitFailed,
)<{ unitUuid: string }, SuccessResponse<{ uuid: string }>, string>();

export const resetUnitAction = createAsyncAction(
  UnitsTypes.ResetUnit,
  UnitsTypes.ResetUnitSuccess,
  UnitsTypes.ResetUnitFailed,
)<{ unitUuid: string }, SuccessResponse<{ uuid: string }>, string>();

export const addPlantWithDeleteAction = createAsyncAction(
  UnitsTypes.AddPlantWithDelete,
  UnitsTypes.AddPlantWithDeleteSuccess,
  UnitsTypes.AddPlantWithDeleteFailed,
)<
  {
    cropUuid: string;
    unitUuid: string;
    pod: number;
    plantActionUuid: string;
    plantUuid: string;
  },
  { response: AddPlantResponse; deletedPlantUuid: string },
  string
>();

export const deleteUnitAction = createAsyncAction(
  UnitsTypes.DeleteUnit,
  UnitsTypes.DeleteUnitSuccess,
  UnitsTypes.DeleteUnitFailed,
)<{ unitUuid: string }, { unitUuid: string }, string>();

export const stopLightingAction = createAsyncAction(
  UnitsTypes.StopLighting,
  UnitsTypes.StopLightingSuccess,
  UnitsTypes.StopLightingFailed,
)<{ unitUuid: string }, { settings: Settings; unitUuid: string }, string>();

export const changeStartDateAction = createAsyncAction(
  UnitsTypes.ChangeStartDate,
  UnitsTypes.ChangeStartDateSuccess,
  UnitsTypes.ChangeStartDateFailed,
)<{ unitUuid: string; startDate: string }, { settings: Settings; unitUuid: string }, string>();

export const removeNutritionAlertAction = createAsyncAction(
  UnitsTypes.RemoveNutritionAlert,
  UnitsTypes.RemoveNutritionAlertSuccess,
  UnitsTypes.RemoveNutritionAlertFailed,
)<{ unitUuid: string }, { unitUuid: string, data: RemoveNutritionAlertResponse }, void>();

export const setSelectedUnitIdAction = createAction(UnitsTypes.SetSelectedUnit)<{ uuid: string }>();

export const startFetchingUpdatesAction = createAction(UnitsTypes.StartFetchingUpdates)<{
  uuid: string;
}>();

export const stopFetchingUpdatesAction = createAction(UnitsTypes.StopFetchingUpdates)<void>();

export const updateSelectedUnitAction = createAction(UnitsTypes.UpdateSelectedUnit)<Unit>();

export const changeUnitEnvironmentAction = createAction(
  UnitsTypes.ChangeUnitEnvironment,
)<Environment>();

export const clearUnitAction = createAction(UnitsTypes.ClearUnit)<void>();

export type UnitsActionUnion = ActionType<
  | typeof getUnitsAction
  | typeof setSelectedUnitIdAction
  | typeof updateSelectedUnitAction
  | typeof clearUnitAction
  | typeof addPlantAction
  | typeof deletePlantAction
  | typeof addPlantWithDeleteAction
  | typeof deleteUnitAction
  | typeof stopLightingAction
  | typeof changeStartDateAction
  | typeof rebootUnitAction
  | typeof resetUnitAction
  | typeof removeNutritionAlertAction
  | typeof changeUnitEnvironmentAction
>;
