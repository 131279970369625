import { ComponentType, NamedExoticComponent } from 'react';
import { LeakIcon, WaterIcon, TempIcon, ReportingIcon, NutritionIcon, SettingsIcon } from '@plant/ui';
import { Icon } from '@plant/data';
import * as Styled from './styles';

export const NotificationData: {
  Container: ComponentType<any>;
  text: string;
  Icon: NamedExoticComponent<Icon>;
}[] = [
  {
    Container: Styled.LeakMessage,
    text: 'notifications.type1',
    Icon: LeakIcon,
  },
  {
    Container: Styled.WaterMessage,
    text: 'notifications.type2',
    Icon: WaterIcon,
  },
  {
    Container: Styled.TempMessage,
    text: 'notifications.type3',
    Icon: TempIcon,
  },
  {
    Container: Styled.ConnectionMessage,
    text: 'notifications.type5',
    Icon: ReportingIcon,
  },
  {
    Container: Styled.ConnectionMessage,
    text: 'notifications.type4',
    Icon: ReportingIcon,
  },
  {
    Container: Styled.ConnectionMessage,
    text: 'notifications.type6',
    Icon: ReportingIcon,
  },
  {
    Container: Styled.SensorsMessage,
    text: 'notifications.type7',
    Icon: SettingsIcon,
  },
  {
    Container: Styled.ConnectionMessage,
    text: 'notifications.type8',
    Icon: ReportingIcon,
  },
  {
    Container: Styled.TempMessage,
    text: 'notifications.type9',
    Icon: NutritionIcon,
  },
];

export const NUTRITION_ALERT_TYPE = 9;