import { Slider } from '@mui/material';
import { styled } from '@plant/ui';
import BackgroundImage from '../../../../assets/images/timelapse/arrows.png';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.light.main,
  padding: 40,
  paddingTop: 30,
  borderRadius: 15,
  boxShadow: '0px 14px 44px rgba(154, 175, 171, 0.15)',
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

export const HeaderSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Title = styled('h3')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 20,
  lineHeight: '20px',
  color: theme.palette.secondary.main,
  margin: 0,
}));

export const ButtonsSection = styled('div')({
  display: 'flex',
  gap: 8,
});

export const OutlinedButton = styled('button')({
  backgroundColor: 'transparent',
  borderRadius: 50,
  border: '1px solid #DAE3E1',
  padding: '10px 15px',
  outline: 'none',
  cursor: 'pointer',
});

export const ButtonText = styled('span')({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '12px',
  color: '#54625C',
  opacity: 0.9,
});

export const ContainedButton = styled('button')({
  backgroundColor: '#F0F5F4',
  borderRadius: '50px',
  border: 'none',
  padding: '10px 15px',
  outline: 'none',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  gap: 8,

  '& svg': {
    fill: 'rgba(51, 66, 59, 0.82)',
  },
});

export const SliderContainer = styled('div')({
  padding: '0 30px',
  paddingTop: 40,
});

export const CustomSlider = styled(Slider)({
  color: '#E7EDEB',
  '& .MuiSlider-mark': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: '#99C6B0',
    width: 38,
    height: 38,
    borderRadius: 13,
    boxShadow: '0px 6px 14px rgba(153, 198, 176, 0.31)',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  '& .MuiSlider-valueLabel': {
    backgroundColor: '#5B806E',
    borderRadius: 5,
  },
});

export const ImagePlaceholder = styled('div')({
  width: '100%',
  height: 200,
  borderRadius: 8,
  backgroundColor: '#99C6B0',
  opacity: 0.3,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImageTextPlaceholder = styled('h1')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  color: theme.palette.secondary.main,
  opacity: 1,
}));

export const TimelapseImage = styled('img')({
  width: '100%',
  borderRadius: 8,
});
