import React from 'react';
import { useTranslation } from 'react-i18next';
import InternalLink from '../IternalLink';
import { Text } from './style';

const TermsAndPrivacy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Text>
      {t('auth.signUp.privacy.byRegistering')}
      <InternalLink
        title={t('auth.signUp.privacy.terms')}
        route="https://en.wikipedia.org/wiki/Term"
      />
      {t('auth.signUp.privacy.and')}
      <InternalLink
        title={t('auth.signUp.privacy.policy')}
        route="https://en.wikipedia.org/wiki/Privacy_policy"
      />
      {t('auth.signUp.privacy.agree')}
      <InternalLink
        title={t('auth.signUp.privacy.legalAge')}
        route="https://en.wikipedia.org/wiki/Legal_age"
      />
      {t('auth.signUp.privacy.parcipate')}
    </Text>
  );
};

export default React.memo(TermsAndPrivacy);
