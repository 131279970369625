import { styled, Button } from '@plant/ui';

export const EnvironmentContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const EnvironmentHint = styled('p')(({ theme }) => ({
  margin: 0,
  marginBottom: 20,
  color: theme.palette.secondary.main,
  fontWeight: 500,
}));

export const EnvironmentDescription = styled('p')(({ theme }) => ({
  margin: 0,
  marginTop: 10,
  marginBottom: 20,
  color: '#6D867B',
  fontWeight: 400,
}));

export const PlantsContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 10,
  rowGap: 8,
});

export const PlantItem = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: 8,
  width: 'min-content',
  padding: '6px 12px',
  borderRadius: 8,
  backgroundColor: theme.palette.light.main,
  color: theme.palette.secondary.main,
}));

export const PlantIcon = styled('img')({
  width: 14,
});

export const LoaderContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
});

export const CustomizeButton = styled(Button)({
  maxWidth: 230,
  marginTop: 40,
});
