import { createReducer } from 'typesafe-actions';
import { Action } from '@plant/data';
import { ActionsActionUnion, getPlantActionsAction } from '../actions/plantActions';

export interface State {
  actions: Action[];
  loading: boolean;
  error: string;
}

export const initialState: State = {
  actions: [],
  loading: false,
  error: null,
};

export const reducer = createReducer<State, ActionsActionUnion>(initialState)
  .handleAction(getPlantActionsAction.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getPlantActionsAction.success, (state, action) => ({
    ...state,
    actions: action.payload.actions,
    loading: false,
  }))
  .handleAction(getPlantActionsAction.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }));
