import { Outlet } from 'react-router-dom';
import ModalsContainer from '../ModalsContainer';
import { ModalContainer } from '../../constants/modalContainer';
import { NavigationBar } from '../NavigationBar';
import { Container } from './styles';

const SidebarLayout: React.FC = () => {
  return (
    <Container>
      <NavigationBar />
      <ModalsContainer container={ModalContainer.Dashboard} />
      <Outlet />
    </Container>
  );
};

export default SidebarLayout;
