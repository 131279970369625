import { fork } from 'redux-saga/effects';
import { startAppSaga } from './app';
import { authSaga } from './auth';
import { unitsSaga } from './units';
import { environmentsSaga } from './environments';
import { modalsSaga } from './modals';
import { actionsSaga } from './plantActions';
import { searchCropsSaga } from './searchCrops';
import { timelapseSaga } from './timelapse';
import { notificationsSaga } from './notifications';
import { contentSaga } from './content';
import { storeSaga } from './store';

export function* sagas(): Generator {
  yield fork(authSaga);
  yield fork(startAppSaga);
  yield fork(unitsSaga);
  yield fork(environmentsSaga);
  yield fork(modalsSaga);
  yield fork(actionsSaga);
  yield fork(searchCropsSaga);
  yield fork(timelapseSaga);
  yield fork(notificationsSaga);
  yield fork(contentSaga);
  yield fork(storeSaga);
}
